import React from "react";
// Customizable Area Start

import { Typography, Box, Button, InputBase, InputLabel, FormControl, styled } from "@material-ui/core";

import Settings2Controller, {
    Props,
    configJSON
} from "./Settings2Controller";
import HeaderBlockWeb from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";
import { imgAlertIcon, imgSuccessIcon, imgSuccessIcon2 } from "./assets";
import { CustomizableImage, styles } from "../../categoriessubcategories/src/Categoriessubcategories.web";
import { backArrow } from "../../categoriessubcategories/src/assets";
import { selectMenuIcon } from "../../catalogue/src/assets";
import { SelectQueryType } from "../../../components/src/CustomDropdown.web";
// Customizable Area End

export default class UpdateSettings extends Settings2Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    changeEmailBlocks = () => {
        const mailText = this.state.changeMailPageText;
        return (
            <>
                <Box data-test-id="change-email-block" sx={contentBlockStyle()}>
                    {this.state.activeSettingPage !== 3 ?
                        <Box className="outer-box-first">
                            <Typography className="title-block">{mailText.title}</Typography>
                            <Box className="inner-box">
                                <Typography className="description-first-block">
                                    {this.state.activeSettingPage === 1 ? mailText.descriptionMail : mailText.descriptionCode }
                                </Typography>

                                {this.state.emailError ?
                                    <Box data-testid="email-error" sx={errorMsgStyle()} style={{marginBottom: "8px"}}>
                                        <Typography className="errormsg_text" style={{ fontFamily: "Montserrat", fontWeight: 700 }}>
                                            {mailText.errorMsg}
                                        </Typography>
                                    </Box> : null
                                }
                                {this.state.activeSettingPage === 1 ?
                                    <FormControl fullWidth className="input-first-block">
                                        <label
                                            htmlFor="input-content-first-block"
                                            style={{fontSize: "14px"}}
                                        >
                                            {mailText.emailLabel}
                                        </label>
                                        <InputBase
                                            id="input-change-email"
                                            placeholder={mailText.emailPlaceholder}
                                            value={this.state.email}
                                            className="input-content-first-block"
                                            onChange={(e) => this.handleChangeEmail(e)}
                                        />
                                    </FormControl> :
                                    <FormControl fullWidth className="input-first-block">
                                        <label
                                            className="input-label"
                                            htmlFor="input-content-first-block"
                                            style={{fontSize: "14px"}}
                                        >
                                            {mailText.codeLabel}
                                        </label>
                                        <InputBase
                                            id="input-email-code"
                                            placeholder={mailText.codePlaceHolder}
                                            value={this.state.code}
                                            className="input-content-first-block"
                                            onChange={(e) => this.handleChangeInput(e, 'code', 'codeError')}
                                        />
                                    </FormControl>
                                }
                                <Typography className="note-first-block">
                                    {this.state.activeSettingPage === 1 && mailText.firstBlockNote}
                                </Typography>

                                {this.state.activeSettingPage === 1 ?
                                    <Button
                                        data-test-id="btn-email-enter"
                                        disabled={this.state.emailError || this.state.email === ''}
                                        onClick={this.changeEmailId}
                                        className="enter-button-first-block">
                                        {mailText.enterButton}
                                    </Button>
                                    :
                                    <Button
                                        data-test-id="btn-otp-verify"
                                        disabled={this.state.codeError || this.state.code === ''}
                                        onClick={this.verifyOtpEmailId}
                                        className="enter-button-first-block">
                                        {mailText.verifyButton}
                                    </Button>}
                                {this.state.activeSettingPage === 2 && <Typography className="confirmation-msg">
                                    {mailText.confirmMessage} <span>{mailText.resendCode}</span>
                                </Typography>}
                            </Box>
                        </Box>
                        :
                        <Box data-test-id="email-change-sucess" className="outer-box-sucess">
                            <Typography className="title-block-sucess">{mailText.title}</Typography>
                            <Box className="sucess-box">
                                <img src={imgSuccessIcon} className="sucess-img" alt="" />
                                <Typography className="sucess-title">{mailText.successTitle}</Typography>
                                <Typography className="sucess-msg">{mailText.successMessage}</Typography>
                            </Box>
                        </Box>
                    }

                </Box>
            </>
        );
    };

    changePhoneBlocks = () => {
        return (
            <>
                <Box data-test-id="change-phone-block" sx={contentBlockStyle()}>
                    {this.state.activeSettingPage !== 3 ?
                        <Box className="outer-box-first">
                            <Typography className="title-block">{this.state.mobTexts.changeMob}</Typography>
                            <Box className="inner-box">
                                <Typography className="description-first-block">
                                    {this.state.activeSettingPage === 1 ? this.state.mobTexts.enterInfo : this.state.mobTexts.afterInfo}
                                </Typography>

                                {this.state.phoneError ?
                                    <Box sx={errorMsgStyle()}>
                                        <Typography className="errormsg_text" style={{ fontFamily: "Montserrat", fontWeight: 700 }}>
                                            {this.state.mobTexts.valid}
                                        </Typography>
                                    </Box> : null
                                }
                                {this.state.activeSettingPage === 1 ?
                                    <FormControl fullWidth className="input-first-block">
                                        <label
                                            className="input-label"
                                            htmlFor="input-content-first-block"
                                            style={{fontSize: "14px"}}
                                        >
                                            {this.state.mobTexts.enterMobNo} <span>*</span>
                                        </label>
                                        <InputBase
                                            inputProps={{ maxLength: 25 }}
                                            id="input-change-phone"
                                            placeholder={this.state.mobTexts.mobNo}
                                            value={this.state.phone}
                                            className="input-content-first-block"
                                            onChange={(e) => this.handleChangeInput(e, 'phone', 'phoneError')}
                                        />
                                    </FormControl> :
                                    <FormControl fullWidth className="input-first-block">
                                        <label
                                            className="input-label"
                                            htmlFor="input-content-first-block"
                                            style={{fontSize: "14px"}}
                                        >
                                            {this.state.mobTexts.enterCode} <span>*</span>
                                        </label>
                                        <InputBase
                                            id="input-phone-code"
                                            placeholder={this.state.mobTexts.enterCode}
                                            value={this.state.code}
                                            className="input-content-first-block"
                                            onChange={(e) => this.handleChangeInput(e, 'code', 'codeError')}
                                        />
                                    </FormControl>
                                }
                                <Typography className="note-first-block">
                                    {this.state.activeSettingPage === 1 && this.state.mobTexts.code}
                                </Typography>

                                {this.state.activeSettingPage === 1 ?
                                    <Button
                                        data-test-id="btn-phone-enter"
                                        disabled={this.state.phoneError || this.state.phone === ''}
                                        onClick={this.changPhoneNo}
                                        className="enter-button-first-block">
                                        {this.state.mobTexts.enter}
                                    </Button>
                                    :
                                    <Button
                                        data-test-id="btn-phone-code-verify"
                                        disabled={this.state.codeError || this.state.code === ''}
                                        onClick={this.verifyOtpPhoneNo}
                                        className="enter-button-first-block">
                                        {this.state.mobTexts.verify}
                                    </Button>}
                                {this.state.activeSettingPage === 2 && <Typography className="confirmation-msg">
                                    {this.state.mobTexts.confirmMessage} <a onClick={this.changPhoneNo}>{this.state.mobTexts.resendCode}</a>
                                </Typography>}
                            </Box>
                        </Box>
                        :
                        <Box data-test-id="phone-change-sucess" className="outer-box-sucess">
                            <Typography className="title-block-sucess">{this.state.mobTexts.changeMob}</Typography>
                            <Box className="sucess-box">
                                <img src={imgSuccessIcon} className="sucess-img" alt="" />
                                <Typography className="sucess-title">{this.state.mobTexts.success}</Typography>
                                <Typography className="sucess-msg">{this.state.mobTexts.success}</Typography>
                            </Box>
                        </Box>
                    }

                </Box>
            </>
        );
    };

    changePasswordBlocks = () => {
        return (
            <>
                <Box data-test-id="change-password-block" sx={contentBlockStyle()}>
                    {this.state.activeSettingPage !== 3 ?
                        <Box className="outer-box-first">
                            <Typography className="title-block"> {this.getContent(configJSON.changePassword,configJSON.changePasswordArabic)}</Typography>
                            <Box className="inner-box">
                                <Typography className="description-first-block">                                   
                                    {this.getContent(configJSON.pleaseEnterInfoText,configJSON.pleaseEnterInfoArabic)}
                                </Typography>

                                {this.state.currentPasswordError ?
                                    <Box sx={errorMsgStyle()}>
                                        <Typography className="errormsg_text" style={{ fontFamily: "Montserrat", fontWeight: 700 }}>                                            
                                            {this.getContent(configJSON.pleaseEnterPassText,configJSON.pleaseEnterPassTextArabic)}
                                        </Typography>
                                    </Box> : null
                                }
                                {this.state.activeSettingPage === 1 ?
                                    <FormControl fullWidth className="input-first-block">
                                        <label
                                            className="input-label"
                                            htmlFor="input-content-first-block"
                                            style={{fontSize: "14px"}}
                                        >
                                            {this.getContent(configJSON.enterCurrentPassText,configJSON.enterCurrentPassTextArabic)} <span>*</span>
                                        </label>
                                        <InputBase
                                            id="input-current-password"
                                            placeholder={this.getContent(configJSON.currentPassText,configJSON.currentPassTextAr)}
                                            value={this.state.currentPassword}
                                            className="input-content-first-block"
                                            onChange={(e) => this.handleChangeInput(e, 'currentPassword', 'currentPasswordError')}
                                        />
                                    </FormControl> :
                                    <>
                                        <FormControl fullWidth className="input-first-block">
                                            <label
                                                className="input-label"
                                                htmlFor="input-content-first-block"
                                                style={{fontSize: "14px"}}
                                            >
                                                 {this.getContent(configJSON.enterNewPassText,configJSON.enterNewPassTextArabic)} <span>*</span>
                                            </label>
                                            <InputBase
                                                id="input-new-password"
                                                placeholder={this.getContent(configJSON.passWordPlaceHolder,configJSON.passWordPlaceHolderAr)}
                                                value={this.state.newPassword}
                                                className="input-content-first-block"
                                                onChange={(e) => this.handleChangeInput(e, 'newPassword', 'currentPasswordError')}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth className="input-first-block">
                                            <label
                                                className="input-label"
                                                htmlFor="input-content-first-block"
                                                style={{fontSize: "14px"}}
                                            >
                                                {this.getContent(configJSON.reEnterNewPassText,configJSON.reEnterNewPassTextArabic)} <span>*</span>
                                            </label>
                                            <InputBase
                                                id="input-confirm-password"
                                                placeholder={this.getContent(configJSON.reEnterNewPassText,configJSON.reenterPlaceHolderAr)}
                                                value={this.state.confirmPassword}
                                                className="input-content-first-block"
                                                onChange={(e) => this.handleChangeInput(e, 'confirmPassword', 'currentPasswordError')}
                                            />
                                        </FormControl>
                                    </>
                                }
                                <Typography className="note-first-block">{" "}
                                </Typography>
                                {this.state.activeSettingPage === 1 ?
                                    <Button
                                        data-test-id="btn-verify-current-password"
                                        disabled={this.state.currentPasswordError || this.state.currentPassword === ''}
                                        onClick={this.verifyOldPassword}
                                        className="enter-button-first-block">
                                        {this.getContent(configJSON.enterText,configJSON.enterTextArabic)}
                                    </Button>
                                    :
                                    <Button
                                        data-test-id="btn-change-password"
                                        disabled={(this.state.newPassword !== this.state.confirmPassword) || (this.state.confirmPassword === '' || this.state.newPassword === '')}
                                        onClick={this.changePassword}
                                        className="enter-button-first-block">
                                        {this.getContent(configJSON.confirmText,configJSON.confirmTextArabic)}
                                    </Button>}
                            </Box>
                        </Box>
                        :
                        <Box data-test-id="password-change-sucess" className="outer-box-sucess">
                            <Typography className="title-block-sucess">{this.getContent(configJSON.changePassText,configJSON.changePassTextArabic)}</Typography>
                            <Box className="sucess-box">
                                <img src={imgSuccessIcon} className="sucess-img" alt="" />
                                <Typography className="sucess-title">{this.getContent(configJSON.successText,configJSON.successTextArabic)}</Typography>
                                <Typography className="sucess-msg">{this.getContent(configJSON.passSuccessText, configJSON.passSuccessTextArabic)}</Typography>
                            </Box>
                        </Box>
                    }

                </Box>
            </>
        );
    };

    helpBlocks = () => {
        return (
            <>
                <Box data-test-id="need-help-block" sx={contentBlockStyle()}>
                    {this.state.activeSettingPage !== 3 ?
                        <Box className="outer-box-help">
                            <Typography className="title-help-block">{this.handleConditon(this.state.language === "en" , "Need Help?" , "هل تحتاج إلى مساعدة؟")}</Typography>
                            <Box className="inner-box-need-help">

                                <FormControl fullWidth className="input-help-block">
                                    <label className="input-label" 
                                            style={{fontSize: "14px"}}>{this.handleConditon(this.state.language === "en" , "Type of query*" , "نوع الاستعلام*")}</label>
                                    <SelectQueryType
                                        Icon={selectMenuIcon}
                                        data-test-id="multiple-type-select"
                                        queryTypeList={this.state.queryTypeList}
                                        selectedQueryType={this.state.queryType}
                                        onChange={(v) => this.handleQueryTypeChange(v, "queryType")}
                                         language={this.state.language}        
                                        />
                                </FormControl>
                                <FormControl fullWidth className="input-help-block">
                                    <label
                                        className="input-label"
                                        htmlFor="input-content-help-block"
                                        style={{fontSize: "14px"}}
                                    >
                                       {this.handleConditon(this.state.language === "en" , "What do you want to talk about?" , "ماذا تريد أن تتحدث عنه؟")} <span>*</span>
                                    </label>
                                    <InputBase
                                        id="input-question1"
                                        placeholder={this.handleConditon(this.state.language === "en" , "What do you want to talk about?" , "ماذا تريد أن تتحدث عنه؟")}
                                        value={this.state.question1}
                                        className="input-content-help-block"
                                        onChange={(e) => this.handleChangeInput(e, "question1", "")}
                                    />
                                </FormControl>
                                <FormControl fullWidth className="input-help-block">
                                    <label className="input-label" htmlFor="input-details" 
                                            style={{fontSize: "14px"}}>
                                        {this.handleConditon(this.state.language === "en" , "Details" , "تفاصيل")} <span>*</span>
                                    </label>
                                    <InputBase
                                        id="input-details"
                                        multiline
                                        rows={6}
                                        placeholder={this.handleConditon(this.state.language === "en" , "Details" , "تفاصيل")}
                                        className="input-content-description-long"
                                        value={this.state.description}
                                        onChange={(e) => this.handleChangeInput(e, "description", "")}
                                    />
                                </FormControl>
                                <Typography className="note-first-block">{" "}
                                </Typography>
                                <div className="need-help-btn-section">
                                    <Button
                                        data-testid="handle-cancel-help"
                                        onClick={this.handleBackPage}
                                        className="cancel-button-help-block">
                                        {this.handleConditon(this.state.language === "en" , "Cancel" , "الغاء")}
                                    </Button>
                                    <Button
                                        data-test-id="handle-submit-help"
                                        disabled={this.state.question1 === "" || this.state.description === "" || this.state.queryType === ""}
                                        onClick={this.contactUs}
                                        className="submit-button-help-block">
                                        {this.handleConditon(this.state.language === "en" , "Submit" , "تقديم")}
                                    </Button>
                                </div>
                            </Box>
                        </Box>
                        :
                        <Box data-test-id="need-help-sucess" className="outer-box-sucess-help">
                            <div className="sucess-title-content">
                                <img src={imgSuccessIcon2} className="sucess-img" alt="" />
                                <Typography className="sucess-title-help">{this.handleConditon(this.state.language === "en" , "Sucess" , "نجاح")}</Typography>
                            </div>
                            <Box className="sucess-box">
                                <Typography className="sucess-msg">{this.handleConditon(this.state.language === "en" , "Your query has been successfully submitted!" , "لقد تم تقديم استفسارك بنجاح!")}</Typography>
                                <Typography className="sucess-msg" style={{ padding: "20px 0px" }}>{this.handleConditon(this.state.language === "en" , "We will get back to you shortly" , "سوف نعود إليك قريبا")}</Typography>
                            </Box>
                        </Box>
                    }

                </Box>
            </>
        );
    };

    deactivateAccountBlocks = () => {
        return (
            <>
                <Box data-test-id="deactivate-account-block" sx={contentBlockStyle()}>
                    {!this.state.deactivateConfirmation ?
                        <Box data-test-id="deactivate-account-content" className="outer-box-deactivate-account">
                            <div className="title-content-deactivate-account">
                                <img src={imgAlertIcon} className="sucess-img" alt="" />
                                <Typography className="confirmation-title">
                 {this.state.language=== "ar"? "هل أنت متأكد؟":"Are you sure?"}
                                </Typography>
                            </div>
                                <Typography className="confirmation-description">
                 {this.state.language=== "ar"? "هل تريد إلغاء تنشيط حسابك؟":"You want to deactivate your account?"}
                                   
                                </Typography>

                            <div className="need-help-btn-section">
                                <Button
                                    data-test-id="handle-yes-btn"
                                    onClick={() => this.deactiveAccount()}
                                    className= {this.state.language=== "ar"? "yes-button-help-block-arabic":"yes-button-help-block"}>
                                  {this.state.language=== "ar"?"نعم":"Yes"}   
                                </Button>
                                <Button
                                    data-testid="handle-no-btn"
                                    onClick={() => this.handleBackPage()}
                                    className="no-button-help-block">
                                     {this.state.language=== "ar"?"لا":"No"}   
                                </Button>
                            </div>
                        </Box>
                        :
                        <Box data-test-id="deactivate-account-sucess" className="outer-box-sucess-help">
                            <div className="sucess-title-content">
                                <img src={imgSuccessIcon2} className="sucess-img" alt="" />
                                <Typography className="sucess-title-help">{this.state.language=== "ar"?"النجاح":"Sucess"}</Typography>
                            </div>
                            <Box className="sucess-box">
                                <Typography className="sucess-msg">{this.state.language=== "ar"?"لقد تم إلغاء تنشيط حسابك بنجاح!":"Your account has been successfully deactivated!"}</Typography>
                            </Box>
                        </Box>
                    }
                </Box>
            </>
        );
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const active_page = this.props.navigation.getParam("option");

        return (
            <>
                <HeaderBlockWeb />
                <BackBox>
                    <CustomizableImage data-test-id="backBtn" src={backArrow} onClick={this.handleBackPage} className={this.state.language === 'ar' ? "rotatedImage" : "imageClass"} />
                    <Box
                        style={{ display: "flex", flexDirection: "column", gap: "5px" }}
                    >
                        <Box sx={styles.links} style={{fontSize: "18px"}}>{this.state.changeMailPageText.backText}</Box>
                    </Box>
                </BackBox>
                {active_page === "change_email" && this.changeEmailBlocks()}
                {active_page === "change_phone" && this.changePhoneBlocks()}
                {active_page === "change_password" && this.changePasswordBlocks()}
                {active_page === "help" && this.helpBlocks()}
                {active_page === "deactivate_account" && this.deactivateAccountBlocks()}
                <FooterBlock />
            </>
        );
        // Customizable Area End

    }
}
// Customizable Area Start

const contentBlockStyle = () => {
    return {
        margin: "0 auto 2rem auto",
        "& .outer-box-first": {
            display: "flex",
            flexDirection: "column",
            gap: "18px",
            height: "auto",
            borderRadius: "20px",
            background:
                "linear-gradient(white, white) padding-box, linear-gradient(135deg, #00F8A2, #5412FC) border-box",
            boxSizing: "border-box",
            border: "1px solid #000",
            margin: "0 auto",
            padding: "38px",
            "& .title-block": {
                textAlign: "center",
                fontSize: "24px",
                color: "#5412FC",
                fontFamily: "Montserrat",
                fontWeight: 700,
                lineHeight: "36px",
                fontSyle: "normal",
            },
            "& .inner-box": {
                width: "340px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                margin: "0 auto",
                "& .description-first-block": {
                    flexDirection: "column",
                    color: "#000",
                    textAlign: "center",
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "36px",
                    margin: "0 auto",
                },
                "& .input-first-block": {
                    width: "100%",
                    alignItems: "center",
                    gap: "16px",
                    "& label": {
                        color: "#262626",
                        fontFamily: "Montserrat",
                        fonSize: "14px !important",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "24px",
                        padding: "0px 5px",
                        width: "100%"
                    },
                    "& .MuiInputLabel-shrink": {
                        transform: "translate(0, 1.5px) scale(1,0.75)"
                    },
                    "& .MuiInputBase-input": {
                        padding: "12px 10px",
                        color: "black",
                        fontSize: "14px",
                        fontFamily: "Montserrat",
                        fontWeight: 400,
                        wordWrap: "break-word",
                        width: "320px",
                    },
                    "& .input-content-first-block": {
                        height: "48px",
                        borderRadius: "12px",
                        border: "1px solid #8C8C8C",
                        margin: 0,
                    },
                },
                "& .note-first-block": {
                    color: "#000",
                    textAlign: "center",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "24px",
                    margin: "1rem auto 0 auto",
                },
                "& .enter-button-first-block": {
                    color: "white",
                    borderRadius: "24px",
                    border: "none",
                    fontWeight: 700,
                    fontSize: "16px",
                    wordWrap: "break-word",
                    fontFamily: "Montserrat",
                    lineHeight: "34px",
                    padding: "7px 75px",
                    background: "#10BFB8",
                    cursor: "pointer",
                    margin: "1rem auto 0 auto",
                    display: "flex",
                    "&:hover": {
                        boxShadow:
                            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
                    },
                    "&:disabled": {
                        background: "#8C8C8C",
                        cursor: "context-menu",
                        "&:hover": {
                            boxShadow: "none",
                        },
                    },

                },
                "& .confirmation-msg": {
                    color: "#8C8C8C",
                    fontFamily: "Montserrat",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "24px",
                    textAlign: "center",
                    "& span": {
                        textDecorationLine: "underline"
                    },
                    "& a": {
                        textDecorationLine: "underline"
                    }
                }
            },
        },

        "& .outer-box-help": {
            borderRadius: "20px",
            background:
                "linear-gradient(white, white) padding-box, linear-gradient(135deg, #00F8A2, #5412FC) border-box",
            boxSizing: "border-box",
            border: "3px solid transparent",
            height: "auto",
            margin: "0 auto",
            padding: "24px",
            display: "flex",
            flexDirection: "column",
            gap: "24px",

            "& .title-help-block": {
                fontSize: "26px",
                fontFamily: "Montserrat",
                color: "#5412FC",
                textAlign: "center",
                lineHeight: "36px",
                fontWeight: 700,
                fontSyle: "normal",
            },
            "& .inner-box-need-help": {
                padding: "0px 28px",
                margin: "0 auto",
                display: "flex",
                flexDirection: "column",
                gap: "18px",

                "& .input-help-block": {
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    "& .MuiBox-root":{
                        margin: 0
                    },
                    "& .MuiAccordionSummary-content .heading":{
                        fontSize: '16px',
                        padding: "12px 10px"
                    },
                    "& .MuiInput-underline::before": {
                        display: "none",
                    },
                    "& label": {
                        color: "#262626",
                        fontFamily: "Montserrat",
                        fonSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "24px",
                    },
                    "& .MuiInputBase-root": {
                        margin: 0
                    },
                    "& .MuiInputBase-input": {
                        padding: "12px 10px",
                        color: "black",
                        fontSize: "16px",
                        fontFamily: "Montserrat",
                        fontWeight: 400,
                        wordWrap: "break-word",
                        width: "320px",
                    },
                    "& .input-content-help-block": {
                        width: "100%",
                        height: "48px",
                        borderRadius: "12px",
                        border: "1px solid #8C8C8C",
                    },
                    "& .input-content-description-long": {
                        width: "100%",
                        margin: "0",
                        borderRadius: "12px",
                        border: "1px solid #8C8C8C",
                    },
                },
                "& .need-help-btn-section": {
                    display: "flex",
                    justifyContent: 'space-between',
                    width: "100%",
                    gap: "12px",
                    margin: "0 auto",
                    "& .submit-button-help-block": {
                        width: "188px",
                        height: "44px",
                        boxSizing: "border-box",
                        color: "white",
                        borderRadius: "12px",
                        border: "none",
                        fontWeight: 700,
                        fontSize: "16px",
                        wordWrap: "break-word",
                        fontFamily: "Montserrat",
                        lineHeight: "34px",
                        padding: "10px 56px",
                        background: "#10BFB8",
                        cursor: "pointer",
                        margin: "0 auto",
                        display: "flex",
                        textTransform: "capitalize",
                        "&:hover": {
                            boxShadow:
                                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
                        },
                        "&:disabled": {
                            background: "#8C8C8C",
                            cursor: "context-menu",
                            "&:hover": {
                                boxShadow: "none",
                            },
                        },

                    },
                    "& .cancel-button-help-block": {
                        textTransform: "capitalize",
                        width: "188px",
                        height: "44px",
                        boxSizing: "border-box",
                        color: "#8C8C8C",
                        fontWeight: 700,
                        fontSize: "16px",
                        wordWrap: "break-word",
                        fontFamily: "Montserrat",
                        lineHeight: "34px",
                        padding: "7px 75px",
                        cursor: "pointer",
                        margin: "0 auto",
                        display: "flex",
                        borderRadius: "12px",
                        border: "2px solid #8C8C8C",
                        background: "#FFF",
                        "&:hover": {
                            boxShadow:
                                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
                        },
                    },
                },

            },
        },
        "& .outer-box-deactivate-account": {
            borderRadius: "20px",
            background:
                "linear-gradient(white, white) padding-box, linear-gradient(135deg, #00F8A2, #5412FC) border-box",
            boxSizing: "border-box",
            border: "3px solid transparent",
            height: "313px",
            margin: "0 auto",
            padding: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "48px",

            "& .title-content-deactivate-account": {
                display: "flex",
                justifyContent: "center",
                gap: "18px",
                alignItems: "center",
                "& img": {
                    width: "40px",
                    height: "40px"
                },
                "& .confirmation-title": {
                    fontFamily: "Montserrat",
                    color: "#000",
                    fontStyle: "normal",
                    fontSize: "28px",
                    lineHeight: "24px",
                    fontWeight: 700,
                },
            },
            "& .confirmation-description": {
                color: "#262626",
                textAlign: "center",
                fontFamily: "Montserrat",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
            },
            "& .need-help-btn-section": {
                display: "flex",
                justifyContent: 'center',
                width: "100%",
                margin: "0 auto",
                gap: "24px",
                "& .yes-button-help-block": {
                    borderRadius: "12px",
                    border: "2px solid #10BFB8",
                    width: "154px",
                    height: "48px",
                    color: "#10BFB8",
                    textAlign: "center",
                    fontFamily: "Montserrat",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "34px",
                    padding: "7px 75px",
                    cursor: "pointer",
                    display: "flex",
                    textTransform: "capitalize",
                    background: "#FFF",
                    "&:hover": {
                        boxShadow:
                            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
                    },
                },
                "& .yes-button-help-block-arabic": {
                    borderRadius: "12px",
                    border: "2px solid #10BFB8",
                    width: "154px",
                    height: "48px",
                    color: "#10BFB8",
                    textAlign: "center",
                    fontFamily: "Montserrat",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "34px",
                    padding: "7px 75px",
                    cursor: "pointer",
                    marginLeft: "100px",
                    display: "flex",
                    textTransform: "capitalize",
                    background: "#FFF",
                    "&:hover": {
                        boxShadow:
                            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
                    },
                },
                "& .no-button-help-block": {
                    textTransform: "capitalize",
                    width: "154px",
                    height: "48px",
                    border: " 2px solid #FF7575",
                    fontWeight: 700,
                    fontSize: "18px",
                    color: "#FF7575",
                    fontFamily: "Montserrat",
                    lineHeight: "34px",
                    padding: "7px 75px",
                    cursor: "pointer",
                    display: "flex",
                    borderRadius: "12px",
                    background: "#FFF",
                    "&:hover": {
                        boxShadow:
                            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
                    },
                },
            },
        },
        "& .outer-box-sucess": {
            width: "500px",
            height: "500px",
            borderRadius: "20px",
            border: "1px solid #000",
            margin: "0 auto",
            "& .title-block-sucess": {
                color: "#5412FC",
                textAlign: "center",
                fontFamily: "Montserrat",
                fontSize: "30px",
                fontSyle: "normal",
                fontWeight: 700,
                lineHeight: "36px",
                padding: "80px 0",
            },
            "& .sucess-box": {
                textAlign: "center",
                "& .sucess-title": {
                    color: "#000",
                    fontFamily: "Montserrat",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "24px",
                    margin: "24px auto"
                },
                "& .sucess-msg": {
                    color: "#000",
                    textAlign: "center",
                    fontFamily: "Montserrat",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "24px",
                    width: "340px",
                    margin: "60px auto"
                },
            },
        },
        "& .outer-box-sucess-help": {
            width: "953px",
            height: "auto",
            borderRadius: "20px",
            background:
                "linear-gradient(white, white) padding-box, linear-gradient(135deg, #00F8A2, #5412FC) border-box",
            boxSizing: "border-box",
            border: "3px solid transparent",
            margin: "0 auto",
            "& .sucess-title-content": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "80px 40px",
                "& .sucess-title-help": {
                    fontFamily: "Montserrat",
                    color: "#000",
                    fontStyle: "normal",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 700,
                    margin: "0px 40px"
                },
            },
            "& .sucess-box": {
                textAlign: "center",
                padding: "20px 225px 145px",
                "& .sucess-msg": {
                    textAlign: "center",
                    fontFamily: "Montserrat",
                    color: "#000",
                    fontSize: "20px",
                    fontWeight: 500,
                    margin: "0px auto",
                    lineHeight: "24px",
                    fontStyle: "normal",
                },
            },
        },
    };
};

const errorMsgStyle = () => {
    return {
        color: "#000",
        textAlign: "center",
        fontFamily: "Montserrat",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
        borderRadius: "12px",
        background: "rgba(255, 117, 117, 0.30)",
        padding: "5px",
        marginBottom: "24px",
        "& .errormsg_text": {
            color: "#FF7575",
            textAlign: "center",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "24px",
        }
    }
}

const BackBox = styled(Box)({
    padding: "24px 50px",
    display: "flex",
    gap: "30px",
    alignItems: "center",
    "& .rotatedImage": {
        rotate: "180deg"
    },
    "@media screen and (max-width:599px)": {
        gap: "15px",
        padding: "15px 40px"
    }
});

// Customizable Area End