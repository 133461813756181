// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Dialog,
  IconButton,
  Modal,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { HelpOutlineOutlined } from "@material-ui/icons";
import AcquisitionProcessController, {
  Props,
  configJSON
} from "./AcquisitionProcessController.web";
import {
  arrowDown,
  chatIcon,
  clearIcon,
  closeIcon,
  documentIcon,
  documentIconColor,
  image1,
  rightIcon,
  uploadDocumentIcon,
} from "./assets";
import { Link } from "react-router-dom";
import Chat from "../../chat/src/Chat.web";
import { AcquisitionProcessAction } from "../../../components/src/AcquisitionProcessAction";
import { conditionSort } from "../../../components/src/Helper";
// Customizable Area End

export class AcquisitionProcessStepAction extends AcquisitionProcessController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // async componentDidMount(): Promise<void> {
  //   this.getDealDetails(this.props.accessRequestId || 0);
  //   this.getProfileDetails();
  // }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, activeStep } = this.props;
    const buyer = this.state.userType ==="buyer";
    const getAction1 = () => {
      return (
        <div className={classes.actionTabStep1}>
          <div className={classes.noActionText}>
            <hr />
            {this.state.pageText.noActionRequired}
            <hr />
          </div>
          <div className={classes.actionTabStep1NextStage}>
          {this.state.pageText.proceedToNextStage}
          </div>
          <button
            data-test-id="action-tab-step-1-yes-button"
            className={classes.actionTabStep1YesButton}
            onClick={this.handleActiveStepOneChange}
            disabled={this.state.isBuyerProceedstage1 || this.state.isSellerProceedstage1}
          >
            {this.state.pageText.yes}
          </button>
        </div>
      );
    };

    const getAction2 = () => {
      return (
        <div className={classes.actionTabStep2}>
          <div className={classes.actionTabStep2LOI}>{this.state.pageText.letterOfIntent}</div>
          <div className={classes.actionTabStep2Section}>
            <div
              data-test-id="loi-document-upload"
              className={classes.actionTabStep2LetterOfIntent}
              onClick={this.handleLOIDocumentUpload}
              style={this.getIfCondition(
                this.state.isLOIDocumentUploaded,
                {
                  border: "2px #10BFB8 solid",
                  color: "#10BFB8",
                },
                {
                  border: "2px #8C8C8C dashed",
                  color: "#8C8C8C",
                }
              )}
            >
              <img
                src={this.getIfCondition(
                  this.state.isLOIDocumentUploaded,
                  documentIconColor,
                  documentIcon
                )}
              />
              {this.state.pageText.letterOfIntent}
            </div>
            {buyer && 
             this.getIfCondition(this.state.loiStatusSend,<button
            data-test-id="loi-send-button"
            className={classes.actionTabStep2ReviewButton}
            disabled={!this.state.isLOIDocumentUploaded}
            onClick={this.sendDocumentToSeller}
          >
            {this.state.pageText.send}
          </button>,<div
                className={classes.actionTabStepAcceptedDone}
                data-test-id="loi-accepted"
              >
                <img src={rightIcon} />
                {this.state.pageText.sent}
              </div>)}
            {!buyer && this.getIfCondition(
              !this.state.isLOIAccepted,
              <>
                {this.getIfCondition(
                  this.state.isLOIDocumentUploaded,
                  <div className={classes.actionTabStep2ReviewCheckBoxDiv}>
                    <Checkbox
                      id="reviewed-loi"
                      checked={this.state.isLOIReviewChecked}
                      onChange={(e) => this.handleLOIReviewChecked(e)}
                    />
                    <label htmlFor="reviewed-loi">
                      {this.state.pageText.iHaveRead}
                    </label>
                  </div>,
                  <></>
                )}
                <button
                  data-test-id="loi-proceed-button"
                  className={classes.actionTabStep2ReviewButton}
                  disabled={!this.state.isLOIReviewChecked}
                  onClick={this.handleLOIProceedClick}
                >
                  {this.getIfCondition(
                    this.state.isLOIReviewChecked,
                    this.state.pageText.proceed,
                    this.state.pageText.review
                  )}
                </button>
              </>,
              <></>
            )}
            <hr
            />
            { !buyer && this.getIfCondition(
              this.state.isLOIAccepted,
              <div
                className={classes.actionTabStepAcceptedDone}
                data-test-id="loi-accepted"
              >
                <img src={rightIcon} />
                {this.state.pageText.accepted}
              </div>,
              <></>
            )}
            <div className={classes.actionTabStep2LOIText}>
              {!buyer ? this.getIfCondition(
                this.state.isLOIAccepted,
                <>
                  {this.state.pageText.stageComplete} <br /> {this.state.pageText.moveToD}
                </>,
                this.state.pageText.loiProceed
               ): this.getIfCondition(this.state.loiStatus, this.state.pageText.underReview,
               this.getIfCondition(this.state.isLOIDocumentUploaded, this.state.pageText.plSign,
               this.state.pageText.construct
               ))}
            </div>
          </div>
        </div>
      );
    };

    const getAction3 = () => {
      return (
        <div className={classes.actionTabStep3}>
           <Accordion expanded = {this.state.isExpanded} onChange={this.handleDocAccordian}>
            <AccordionSummary
              expandIcon={<img src={arrowDown} />}
              aria-controls="Requested-Documents"
              id="Requested-Documents"
            >
              <div className="heading">{this.state.pageText.requestDoc}</div>
            </AccordionSummary>
            <AccordionDetails>
             {this.getIfCondition(!buyer, (<><div className={classes.actionTabStep3RequestedDocumentSection}>
                {this.state.requestedDocumentsList?.map((list,index) => (
                  <div className={classes.actionTabStep3RequestedDocumentDiv}>
                    <div
                      className={classes.actionTabStep3RequestedDocumentCount}
                    >
                      {index+1}.
                    </div>
                    <div
                      className={classes.actionTabStep3RequestedDocumentUpload}
                    >
                      <div
                        className={classes.actionTabStep3RequestedDocumentTitle}
                      >
                        {list.title}
                      </div>
                      <div
                        className={
                          classes.actionTabStep3RequestedDocumentButtonDiv
                        }
                      >
                        {this.getIfCondition(
                          !list.file,
                          <input
                            type="file"
                            id={`document-upload-${index}`}
                            hidden
                            onChange={(e) =>
                              this.handleRequestedDocumentUploadFile(e, list.id)
                            }
                          />,
                          <></>
                        )}
                        <label
                          htmlFor={`document-upload-${index}`}
                          className={
                            classes.actionTabStep3RequestedDocumentButton
                          }
                          style={this.getIfCondition(
                            list.file,
                            {
                              background: "rgba(0, 248, 162, 0.30)",
                              color: "#262626",
                            },
                            {}
                          )}
                        >
                          <img
                            src={this.getIfCondition(
                              list.file,
                              documentIcon,
                              uploadDocumentIcon
                            )}
                          />
                          {list.file ? list.file.name : this.state.pageText.uploadDocument}
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <AcquisitionProcessAction labels={{send: this.state.pageText.send, sent: this.state.pageText.sent}}  requestedDocumentsList={this.state.requestedDocumentsList} isDocumentsRevied={this.state.isDocumentsRevied} updateDocumentsFromSeller={this.updateDocumentsFromSeller} />
              </>),
              <>
              <div className={classes.actionTabStep3RequestedDocumentSection}>
                {this.state.requestedDocumentsForSeller.map((ele,index) => (
                  <div style={{display:'flex', alignItems:'center', width:'100%', marginBottom: "10px"}}>
                <span>{index+1}.{" "}</span>
                    <TextField fullWidth className={classes.textFields} onChange={(e) => { this.onInputChange(index, e) }} data-test-id={`${index}-input`}
                      value={this.state.requestedDocumentsForSeller[index]}
                      disabled={this.state.isDocumentSent} />
                </div>
                ))}

                {this.getIfCondition(this.state.isDocumentSent,<></>,
                <span className={classes.actionTabSpan}>
                <IconButton onClick={this.inputs} style={{justifyContent:'flex-start', width:'28px'}} data-test-id="add-icon">
                  <AddCircleOutlineIcon style={{fontSize:'28px'}} />
                </IconButton>
                </span>)}
                <div className={classes.actionTabStep3RequestedDocumentSend}>
                <button
                  className={classes.actionTabStep3SendButton}
                  disabled={
                    (!this.state.requestedDocumentsForSeller.every(
                      (list) => (list !=="")
                    ) || this.state.isDocumentSent)
                  }
                  data-test-id="request-button"
                  onClick={this.requestDocuments}
                >
                  {this.getIfCondition(this.state.isDocumentSent, this.state.pageText.requested, this.state.pageText.request)}
                </button>
              </div>
              </div>
              </>)}
            </AccordionDetails>
          </Accordion>
          {this.getIfCondition(buyer,<Accordion >
            <AccordionSummary
              expandIcon={<img src={arrowDown} />}
              aria-controls="review-Documents"
              id="review-Documents"
            >
              <div className="heading">{this.state.pageText.reviewDoc}</div>
            </AccordionSummary>
            <AccordionDetails>
             {this.getIfCondition(buyer, (<>
                  <div className="mainDiv">
                  {this.state.reviedDocuments.map((item,index) => (
                    <div key={index} style={{marginBottom:"30px",gap:"10px",marginRight:"5px"}}  className="document-list-div" onClick={()=>{window.open(item.url, "_blank")}}>
                      <img src={documentIcon} />
                      <div className="document-text">
                        {item.document_title}
                      </div>
                    </div>
                  ))}
                  </div>
              </>),
              <>
              </>)}
            </AccordionDetails>
          </Accordion>,<></>)}
          <Accordion >
            <AccordionSummary
              expandIcon={<img src={arrowDown} />}
              aria-controls="Sale-Purchase-Agreement"
              id="Sale-Purchase-Agreement"
            >
              <div className="heading">{this.state.pageText.spa}</div>
            </AccordionSummary>
            <AccordionDetails>
              <div
                className={classes.actionTabStep3SalePurchaseAgreementSection}
              >
                <div
                  data-test-id="sale-purchase-agreement-document-upload"
                  className={classes.actionTabStep3SalePurchaseAgreement}
                  onClick={this.handleSalesPurchaseDocumentUpload}
                  style={this.getIfCondition(
                    this.state.isSalePurchaseAgreementUploaded,
                    {
                      border: "2px #10BFB8 solid",
                      color: "#10BFB8",
                    },
                    {
                      border: "2px #262626 solid",
                      color: "#000000",
                    }
                  )}
                >
                  <img
                    src={documentIcon}
                    style={{
                      opacity: this.getIfCondition(
                        this.state.isSalePurchaseAgreementUploaded,
                        1,
                        0.4
                      ),
                    }}
                  />
                  {this.state.pageText.spa}
                </div>
                {this.getIfCondition(!buyer,this.getIfCondition(
                  this.state.isSalePurchaseAgreementDone,
                  <div
                    className={classes.actionTabStep3SalePurchaseAgreementDone}
                  >
                    <div
                      className={classes.actionTabStepAcceptedDone}
                      data-test-id="sale-purchase-agreement-done"
                    >
                      <img src={rightIcon} />
                      {this.state.pageText.done}
                    </div>
                  </div>,
                  <div
                    className={classes.actionTabStep3SalePurchaseAgreementSend}
                  >
                    <button
                      data-test-id="sale-purchase-agreement-proceed-button"
                      className={classes.actionTabStep3SendButton}
                      disabled={!this.state.isSalePurchaseAgreementUploaded}
                      onClick={this.handleSalePurchaseAgreementProceed}
                    >
                      {this.getIfCondition(
                        this.state.isSalePurchaseAgreementUploaded,
                        this.state.pageText.proceed,
                        this.state.pageText.send
                      )}
                    </button>
                  </div>
                ),<>
                 <div
                    className={classes.actionTabStep3SalePurchaseAgreementSend}
                  >
                    {this.getIfCondition(!this.state.isSalePurchaseDocSent,<button
                      data-test-id="sale-purchase-agreement-send-button"
                      className={classes.actionTabStep3SendButton}
                      disabled={!this.state.isSalePurchaseAgreementUploaded}
                      onClick={this.updateStatusOfSalesPurchaseBybuyer}
                    >
                      {this.state.pageText.send}
                    </button>,<div
                          className={classes.actionTabStepAcceptedDone}
                          data-test-id="loi-accepted"
                        >
                        <img src={rightIcon} />
                        {this.state.pageText.sent}
                      </div>)}
                  </div>
                </>)}
                {this.getIfCondition(!buyer,<div className={classes.actionTabStepText}>
                  {this.getIfCondition(
                    this.state.isSalePurchaseAgreementDone,
                    this.state.pageText.stageComplete,
                    this.getIfCondition(
                      this.state.isSalePurchaseAgreementUploaded,
                      this.state.pageText.clickToProceed,
                      this.state.pageText.yetToInit
                    )
                  )}
                </div>,<>
                <div className={classes.actionTabStepText}>
                  {this.getIfCondition(this.state.isSalePurchaseDocSent, this.state.pageText.underReview, this.state.pageText.signTheSpa)}
                </div>
                </>)}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      );
    };

    const getAction4 = () => {
      const whatNextList = [
        {
          id: 1,
          title: this.state.pageText.checkTerms,
          description: this.state.pageText.makeSure,
        },
        {
          id: 2,
          title: this.state.pageText.use,
          description:
          this.state.pageText.safe,
        },
        {
          id: 3,
          title: this.state.pageText.signDocs,
          description: this.state.pageText.get,
        },
        {
          id: 4,
          title: this.state.pageText.consult,
          description:
          this.state.pageText.have,
        },
        {
          id: 5,
          title: this.state.pageText.paid,
          description:
          this.state.pageText.once,
        },
      ];

      return (
        <div className={classes.actionTabStep4}>
          <div className={classes.actionTabStep4Description}>
            <div className={classes.actionTabStep4DescriptionText}>
            {this.state.pageText.hereNext}
            </div>
          </div>
          <div className={classes.actionTabStep4AccordionDiv}>
            {whatNextList.map((list) => (
              <Accordion key={list.id}>
                <AccordionSummary
                  expandIcon={<img src={arrowDown} />}
                  aria-controls={`What-Next-List-${list.id}`}
                  id={`What-Next-List-${list.id}`}
                >
                  <div className="heading">{list.title}</div>
                </AccordionSummary>
                <AccordionDetails>{list.description}</AccordionDetails>
              </Accordion>
            ))}
          </div>
         {
         this.getIfCondition(
            !this.state.isStepFourDone,
            <div className={`${classes.actionTabStep4Button} finalizeButtonBox`}>
              <button
                data-test-id="step-four-yes-button"
                className={classes.actionTabStep4ButtonYes}
                onClick={this.stage4Api}
              >
                {this.state.pageText.finalizeAcquisition}
              </button>
            </div>,
            <div className={`${classes.actionTabStep4Button} finalizeButtonBox`}>
            <div
              className="finalizedTextBox"
             data-test-id="step-four-done"
            >
              {this.state.pageText.finalized}
            </div>
            <div className={classes.actionTabStepText}>{this.state.pageText.stageComplete}</div>
            <div className={classes.actionTabStepText}>{this.state.pageText.moveToLastStage}</div>
          </div>,
          )}
          <Box className="supportBox">
            <Typography align="left">{this.state.pageText.needHelpText}</Typography>
            <Button className={classes.actionTabStep4ButtonNo}

              onClick={this.handleHelpNavigation}
              data-test-id="supportTestId">
              {this.state.pageText.contactSupport}
            </Button>
          </Box> 
        </div>
      );
    };

    const chatBtn = () => {
      return (
        <>{this.getIfCondition(!this.state.displayChat,
          <div>
            <button data-test-id="chat-btn" style={conditionSort(this.state.language == 'en', {right: 24}, {left: 24})} className={classes.chatButton} onClick={() => this.toggleChatComponent()}>
              <img src={chatIcon} alt="chat" />
              {conditionSort(this.state.unreadCount > 0, <div className={classes.chatBadge}>{this.state.unreadCount}</div>, null)}
            </button>
          </div>, null)}
        </>
      )
    }

    const getActions = () => {
      switch (activeStep) {
        case 1:
          return getAction1();
        case 2:
          return getAction2();
        case 3:
          return getAction3();
        case 4:
          return getAction4();
        case 5:
          return (
            <div className={classes.actionTabStep5}>
              <div className={classes.actionTabStep5Title}>
                {this.state.pageText.congrat} {this.getIfCondition(buyer,this.state.pageText.acquiring, this.state.pageText.selling)} {this.state.pageText.yourSu}
              </div>
              <div className={classes.actionTabStep5Details} style={{border: 'none', justifyItems: 'center', padding: '0px'}}>
                    <div className={classes.actionTabStep5Question}>
                      {this.state.pageText.inte}
                    </div>
                    <button data-test-id="feedback-btn" className={classes.actionTabStep4ButtonYes} onClick={() => this.handleNavigation("Feedback", true)}>
                      {this.state.pageText.share}
                    </button>
                  </div>
              <div className={classes.actionTabStep5Content}>
                <div className={classes.actionTabStep5SubTitle}>
                  {this.state.pageText.wn}
                </div>
                <div className={classes.actionTabStep5DetailsSection}>
                  <div className={classes.actionTabStep5Details}>
                    <div className={classes.actionTabStep5Question}>
                      {this.getIfCondition(this.state.userType === "seller", this.state.pageText.got, this.state.pageText.buyOther)}
                    </div>
                    <button className={classes.actionTabStep5Button}
                      data-test-id="listingNavigationTestId"
                      onClick={this.stageFiveUserNavigation}>
                      {this.getIfCondition(this.state.userType === "seller", this.state.pageText.add, this.state.pageText.exploreMarket)}
                    </button>
                  </div>
                  <div className={classes.actionTabStep5Details}>
                    <div className={classes.actionTabStep5Question}>
                      {this.getIfCondition(this.state.userType === "buyer", this.state.pageText.intrestedToSell, this.state.pageText.intr)}
                    </div>
                    <button className={classes.actionTabStep5Button}
                      data-test-id="signInTestId"
                      onClick={this.logOutModal}>
                      {this.getIfCondition(this.state.userType === "buyer", this.state.pageText.becomeSeller, this.state.pageText.become)}
                    </button>
                  </div>
                  
                </div>
              </div>
              {chatBtn()}
              <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                open={this.state.confirmLogOut}
                className={classes.deleteModal}
              >
                <Paper elevation={0} className="modalPaper" style={{width: "700px"}}>
                  <Typography variant="h5" align="center" className="modalBody" data-test-id={"confirmTestId"}>
                      {this.state.pageText.buyerConfirmationTxt}
                  </Typography>
                  <div className="modalFooter">
                    <Button style={{width: "350px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "4px", paddingRight: "4px"}} onClick={this.navigateTosignIn} data-test-id={"yesConfirmTestId"} className="deleteButton">
                      {this.state.pageText.ySp}</Button>
                    <Button style={{width: "350px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "4px", paddingRight: "4px"}} onClick={this.logOutModal} data-test-id={"noConfirmTestId"} className="cancelButton">
                      {this.state.pageText.nKh}</Button>
                  </div>
                </Paper>
              </Modal>
            </div>
          );
        default:
          return null;
      }
    };
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <div
          className={classes.mainComponent}
          data-test-id="main-acquisition-process-step-action"
        >
          <div className={classes.ActionHeadingButtonDiv}>
            <div className={classes.ActionHeadingDiv}>
              <img src={this.state.clientImage} className={classes.profileImage} />
              <div className={classes.ActionHeadingSection}>
                <p className={classes.ActionHeadingText} data-test-id="profileDataTestId">{conditionSort(this.state.language === "en" , this.state.clientName , this.state.clientNamear)}</p>
                <p className={classes.ActionSubHeadingText}>
                  {conditionSort(this.state.language === "en" , this.state.clientBio , this.state.clientBioar)}
                </p>
              </div>
            </div>
            <Box
              className={classes.viewProfileButton}
              data-test-id="profileBtnTestId"
              onClick={this.viewClientProfile}
            >{this.state.pageText.viewProfile}</Box>
          </div>
          <div
            className={classes.stepActionTabDiv}
          >
            {this.getIfCondition(
              activeStep === 5,
              <div
                className={classes.stepActionTabValueDiv}
                style={{ borderTopLeftRadius: "12px" }}
              >
                <div
                  className={classes.selectedColorDiv}
                  style={{
                    background: "#10BFB8",
                    borderTopLeftRadius: "12px",
                  }}
                />
               <div className={classes.tabDiv} style={{overflowY: this.getIfCondition(this.state.displayChat, "", "auto")}}>
                {this.getIfCondition(this.state.displayChat,<Chat navigation={this.props.navigation} id={this.state.dealId.toString()}
                      dealDetails={this.state.dealDetails} closeChat={() => this.toggleChatComponent()} />, getActions())}
                    </div>
              </div>,
              <>
                <Tabs
                  data-test-id="step-action-tab"
                  value={this.state.stepActionTab}
                  onChange={(_, v) => this.handleTabChange(v)}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab
                    label={this.state.pageText.documents}
                    value="Documents"
                    className="documents-tab"
                  />
                  <Tab
                    label={this.state.pageText.actions}
                    value="Actions"
                    className="actions-tab"
                  />
                </Tabs>
                <div className={classes.stepActionTabValueDiv}
                style={conditionSort(this.state.language == 'en', {borderTopLeftRadius: "0"}, {borderTopRightRadius: "0"})}
                >
                  <div
                    className={classes.selectedColorDiv}
                    style={{
                      opacity: this.getIfCondition(
                        this.state.stepActionTab === "Documents",
                        0.6,
                        0.8
                      ),
                      borderTopRightRadius: conditionSort(this.state.language == 'ar', '0px', "12px"),
                      borderTopLeftRadius: conditionSort(this.state.language == 'ar', '12px', "0px"),
                    }}
                  />
                  <div className={classes.tabDiv} style={{overflowY: this.getIfCondition(this.state.displayChat,"", "auto")}}>
                    {this.getIfCondition(
                      this.state.stepActionTab === "Documents",
                      <>
                        <Modal
                          disablePortal
                          disableEnforceFocus
                          disableAutoFocus
                          open={this.state.openDeleteModal}
                          className={classes.deleteModal}
                        >
                          <Paper elevation={0} className="modalPaper">
                            <Typography variant="h5" align="center" className="modalBody" data-test-id={"modalText"}>
                              {this.state.pageText.sureToDelete}
                              {this.state.deleteDocument.title} ?
                            </Typography>
                            <div className="modalFooter">
                              <Button onClick={this.handleDeleteDocument}  data-test-id={"yesBtn"} className="deleteButton">{this.state.pageText.yes}</Button>
                              <Button onClick={this.handleCloseDeleteModal} data-test-id={"noBtn"} className="cancelButton">{this.state.pageText.no}</Button>
                            </div>
                          </Paper>
                        </Modal>
                        <div className={classes.documentsTabDiv}>
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<img src={arrowDown} />}
                              aria-controls="Shared-with-me"
                              id="Shared-with-me"
                            >
                              <div className="heading">{this.state.pageText.withMe}</div>
                            </AccordionSummary>
                            <AccordionDetails>
                              {this.state.documentsSharedWithMe.map((item,index) => (
                                <div key={index} className="document-list-div" onClick={()=>{this.handleFileOpen(item.url)}} data-test-id={`shared-with-me-${index}`}>
                                  <img src={documentIcon} />
                                  <div className="document-text">
                                    {item.title}
                                  </div>
                                </div>
                              ))}
                            </AccordionDetails>
                          </Accordion>
                          <Accordion >
                            <AccordionSummary
                              expandIcon={<img src={arrowDown} />}
                              aria-controls="Shared-by-me"
                              id="Shared-by-me"
                            >
                              <div className="heading">{this.state.pageText.myMe}</div>
                            </AccordionSummary>
                            <AccordionDetails>
                              {this.state.documentsSharedByMe?.map((item,index) => (
                                <div className="mySharedDoc">
                                <div key={index} className="document-list-div" data-test-id={`shared-by-me-${index}`} onClick={()=> {this.handleFileOpen(item.url)}}>
                                  <img src={documentIcon} />
                                  <div className="document-text" data-test-id={`docTitleText${index}`}>
                                    {item.title}
                                  </div>
                                  </div>
                                  {this.getIfCondition((item.title !== configJSON.loiText &&
                                    item.title !== configJSON.sellPurchaseText
                                  ),
                                    <CancelOutlinedIcon className="cancelIcon" data-test-id={`deleteBtn_${index}`} onClick={() => this.openDeleteModal(item)} />,
                                    <></>)}
                                </div>
                              ))}
                            </AccordionDetails>
                          </Accordion>
                        </div>
                        <button
                          data-test-id="upload-document-button"
                          className={classes.uploadDocumentButton}
                          onClick={() => this.handleUploadDocumentOnClick(true)}
                        >
                          <img src={uploadDocumentIcon} />
                          {this.state.pageText.uploadDocument}
                        </button>
                      </>,
                      <>
                      {this.getIfCondition(this.state.displayChat,<Chat data-test-id="chat-comp" navigation={this.props.navigation} id={this.state.dealId.toString()}
                          dealDetails={this.state.dealDetails} closeChat={() => this.toggleChatComponent()} />,getActions())}
                      </>
                    )}
                  </div>
                  {chatBtn()}
                </div>
              </>
            )}
          </div>
        </div>
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={this.state.openUploadDocumentDialog}
          className={classes.uploadDocumentDialog}
          scroll="body"
        >
          <div className={classes.uploadDocumentDialogTitleDiv}>
            <div className={classes.uploadDocumentDialogTitle}>
              {this.state.pageText.uploadDocument}
            </div>
            <button
              data-test-id="upload-document-close-button"
              className={classes.uploadDocumentDialogCloseButton}
              onClick={() => this.handleUploadDocumentOnClick(false)}
            >
              <img src={closeIcon} />
            </button>
          </div>
          <div className={classes.uploadDocumentDialogTitleLabel}>{this.state.pageText.title} :</div>
          <div className={classes.uploadDocumentDialogInputDiv}>
            <input
              id="document-name"
              className={classes.uploadDocumentDialogInput}
              onChange={(e)=> {this.setState({uploadDocumentTitle: e.target.value})}}
              style={this.getIfCondition(
                this.state.uploadDocumentFile,
                {
                  borderColor: "#10BFB8",
                },
                {
                  borderColor: "#8C8C8C",
                }
              )}
            />
            {this.getIfCondition(
              this.state.uploadDocumentFile,
              <button
                data-test-id="upload-document-clear-button"
                className={classes.uploadDocumentDialogButton}
                style={{
                  borderColor: "#FF7575",
                }}
                onClick={() =>
                  this.handleUploadDocumentFileInputChange("", true)
                }
              >
                <img src={clearIcon} />
              </button>,
              <>
                <input
                  type="file"
                  id="document-upload"
                  hidden
                  onChange={this.handleUploadDocumentFileInputChange}
                />
                <label
                  htmlFor="document-upload"
                  className={classes.uploadDocumentDialogButton}
                >
                  <img src={uploadDocumentIcon} />
                </label>
              </>
            )}
            {this.state.uploadDocumentFile && (
              <div className={classes.uploadDocumentFileName}>
                {this.state.uploadDocumentFile.name}
              </div>
            )}
          </div>
          <div className={classes.uploadDocumentUploadButtonDiv}>
            <button
              className={classes.uploadDocumentUploadButton}
              disabled={!this.state.uploadDocumentFile}
              onClick={ ()=>{this.addFiles(this.state.accessRequestId || 0)}}
              data-test-id="add-file-button"
            >
              {this.state.pageText.upload}
            </button>
          </div>
        </Dialog>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles: any = {
  mainComponent: {
    borderRadius: "20px",
    border: "1px #8C8C8C solid",
    padding: "25px",
    width: "64%",
    boxSizing: "border-box",
    "@media (max-width:1250px)":{
      width:'60%'
    },
    "@media (max-width:768px)":{
      width:'100%',
      padding: "25px 19px"
    }
  },
  ActionHeadingButtonDiv: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    gap: "72px",
    "@media (max-width:959px)": {
      display: "grid",
      justifyContent: "normal",
      gap:0
    },
  },
  ActionHeadingDiv: {
    display: "flex",
    alignItems: "center",
    gap: "26px",
    "@media (max-width:768px)":{
      gap:"16px"
    }
  },
  ActionHeadingSection: {
    display: "grid",
    gap: "10px",
    "@media (max-width:768px)":{
      gap:"0"
    }
  },
  ActionSubHeadingText: {
    color: "black",
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: 400,
    lineHeight: "20px",
    margin: "0",
    "@media (max-width:768px)":{
      fontSize: "10px",
    }
  },
  ActionHeadingText: {
    color: "black",
    fontSize: "24px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "0",
    "@media (max-width:768px)":{
      fontSize: "16px",
    }
  },
  profileImage:{
    borderRadius:"50%",
    width:"80px",
    height:"80px",
    "@media (max-width:768px)":{
      width:'54px',
      height:'54px'
    }
  },
  viewProfileButton: {
    background: "transparent",
    color: "#5412FC",
    fontSize: "18px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    lineHeight: "24px",
    border: "none",
    textDecoration: "underline",
    whiteSpace: "nowrap",
    cursor: "pointer",
    "@media (max-width:768px)":{
      fontSize: "12px",
    }
  },
  stepActionTabDiv: {
    paddingTop: "25px",
    "& .MuiTabs-centered": {
      justifyContent: "normal",
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiTab-textColorPrimary": {
      background: "#5412FC",
      borderTopLeftRadius: "12px",
      borderTopRightRadius: "12px",
      color: "white",
      fontSize: "18px",
      fontFamily: "Montserrat",
      fontWeight: 500,
      lineHeight: "16px",
      textTransform: "none",
      "@media (max-width:768px)":{
        fontSize: "14px",
      }
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      fontWeight: 700,
    },
    "& .documents-tab": {
      opacity: 0.6,
    },
    "& .actions-tab": {
      opacity: 0.8,
    },
  },
  stepActionTabValueDiv: {
    background: "white",
    borderRadius: "12px",
    border: "1px #8C8C8C solid",
    borderTop: "none",
    padding: "30px 30px 25px",
    position: "relative",
    height: "512px",
    "@media (max-width:768px)":{
      padding:'24px 16px 24px 16px'
    }

  },
  selectedColorDiv: {
    position: "absolute",
    background: "#5412FC",
    width: "100%",
    height: "12px",
    top: 0,
    left: 0,
  },
  chatButton: {
    background: "#10BFB8",
    border: "none",
    padding: "10px",
    borderRadius: "12px",
    position: "absolute",
    bottom: 24,
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
  },
  chatBadge: {
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    backgroundColor: 'red',
    color: 'white',
    borderRadius: '50%',
    padding: '5px 10px',
    fontSize: '12px',
  },
  tabDiv: {
    height: "100%",
    paddingRight: "4px",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      background: "white",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#8C8C8C",
      borderRadius: "4px",
    },
    "& .MuiAccordion-root": {
      boxShadow: "none",
      borderRadius: "12px",
      border: "1px #8C8C8C solid",
      margin: "0",
      
    },
    "& .MuiAccordion-root:before": {
      backgroundColor: "transparent",
    },
    "& .MuiAccordionSummary-root": {
      padding: "0px",
      minHeight: "auto",
      width:'100%',
      "& *": {
        cursor: "pointer",
      },
    },
    "& .MuiIconButton-root": {
      margin: "0 !important",
      padding: "0px 20px",
      "@media (max-width:768px)":{
        padding:'12px'
      }
    },
    "& .MuiAccordionSummary-content": {
      margin: "0",
      padding: "20px",
      "@media (max-width:768px)":{
        padding: "12px",
      },
      "& .heading": {
        color: "black",
        fontSize: "18px",
        fontFamily: "Montserrat",
        fontWeight: 600,
        "@media (max-width:768px)":{
          fontSize:'14px',
        },
      },
    },
  },
  deleteModal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    "& .modalPaper": {
      width: "500px",
      height: "280px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
      borderRadius: "20px",
      padding: "20px",
      boxSizing: "border-box",
      "& .MuiTypography-h5": {
        fontSize: "24px",
        fontFamily: "Montserrat",
        fontWeight: 700
      }
    },
    "& .modalFooter": {
      justifyContent: "center",
      display: "flex",
      gap: "30px",
      width: "100%",
      "& .MuiButton-root": {
        borderRadius: "12px",
        padding: "10px 24px",
        fontSize: "18px",
        fontFamily: "Montserrat",
        fontWeight: 700,
        width: "200px",
        lineHeight: "24px",
        textTransform: "none"
      }
    },
    "& .deleteButton": {
      background: "#10BFB8",
      color: "#FFFFFF",
    },
    "& .cancelButton": {
      border: "2px solid #FF7575",
      color: "#FF7575"
    }
  },
  documentsTabDiv: {
    display: "grid",
    gap: "20px",
    width:'100%',
    "& .MuiAccordionDetails-root": {
      padding: "15px",
      flexWrap: "wrap",
      gap: "20px 40px",
      "@media (max-width:768px)":{
        padding: "20px 10px",
        justifyContent: "flex-start",
        gap:'32px'
      },
      "& .document-list-div": {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        cursor:'pointer',
        "@media (max-width:768px)":{
          gap:'12px'
        },
      },
      "& .document-text": {
        color: "black",
        fontSize: "18px",
        fontFamily: "Montserrat",
        fontWeight: 500,
        lineHeight: "24px",
        "@media (max-width:768px)":{
          fontSize: "14px",
        },
      },
    },
    "& .mySharedDoc": {
      position: "relative",
      "& .cancelIcon": {
        position: "absolute",
        top: -13,
        right: -25,
        color: "red",
        cursor: "pointer"
      }
    }
  },
  uploadDocumentButton: {
    background: "white",
    borderRadius: "12px",
    border: "2px #10BFB8 solid",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "32px",
    padding: "8px",
    position: "absolute",
    bottom: 34,
    width: "77.3%",
    color: "#10BFB8",
    fontSize: "24px",
    fontFamily: "Montserrat",
    fontWeight: "500",
    lineHeight: "24px",
    "@media (max-width:1400px)":{
      width: "70%"
    },
    "@media (max-width:1200px)":{
      width: "auto",
      paddingLeft: "10px",
      paddinRight: "10px",
      gap: "10px"
    },
    "@media (max-width:768px)":{
      fontSize: "18px",
      marginBottom: "50px",
      gap:'10px'
    },
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
  },
  uploadDocumentDialog: {
    "& .MuiDialog-scrollBody": {
      marginLeft: "7%",
      textAlign: "left",
      "@media (max-width:768px)":{
        marginLeft:'0%',
        textAlign:"center"
      },
    },
    "& .MuiDialog-paper": {
      maxWidth: "538px",
      width: "100%",
      boxShadow: "none",
      borderRadius: "20px",
      padding: "30px",
      "@media (max-width:768px)":{
        padding:"25px",
        width:'70%'
      },
    },
  },
  uploadDocumentDialogTitleDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "20px",
    padding: "15px 0px"
  },
  uploadDocumentDialogTitle: {
    color: "#262626",
    fontSize: "24px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    "@media (max-width:768px)":{
      fontSize:'18px'
    }
  },
  uploadDocumentDialogCloseButton: {
    background: "transparent",
    border: "none",
    padding: 0,
    "& img": {
      width: "40px",
      height: "40px",
    },
  },
  uploadDocumentDialogTitleLabel: {
    color: "#8C8C8C",
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    marginBottom: "19px",
    "@media (max-width:768px)":{
      fontSize:'16px'
    }
  },
  uploadDocumentDialogInputDiv: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    position: "relative",
  },
  uploadDocumentDialogInput: {
    borderRadius: "12px",
    border: "2px #8C8C8C solid",
    padding: "14px 16px",
    color: "#262626",
    fontSize: "20px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    lineHeight: "24px",
    width: "100%",
    "@media (max-width:768px)":{
      fontSize:'14px'
    }
  },
  uploadDocumentDialogButton: {
    borderRadius: "12px",
    border: "2px #10BFB8 solid",
    padding: "10px",
    cursor: "pointer",
    background: "transparent",
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
  },
  uploadDocumentFileName: {
    color: "#8C8C8C",
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontStyle: "italic",
    fontWeight: 500,
    position: "absolute",
    bottom: "-24px",
    "@media (max-width:768px)":{
      fontSize:'10px'
    }
  },
  uploadDocumentUploadButtonDiv: {
    padding: "25px",
    textAlign: "center",
  },
  uploadDocumentUploadButton: {
    background: "#10BFB8",
    borderRadius: "12px",
    border: "2px #10BFB8 solid",
    padding: "12px 36px",
    color: "white",
    fontSize: "24px",
    fontFamily: "Montserrat",
    fontWeight: 600,
    lineHeight: "24px",
    "@media (max-width:768px)":{
      fontSize:'18px'
    },
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
    "&:disabled": {
      background: "white",
      border: "2px #8C8C8C solid",
      color: "#8C8C8C",
      "&:hover": {
        boxShadow: "none",
      },
    },
  },
  actionTabStep1: {
    display: "grid",
    justifyItems: "center",
    alignContent: "flex-start",
    gap: "20px",
    height: "100%",
    boxSizing: "border-box",
    padding: "20px 0px"
  },
  noActionText: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    color: "#262626",
    fontSize: "24px",
    fontFamily: "Montserrat",
    textAlign: "center",
    fontWeight: 600,
    "@media (max-width:768px)":{
      fontSize:'18px'
    },
    "& hr": {
      width: "80px",
      borderColor: "#262626",
    },
  },
  actionTabStep1NextStage: {
    color: "#262626",
    fontSize: "24px",
    fontFamily: "Montserrat",
    fontWeight: 600,
    margin: "10px auto",
    "@media (max-width:768px)":{
      fontSize:'18px'
    }
  },
  actionTabStep1YesButton: {
    background: "#10BFB8",
    borderRadius: "12px",
    border: "none",
    padding: "12px 58px",
    color: "white",
    fontSize: "24px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    lineHeight: "24px",
    "@media (max-width:768px)":{
      fontSize:'18px'
    },
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
    "&:disabled": {
      boxShadow:"none",
      background:"#cccccc",
      cursor:"none"
    }
  },
  actionTabStep2: {
    display: "grid",
    gap: "25px",
    "@media (max-width:768px)":{
      gap: "27px",
    },
  },
  actionTabStep2LOI: {
    color: "black",
    fontSize: "20px",
    fontFamily: "Montserrat",
    fontWeight: 600,
    lineHeight: "24px",
    "@media (max-width:768px)":{
      fontSize:'16px'
    }
  },
  actionTabStep2Section: {
    padding: "0 25px",
    display: "grid",
    justifyItems: "center",
    "@media (max-width:768px)":{
      padding: "0",
    },
    "& hr": {
      width: "100%",
      marginBottom: "15px !important"
    },
  },
  actionTabStep2LetterOfIntent: {
    borderRadius: "12px",
    border: "2px #8C8C8C dashed",
    minWidth: "250px",
    padding: "25px",
    marginBottom: "15px",
    display: "flex",
    alignItems: "center",
    gap: "20px",
    color: "#8C8C8C",
    fontSize: "18px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    lineHeight: "24px",
    "@media (max-width:768px)":{
      fontSize:'14px',
      minWidth:'155px'
    },
    "& img": {
      width: "40px",
    },
  },
  actionTabStep2ReviewCheckBoxDiv: {
    display: "flex",
    alignItems: "center",
    gap: "28px",
    marginBottom: "15px",
    "& *": {
      cursor: "pointer",
    },
    "& .MuiCheckbox-root": {
      padding: 0,
    },
    "& .MuiSvgIcon-root": {
      width: "43px",
      height: "43px",
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#10BFB8 !important",
    },
    "& label": {
      color: "black",
      fontSize: "18px",
      fontFamily: "Montserrat",
      fontWeight: 500,
      lineHeight: "24px",
      "@media (max-width:768px)":{
        fontSize:'14px'
      }
    },
  },
  actionTabStep2ReviewButton: {
    borderRadius: "12px",
    border: "2px #10BFB8 solid",
    background: "#10BFB8",
    padding: "12px 27px",
    color: "white",
    fontSize: "20px",
    fontFamily: "Montserrat",
    fontWeight: 600,
    lineHeight: "24px",
    marginBottom: "15px",
    "@media (max-width:768px)":{
      fontSize:'18px'
    },
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
    "&:disabled": {
      border: "2px #8C8C8C solid",
      background: "white",
      padding: "12px 55px",
      color: "#8C8C8C",
      "&:hover": {
        boxShadow: "none",
      },
    },
  },
  actionTabStepAcceptedDone: {
    display: "flex",
    alignItems: "center",
    gap: "14px",
    color: "10BFB8",
    fontSize: "20px",
    fontFamily: "Montserrat",
    fontWeight: 600,
    lineHeight: "24px",
    marginBottom: "18px",
    justifyContent: "center",
    "@media (max-width:768px)":{
      fontSize:'16px'
    },
  },
  actionTabStep2LOIText: {
    margin: "0 20px",
    textAlign: "center",
    color: "#262626",
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: 400,
    lineHeight: "24px",
    "@media (max-width:768px)":{
      fontSize:'12px'
    }
  },
  actionTabStep3: {
    display: "grid",
    gap: "32px",

    "@media (max-width:768px)":{
      display: 'block',
    },
    "& .MuiAccordion-root": {
      marginBottom: "10px"
    },
    "& .MuiAccordionDetails-root": {
      padding: "20px 35px",
      display: "grid",
      gap: "25px",
      "@media (max-width:768px)":{
        padding: "0 10px 20px 10px",
        gap:'0'
      },
      
      "& .mainDiv":{
        display: 'flex',
        flexWrap: 'wrap',
        "& .document-list-div": {
          marginBottom: "0px !important"
        }
      },
      "& .document-list-div": {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        cursor:'pointer',
        "@media (max-width:768px)":{
          gap:'12px'
        },
      },
      "& .document-text": {
        color: "black",
        fontSize: "18px",
        fontFamily: "Montserrat",
        fontWeight: 500,
        lineHeight: "24px",
        "@media (max-width:768px)":{
          fontSize: "14px",
        },
      },
    },
  },
  actionTabStep3RequestedDocumentSection: {
    display: "grid",
    gap: "15px",
    "@media (max-width:768px)":{
      gap:'0'
    },
    
  },
  actionTabSpan: {
    "@media (max-width:768px)":{

    height: '24px'
    }
  },
  actionTabStep3RequestedDocumentDiv: {
    display: "flex",
    gap: "28px",
  },
  actionTabStep3RequestedDocumentCount: {
    color: "black",
    fontSize: "20px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    lineHeight: "40px",
    width: "18px",
    "@media (max-width:768px)":{
      fontSize:'16px'
    }
  },
  actionTabStep3RequestedDocumentUpload: {
    display: "grid",
    gap: "10px",
    width: "100%",
    paddingTop: "10px",
  },
  actionTabStep3RequestedDocumentTitle: {
    color: "black",
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    "@media (max-width:768px)":{
      fontSize:'12px'
    }
  },
  actionTabStep3RequestedDocumentButtonDiv: {
    display: "flex",
  },
  actionTabStep3RequestedDocumentButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "32px",
    width: "100%",
    borderRadius: "12px",
    border: "1px #10BFB8 solid",
    padding: "8px",
    color: "#10BFB8",
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    lineHeight: "24px",
    "@media (max-width:768px)":{
      fontSize:'12px'
    },
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
    "& img": {
      width: "24px",
    },
  },
  actionTabStep3RequestedDocumentSend: {
    display: "flex",
    justifyContent: "flex-end",
    "@media (max-width:768px)":{
      justifyContent: "center",
      marginTop:'10px'
    },
  },
  actionTabStep3SendButton: {
    borderRadius: "12px",
    border: "2px #10BFB8 solid",
    background: "#10BFB8",
    padding: "12px 48px",
    color: "white",
    fontSize: "24px",
    fontFamily: "Montserrat",
    fontWeight: 600,
    lineHeight: "24px",
    "@media (max-width:768px)":{
      fontSize:'14px',
      padding: "6px 12px"
    },
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
    "&:disabled": {
      border: "2px #8C8C8C solid",
      background: "white",
      color: "#8C8C8C",
      "&:hover": {
        boxShadow: "none",
      },
    },
  },
  actionTabStep3SalePurchaseAgreementSection: {
    padding: "0px 25px",
    "@media (max-width:768px)":{
      padding: "0px",
      marginTop: "20px"
    }
  },
  actionTabStep3SalePurchaseAgreement: {
    borderRadius: "12px",
    border: "2px #8C8C8C dashed",
    padding: "20px 43px",
    display: "flex",
    alignItems: "center",
    gap: "24px",
    color: "#8C8C8C",
    fontSize: "18px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    lineHeight: "24px",
    "@media (max-width:768px)":{
      fontSize:'14px',
      gap: 0,
      padding: "4px 8px"
    },
    "& img": {
      width: "40px",
    },
  },
  actionTabStep3SalePurchaseAgreementSend: {
    textAlign: "center",
    margin: "20px 0px",
    "@media (max-width:768px)":{
      marginTop: "10px",
      marginBottom: "0px"
    },
  },
  actionTabStepText: {
    color: "#262626",
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "center",
    "@media (max-width:768px)":{
      fontSize:'12px'
    }
  },
  actionTabStep3SalePurchaseAgreementDone: {
    marginTop: "10px"
  },
  actionTabStep4Title: {
    color: "black",
    fontSize: "20px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    marginBottom: "10px",
    "@media (max-width:768px)":{
      fontSize:'16px'
    }
  },
  actionTabStep4: {
    "& .finalizeButtonBox": {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      padding: "15px 0px",
      marginBottom: "0px !important",
      "@media (max-width:768px)":{
        padding:'0px'
      }
    },
    "& .supportBox": {
      display: "flex",
      alignItems: "center",
      position: "relative",
      justifyContent: "center",
      gap: "10px",
      "& .MuiButton-root": {
        height: "42px",
        borderRadius: "8px",
        padding: "10px",
        marginLeft: "20px",
        boxSizing: "border-box",
        textTransform: "none",
        "@media (max-width:1100px)": {
          padding: "10px 5px"
        }
      },
      "& .MuiTypography-body1": {
        position: "absolute",
        left: 25,
        fontWeight: 600,
        fontFamily: "Montserrat",
        lineHeight: "24px",
        fontSize: "20px",
        color: "black",
        "@media (max-width:1200px)": {
          left: 0,
          wordSpacing: "-3px",
          wordWrap: 'break-word',
  width: '60px'
        },
        "@media (max-width:768px)": {
          wordWrap: 'break-word',
          width: '60px'
        }
      }
    },
    "& .finalizedTextBox": {
      fontSize: "20px",
      border: "2px #10BFB8 solid",
      borderRadius: "8px",
      color: "#10BFB8",
      background: "white",
      padding: "15px 20px",
      fontWeight: 600,
      fontFamily: "Montserrat",
      lineHeight: "24px"
    }
  },
  actionTabStep4Description: {
    display: "grid",
    gap: "15px",
    marginBottom: "15px"
  },
  actionTabStep4DescriptionText: {
    color: "black",
    fontSize: "20px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    "@media (max-width:768px)":{
      fontSize:'16px'
    },
    "& span": {
      fontWeight: 600,
      textDecoration: "underline",
    },
  },
  actionTabStep4AccordionDiv: {
    display: "grid",
    gap: "15px",
    marginBottom: "15px",
    overflow: "hidden",
    "& .MuiAccordionSummary-content": {
      padding:"15px 20px"
    },
    "& .MuiAccordionDetails-root": {
      padding: "0 24px 15px",
      color: "black",
      fontSize: "18px",
      fontFamily: "Montserrat",
      fontWeight: 500,
      "@media (max-width:768px)":{
        fontSize:'14px'
      }
    },
  },
  actionTabStep4question: {
    textAlign: "center",
    margin: "0px",
  },
  actionTabStep4Button: {
    display: "flex",
    justifyContent: "center",
    gap: "44px",
    marginBottom: "10px"
  },
  actionTabStep4ButtonYes: {
    borderRadius: "8px",
    border: "2px #10BFB8 solid",
    background: "white",
    padding: "12px 32px",
    color: "#10BFB8",
    fontSize: "20px",
    fontFamily: "Montserrat",
    fontWeight: 600,
    lineHeight: "24px",
    "@media (max-width:768px)":{
      fontSize:'16px',
    padding: "6px 0px"
    },
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
  },
  actionTabStep4ButtonNo: {
    borderRadius: "8px",
    border: "2px #FF7575 solid",
    background: "white",
    padding: "12px 35px",
    color: "#FF7575",
    fontSize: "20px",
    fontFamily: "Montserrat",
    fontWeight: 600,
    lineHeight: "24px",
    "@media (max-width:768px)":{
      fontSize:'16px',
    marginTop: "12px", 
    padding: "6px 0px"

    },
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
  },
  actionTabStep5: {
    display: "grid",
    gap: "25px"
  },
  actionTabStep5Title: {
    textAlign: "center",
    margin: "0px 98px",
    color: "black",
    fontSize: "24px",
    fontFamily: "Montserrat",
    fontWeight: 600,
    "@media (max-width:768px)":{
      fontSize:'18px',
      margin: "0px 0px",

    }
  },
  actionTabStep5Content: {
    display: "grid",
    gap: "15px",
    margin: "0 14px",
    "@media (max-width:768px)":{
      margin: "0px 0px"
    }
  },
  actionTabStep5SubTitle: {
    color: "black",
    fontSize: "24px",
    fontFamily: "Montserrat",
    fontWeight: 600,
    "@media (max-width:768px)":{
      fontSize:'18px'
    }
  },
  actionTabStep5DetailsSection: {
    display: "grid",
    gap: "10px"
  },
  actionTabStep5Details: {
    display: "grid",
    justifyItems: "start",
    gap: "10px",
    borderRadius: "12px",
    border: "1px #8C8C8C solid",
    padding: "16px 24px",
  },
  actionTabStep5Question: {
    color: "black",
    fontSize: "18px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    "@media (max-width:768px)":{
      fontSize:'14px',
      textAlign: 'center'
    }
  },
  actionTabStep5Button: {
    background: "transparent",
    border: "none",
    padding: "0",
    color: "black",
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: 400,
    textDecoration: "underline",
    cursor: "pointer",
    "@media (max-width:768px)":{
      fontSize:'12px'
    }
  },
  textFields :{
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    '& .MuiInputBase-input': {
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: 4,
      padding: '10px 14px',
      '&:focus': {
        borderRadius: 4,
      },
    },
  }
};

export default withStyles(styles)(AcquisitionProcessStepAction);
// Customizable Area End