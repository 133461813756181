// Customizable Area Start
import React from "react";
import Box from "@material-ui/core/Box";
import { InputLabel, styled, createStyles, withStyles, OutlinedInput } from "@material-ui/core";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { arrowDownIcon, UKFlag, arabicLang , hamburgerIcon, alertIcon, arrowIcon, crossIcon, userDefaultImage } from './assets';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Drawer from '@material-ui/core/Drawer';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { setStorageData, getStorageData, removeStorageData } from "framework/src/Utilities";
const images = require("../../blocks/email-account-registration/src/assets");
import { arabicLogo, arabicLogoBlacl } from "./assets";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { Message } from "framework/src/Message";
import { runEngine } from "framework/src/RunEngine";
import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
interface MyProps extends RouteComponentProps {
  classes: Record<string, string>;
  profileImageUrl?:string | null;
};

type MyState = {
  language: string;
  ShowDialog: boolean;
  ShowLogoutBlock: boolean;
  ShowSideBar: boolean;
  isBuyer: boolean;
  isSeller: boolean;
  isUnreadNotifications: boolean;
  avtarUrl: string | undefined;
  disabled: boolean;
};



interface SS {
  
}
class HeaderBlock extends BlockComponent<
MyProps,
MyState,
SS
>{
  getDetailsRequestAPI = "";
  userProfileCallId = "";

  noHeaderPathNames = ['/EmailAccountRegBlock', '/CustomizableUserSubscriptions', '/EmailAccountLoginBlock', '/ForgotPassword', '/ForgotEmailId']
  constructor(props: any) {
    super(props);
    this.receive = this.receive.bind(this);
        
    this.subScribedMessages = [
    getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      language: 'en',
      ShowDialog: false,
      ShowLogoutBlock: false,
      ShowSideBar: false,
      isBuyer: false,
      isSeller: false,
      isUnreadNotifications: false,
      avtarUrl: "",
      disabled: false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    super.componentDidMount();
    this.getLanguage();
    this.getUnreadNotifications();
    this.getBuyerProfile();
  }

  getUnreadNotifications = async () => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    
    let endPoint = `/bx_block_notifications/is_unread`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDetailsRequestAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const responseAPIId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    // get response
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (this.getDetailsRequestAPI === responseAPIId) {
      this.setState({isUnreadNotifications: responseJson.unread_notifications});
    }
    
    if (this.userProfileCallId === responseAPIId) {
      this.setState({avtarUrl: responseJson?.data?.attributes?.image?.url});
    }
  }
}


  getLanguage = async () => {
    let lang = await getStorageData('language')
    let typeOfUser = await getStorageData('typeOfUser');
    if (typeOfUser === "buyer") { this.setState({ isBuyer: true }); }
    if (typeOfUser === "seller") { this.setState({ isSeller: true }); }
    if (lang) {
      return this.setState({ language: lang })
    }
    this.setState({ language: 'en' })
  }


  handleActiveClass = (activeRoute: string) => {
    if (window.location.pathname.toLowerCase() === activeRoute.toLowerCase()) {
      return "party"
    }
    else {
      return "my-menu-link"
    }
  }
  handleDialogBox = () => {
    this.setState({ ShowDialog: true })
  }
  handleClose = () => {
    this.setState({ ShowDialog: false })
  }
  handleLogout = () => {
    this.setState({
      ShowDialog: false,
      ShowLogoutBlock: true
    });
  };

  setUserProfileLocalStorage = async () => {
    const userData = await getStorageData("userProfile")
    if(userData){
        this.setState({avtarUrl:JSON.parse(userData)?.image?.url})
    }
  }

  handleLogoutClose = () => {
    this.setState({ ShowLogoutBlock: false })
  }

  getBuyerProfile = async () =>  {
    const header = {
      token: await getStorageData('token'),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/start_ups/buyer_profile"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  logoutLogic = () => {
    this.setState({ ShowLogoutBlock: false })
    removeStorageData('token');
    removeStorageData('acquisition');
    removeStorageData('typeOfUser');
    removeStorageData('isUserSubscribed');
    removeStorageData('given_feedback');
    window.location.href = "/";
  }

  handleSideBarToggle = () => {
    this.setState({ ShowSideBar: true })
  }
  handleSidebarClose = () => {
    this.setState({ ShowSideBar: false })
  }
  handleDialogBlock = () => {
    return (
      <DialogContainer open={this.state.ShowDialog} onClose={() => this.handleClose()}  style={{borderRadius: this.state.language === 'en' ? '12px 0px 12px 12px' : '0px 12px 12px 12px'}}>
        <ul className="dialog_block" onClick={() => this.handleClose()}>
          <li className="dialog_list">
            <Link to="/Settings2/notifications" className="dialog_item" style={{display: 'flex'}}>
              {this.state.language === 'en' ? 'Notifications ' : 'إشعارات'}
              {this.state.isUnreadNotifications && <div className="green-dot"></div>}
              </Link>
          </li>
          <li className="dialog_list">
            <Link to="/Settings2/settings" className="dialog_item">{this.state.language === 'en' ? 'Settings' : 'إعدادات'}</Link>
          </li>
          <li className="dialog_list">
            <a href={this.state.isSeller ? `${window.location.origin}/SellerProfile` : `${window.location.origin}/EditBuyerProfile`} className="dialog_item">{this.state.language === 'en' ? 'User profile' : "الملف الشخصي"}</a>
          </li>
          <li className="dialog_list">
            <Link to="/Settings2/resources" className="dialog_item">{this.state.language === 'en' ? 'Resources' : 'موارد'}</Link>
          </li>
          <li className="dialog_list">
            <Link to="/Settings2/notificationSettings" className="dialog_item">{this.state.language === 'en' ? 'Notification Settings' : "إعدادات الإشعارات"}</Link>
          </li>
          <li className="dialog_list logout_note" onClick={this.handleLogout}>
            <img src={arrowIcon} alt="logout_icon" />
            <span className="dialog_item" style={this.state.language === 'en' ? {paddingLeft: "21px"} : {paddingRight: "21px"} }>{this.state.language === 'en' ? 'Logout' : 'تسجيل خروج'}</span>
          </li>
        </ul>
      </DialogContainer>

    )
  }

  handleLogoutBlock = () => {
    return (
      <DialogLogoutContainer
        open={this.state.ShowLogoutBlock}
        onClose={this.handleLogoutClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <img src={alertIcon} alt="alert_image" className="alert_img" />
          <span>{this.state.language === 'en' ? 'Are you sure?' : 'هل أنت متأكد؟'}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>
            {this.state.language === 'en' ? 'Are you sure you want to log out?' : 'هل أنت متأكد أنك تريد تسجيل الخروج؟'}
            </div>
            {this.state.language === 'en' ? 'To access your account, you will have to log in again' : 'للوصول إلى حسابك، سيتعين عليك تسجيل الدخول مرة أخرى'}
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.logoutLogic} className="logout_btn btn">
            {this.state.language === "ar" ? "نعم، قم بتسجيل الخروج" : "Yes, Log out"}
          </Button>
          <Button onClick={this.handleLogoutClose} className="go_back_btn btn" autoFocus>
            {this.state.language === "ar" ? "لا، ارجع" : "No, Go back"}
          </Button>
        </DialogActions>
      </DialogLogoutContainer>

    )
  }
  RenderHeaderBlock(propsData: React.ReactNode
  ) {
    return (<>{this.state.ShowSideBar ? <DrawerContainer
      open={this.state.ShowSideBar}
      onClose={this.handleSidebarClose}
      anchor={"top"}
    >{propsData}</DrawerContainer> : propsData}</>)
  }

  handleLangChange = (e: any) => {
    this.setState({ language: e.target.value as string })
    setStorageData('language', e.target.value)
    window.location.reload();
  }

  render() {
    const { language } = this.state;
    const { pathname } = this.props.location;
    const { classes } = this.props;
    return (
      this.RenderHeaderBlock(
        <HeaderContainer className={`${this.state.ShowSideBar ? "test" : "header_main"}`}>
          <div className="header-1">
            <Box className='logo-container'>
              <Link to="/" className="my-menu-link"><img src={ this.state.language ==="en" ? images.namiLogo : arabicLogo}  className="logo" /></Link>
              <div className="cross_img" >
                <img src={crossIcon} alt="cross_img" onClick={this.handleSidebarClose} />
              </div>
            </Box>
            {!this.noHeaderPathNames.includes(pathname) &&
              <>
                {
                  (
                    this.state.isSeller
                  ) &&
                  (
                    <>
                      <div className='pipe' />
                      <Link className="party bold_line" to="#">
                        {this.state.language === 'ar' ? 'بائع' : 'Seller'}
                      </Link>
                    </>
                  )
                }

                {this.state.isBuyer && (
                  <>
                    <div className='pipe' />
                    <Link className="party bold_line" to="#">
                      {this.state.language === 'ar' ? 'مشتر' : 'Buyer'}
                    </Link>
                  </>
                )
                }

              </>
            }
          </div>
          {!this.noHeaderPathNames.includes(pathname) &&
              <>
          {this.state.isBuyer
            && (
              <ul className="my-menu-list">
                <li className="my-menu-list-item">
                  <Link to="/Catalogue" className={`${this.handleActiveClass("/Catalogue")}`}>{this.state.language === 'ar' ? 'القوائم' : 'Listings'}</Link>
                </li>
                <li className="my-menu-list-item">
                  <Link to="/Deals" className={`${this.handleActiveClass("/Deals")}`}>{this.state.language === 'ar' ? 'صفقات' : 'Deals'}</Link>
                </li>
                <li className="my-menu-list-item">
                  <Link to="/BuyerDashboard" className={`${this.handleActiveClass("/BuyerDashboard")}`}>{this.state.language === 'ar' ? 'لوحة التحكم' : 'Dashboard'}</Link>
                </li>
              </ul>
            )
          }

          {this.state.isSeller &&
            (<ul className="my-menu-list">
              <li className="my-menu-list-item">
                <Link to="/StartUpDetails" className={`${["/StartUpDetails", "/CompanyProfileEdit"].some((str: string) => (window.location.pathname).toLowerCase().includes(str.toLowerCase())) ? 'party' : 'my-menu-link'}`}>
                {this.state.language === 'ar' ? 'قائمتي' : 'My Listing'}</Link>
              </li>
              <li className="my-menu-list-item">
                <Link to="/MyAcquisition" className={`${this.handleActiveClass("/MyAcquisition")}`}>{this.state.language === 'ar' ? 'الاستحواذ الخاص بي' : 'My Acquisition'}</Link>
              </li>
            </ul>
            )}
            </>
  }

          <FormControl variant="outlined" className={`${this.state.language === "ar" ? "marginRightAuto" : "marginLeftAuto"}`} style={{display: `${(pathname === '/CustomizableUserSubscriptions') && 'block'}`}}>
            <InputLabel id="select-language-label"></InputLabel>
            <Select
              disabled={this.state.disabled}
              labelId="select-language-label"
              id="select-language"
              value={language}
              onChange={this.handleLangChange}
              className="mes"
              style={{ padding: this.state.language === 'en' ? '8px 20px 8px 4px' : '8px 0px 8px 20px'}}
              IconComponent={
                () => (this.state.disabled ? <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.9926 0.90782L11.9969 0.912045L11.9968 0.912094C12.1242 1.04576 12.1953 1.22335 12.1953 1.40803C12.1953 1.59269 12.1242 1.77026 11.9968 1.90392L11.9926 0.90782ZM11.9926 0.90782L11.989 0.904195L11.9926 0.90782ZM7.01872 7.12861L11.9968 1.90397L5.97798 7.12861C6.0451 7.19906 6.12584 7.25515 6.21528 7.29348C6.30473 7.3318 6.40103 7.35156 6.49835 7.35156C6.59566 7.35156 6.69196 7.3318 6.78141 7.29348C6.87086 7.25515 6.95159 7.19906 7.01872 7.12861C7.01872 7.12861 7.01872 7.12861 7.01872 7.12861ZM0.999894 1.90397L5.97797 7.12861L11.6677 0.979717C11.6139 0.956641 11.5561 0.944742 11.4976 0.944742C11.4391 0.944742 11.3812 0.956641 11.3274 0.979717C11.2737 1.00279 11.2252 1.03656 11.1849 1.07897L11.0039 0.90652L11.0038 0.906614C11.0038 0.90667 11.0037 0.906726 11.0037 0.906782L6.49745 5.6365L1.99304 0.906783C1.92939 0.839787 1.85278 0.786444 1.76788 0.749993C1.68297 0.71354 1.59153 0.694742 1.49913 0.694742C1.40672 0.694742 1.31529 0.71354 1.23038 0.749992C1.14656 0.785975 1.07084 0.838419 1.00768 0.904204L1.00408 0.90781L1.00403 0.907759L0.999895 0.912094C0.87247 1.04576 0.801382 1.22335 0.801382 1.40803C0.801382 1.59269 0.872453 1.77026 0.999851 1.90392C0.999866 1.90394 0.99988 1.90395 0.999894 1.90397Z" fill="#8C8C8C" stroke="#8C8C8C" stroke-width="0.5"/>
                </svg> : <img
                  className="arrow-down-icon"
                  style={this.state.language === 'ar' ? {left: '24px'} : {right: '24px'}}
                  src={arrowDownIcon}
                />)
              }
            >
              <MenuItem value='en'>
                <img src={UKFlag} />
                <span className={`${classes.languageText}`}>{'EN'}</span>
              </MenuItem>
              <MenuItem value='ar'>
                <img src={arabicLang} />
                <span className={classes.languageText}>{ 'ع'}</span>
              </MenuItem>
            </Select>
          </FormControl>
          {!this.noHeaderPathNames.includes(pathname) &&
          <>
          <span className="hamburger-menu-toggler" onClick={this.handleSideBarToggle}>
            <img src={hamburgerIcon} alt="Hamburger toggler" />
          </span>
          {(this.state.isBuyer || this.state.isSeller) &&
            <div style={{ position: "relative" }} className="profile_outer">
              <div className="img_profile" style={this.state.language === "en" ? {} : {paddingRight: "30px"}} onClick={this.handleDialogBox}>
                <img className={this.state.isUnreadNotifications ? 'img_noti':'img_noti_dis'} src={(this.props?.profileImageUrl || this.state.avtarUrl) || userDefaultImage} alt="" />
                {this.state.isUnreadNotifications &&<div className="green-dot" style={this.state.language == 'ar' ? {right: "0x"} : {right: "-12px", top: "-3px"}}></div>}
              </div>
              {this.state.ShowDialog ? this.handleDialogBlock() : ""}
              {this.state.ShowLogoutBlock ? this.handleLogoutBlock() : ""}
            </div>
          }</>
        }

        </HeaderContainer>
      )
    );


  }
}

const HeaderContainer = styled('header')(({ theme }) => ({
  padding: "7px 4% 7px 10px",
  display: "flex",
  alignItems: "center",
  borderBottom: "1px solid black",
  [theme.breakpoints.down('sm')]: {
    padding: "14px 20px 14px 20px",
  },

  '& .marginRightAuto': {
    marginRight: "auto"
  },
  '& .marginLeftAuto': {
    marginLeft: "auto"
  },
  '& *': {
    boxSizing: 'border-box',
  },

  '& .header-1': {
    display: "flex",
    alignItems: "center",
  },
  '& .logo': {
    width: "100px",
    height: "38px",
  },
  '& .pipe': {
    width: 0,
    height: "40px",
    border: "2px solid #000",
    borderRadius: "1000px",
    margin: "0 24px 0 28px",
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  '& .party': {
    textDecoration: "none",
    color: "#5412FC",
    fontFamily: "Montserrat , Noto Kufi Arabic",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "24px",
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  '& .my-menu-list': {
    listStyle: 'none',
    display: 'flex',
    paddingLeft: '0',
    marginTop: '0',
    marginBottom: '0',
    marginLeft: '120px',
    gap: '100px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    "@media (max-width:1100px) and (min-width:800px)": {
      marginLeft: '55px',
      gap: '60px',
    }

  },
  '& .my-menu-link': {
    textDecoration: 'none',
    color: "#5412FC",
    fontFamily: "Montserrat , Noto Kufi Arabic",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  '& .MuiFormControl-root': {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: "2px solid #10BFB8",
    '&.PrivateNotchedOutline-root-4': {
      border: "2px solid #10BFB8",
    }
  },
  '& .MuiOutlinedInput-input': {
    padding: 0,
    paddingLeft: '20px',
    paddingRight: '20px',
    display: "flex",
    color: "#10BFB8",
    alignItems: "center",
    border: "none"
  },
  '& .mes': {order: -1,  marginRight: theme.spacing(1), cursor: 'pointer'},
  '& .disabled': {color: 'rgba(0, 0, 0, 0.38)'},
  '& .arrow-down-icon': {
    width: '20px',
    height: '20px',
    position: 'absolute',
    pointerEvents: 'none'
  },
  '& .hamburger-menu-toggler': {
    display: 'none',
    '& img': {
      width: '29px',
      height: '18px'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginLeft: 'auto',
      paddingRight: '9px' // 30px - 21px (header padding)
    }
  },
  '& .cross_img': {
    display: "none"
  },
  '& .green-dot':{
    width: "12px",
    height: "12px",
    backgroundColor: "#FF7575",
    borderRadius: "50%",
    position: "absolute",
    top: "0px",
    border: '1px solid white'
  },
  '& .img_profile': {
    paddingLeft: '60px',
    cursor: "pointer",
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    position: "relative",
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  '& .img_noti': {
    border: '2px solid #FF7575',
    borderRadius: '50%',
    padding: '1px',
    width:"48px",
    height:"48px"
  },
  '& .img_noti_dis': {
    borderRadius: '50%',
    width:"48px",
    height:"48px"
  },
}));

const DialogContainer = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paperScrollPaper': {
    position: "absolute",
    top: "45px",
  },
  '& .MuiDialog-scrollPaper': {
    justifyContent: "flex-end",

  },
  '& .MuiPaper-rounded': {
  },
  '& .logout_note': {
    paddingTop: "41px",
    marginBottom: "0px",
    [theme.breakpoints.down('sm')]: {
      paddingTop: "21px",
    }

  },
  '& .dialog_block': {
    padding: "32px 78px 0px 32px",
    margin: "0px",
    [theme.breakpoints.down('sm')]: {
      padding: "22px 32px 0px 22px",
    }
  },
  '& .dialog_list': {
    listStyle: "none",
    marginBottom: "32px",
    [theme.breakpoints.down('sm')]: {
      marginBottom: "20px",

    }
  },
  '& .green-dot': {
    width: "12px",
    height: "12px",
    backgroundColor: "#FF7575",
    borderRadius: "50%",
    marginLeft: '2px',
    border: "2px solid white",
    position: "absolute",
    right: "24px"
    
  },
  '& .dialog_item': {
    color: "#000",
    fontFamily: "Montserrat , Noto Kufi Arabic",
    fontSize: "20px",
    fontWeight: "500",
    textDecoration: "none",
    [theme.breakpoints.down('sm')]: {
      fontSize: "16px"
    }
  }
}))

const DialogLogoutContainer = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paperWidthSm': {
    maxWidth: "634px",
    width: "100%"
  },
  '& .MuiPaper-rounded': {
    borderRadius: "12px"
  },
  '& .MuiDialogTitle-root': {
    padding: "0px"
  },
  '& .MuiDialogContent-root': {
    maxWidth: "376px",
    width: "100%",
    margin: "auto",
    padding: "0px"
  },
  '& .MuiTypography-h6': {
    textAlign: "center",
    paddingTop: "49px",
    paddingBottom: "39px",
    color: "#262626",
    fontFamily: "Montserrat , Noto Kufi Arabic",
    fontSize: "28px",
    fontWeight: 700,
    display: "flex",
    gap: "40px",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      paddingTop: "20px",
      paddingBottom: "20px",
      fontSize: "22px"
    }

  },
  '& .MuiButton-root': {
    transition: "unset"
  },

  '& .MuiDialogContentText-root': {
    color: "#000",
    textAlign: "center",
    fontFamily: "Montserrat , Noto Kufi Arabic",
    fontSize: "20px",
    fontWeight: 500,
    marginBottom: "0px",
    lineHeight: "29px",
    letterSpacing: "0px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "14px !important",
      lineHeight: "24px",
    }

  },
  '& .MuiDialogActions-root': {
    justifyContent: "space-between",
    padding: "42px 40px 28px 40px",
    [theme.breakpoints.down('sm')]: {
      padding: "25px 30px 20px 25px",
    }

  },
  '& .logout_btn': {
    
    border: "2px solid #FF7575",
    color: "#FF7575",
  }
  , '& .btn': {
    borderRadius: "12px",
    background: "#FFF",
    textAlign: "center",
    fontFamily: "Montserrat , Noto Kufi Arabic",
    fontSize: "16px",
    fontWeight: 600,
    padding: "12px 34px",
    textTransform: "none",
    [theme.breakpoints.down('sm')]: {
      padding: "6px 12px",
      fontSize: "12px",
    }
  },
  '& .go_back_btn': {
    border: "2px solid #10BFB8",
    color: "#10BFB8",
  },
  '& .alert_img': {
    [theme.breakpoints.down('sm')]: {
      width: "30px",
      height: "30px"
    }

  }
}))
const DrawerContainer = styled(Drawer)(({ theme }) => ({
  '& .test': {
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    width: "100%",
    borderBottom: "1px solid #000",

  },
  '& .MuiBox-root': {
    padding: "0px 28px",
    width: "100%",
    borderBottom: "1px solid #000",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  '& .header-1': {
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    width: "100%",
  },

  '& .hamburger-menu-toggler': {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
      marginLeft: 'auto',
    }
  },
  '& .party': {
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  '& .my-menu-list': {
    margin: "0px",
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },

  '& .my-menu-link': {
    padding: "15px 0px"
  },
  '& .MuiFormControl-root': {
    width: "100%",
    padding: "15px 28px",
    borderBottom: "1px solid #000",
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  '& .bold_line': {
    fontSize: "20px",
    padding: "15px 28px",
    width: "100%",
    borderBottom: "1px solid #000",
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    maxWidth: 'min-content'
  },
  '& .MuiOutlinedInput-input': {
    padding: '8px 76px 8px 24px',
  },
  '& .my-menu-list-item': {
    padding: "15px 28px",
    borderBottom: "1px solid #000"
  },
  '& .img_profile': {
    padding: "0px",
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  '& .MuiInputBase-root': {
    margin: "0px"
  },
  '& .profile_outer': {
    padding: "15px 28px",
    borderBottom: "1px solid #000",
    width: "100%"


  },
  '& .cross_img': {
    display: "block"
  }

}))

const webStyles = createStyles({
  languageText: {
    display: "inline-block",
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: "24px"
  }
})

export default withRouter(withStyles(webStyles)(HeaderBlock));
// Customizable Area End
















