import React from "react";
// Customizable Area Start
import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  Typography,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";
import {
  mainLandingBg,
  mainLandingBgMobile,
  unlocklogo,
  targetLogo,
  arrowListIcon,
  healthCareLogo,
  healthLogo,
  businessLogo,
  bookMark,
  flag,
  share,
  info,
  communityLogo,
  foldedArrow,
  namiLogo,
  revenueLogo,
  profitLogo,
  mapLogo,
  employeesLogo,
  calendarLogo,
  valuationLogo,
  mobileIcon,
  arrowBg,
  saasLogo,
  phoneLogo,
  eCommerceLogo,
  energyLogo,
  startupLogo,
  profile1,
  profile2,
  profile1Gray,
  profile2Gray,
  whatPeopleSay,
  arrowDownIcon,
  UKFlag,
  ArFlag,
  sellerLogo, platformLogo, buyerLogo,
  arabicLogo
} from "./assets";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {
  createStyles,
  Theme,
  withStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FooterBlock from "../../../components/src/FooterBlock.web";
import { isDirRtl, conditionSort } from "../../../components/src/Helper";

let modifiedTheme: Theme = createTheme();
modifiedTheme = createTheme({
  overrides: {
    MuiMenuItem: {
      root: {
        color: "#10BFB8",
        fontSize: 20,
        fontWeight: 700,
        lineHeight: "24px",
        fontFamily: "Montserrat, sans-serif !important",
        "& .languageText": {
          display: "inline-block",
          paddingLeft: conditionSort(isDirRtl, 0, 20),
          paddingRight: conditionSort(isDirRtl, 20, 0)
        },
      },
    },
  },
});

// Customizable Area End

import MainLandingPageController, {
  Props,
} from "./MainLandingPageController.web";
import { configJSON } from "./LandingPageController";

export default class MainLandingPage extends MainLandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getHrContainerUi = () => {
    const { pageTxtData } = this.state
    return (
      <>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={this.props.classes.hrContainer}
        >
          <hr className={this.props.classes.horizontal} />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={this.props.classes.hrBelowContainer}
        >
          <Box className={this.props.classes.iconsLeft}>
            <img src={bookMark} />
            <img src={flag} />
            <img src={share} />
          </Box>
          <Box className={this.props.classes.originalListing}>
            <img src={info} />
            <Typography>{this.getSpecifiedLangString(this.state.language,pageTxtData.arabicLine,configJSON.arabicLineAr)}</Typography>
          </Box>
        </Grid>
      </>
    );
  };

  heroCard(heroCardNum = "heroCard1", isCard = true) {
    const { classes } = this.props;
    const { pageTxtData } = this.state;
    const cards: any = {
      heroCard1: {
        industryTitle: this.getSpecifiedLangString(this.state.language,configJSON.SaasTxt,configJSON.SaasTxtAr),
        industryLogo: saasLogo,
        companyType: this.getSpecifiedLangString(this.state.language,pageTxtData.startUpTxt,configJSON.startUpTxtAr),
        companyTypeLogo: startupLogo,
        price: 1,
        active: "active",
      },
      heroCard2: {
        industryTitle: this.getSpecifiedLangString(this.state.language,pageTxtData.healthCareTxt,configJSON.healthCareTxtAr),
        industryLogo: healthLogo,
        companyType: this.getSpecifiedLangString(this.state.language,pageTxtData.businessTxt,configJSON.businessTxtAr),
        companyTypeLogo: businessLogo,
        price: 2,
        active: "",
      },
      heroCard3: {
        industryTitle: this.getSpecifiedLangString(this.state.language,pageTxtData.mobileAppTxt,configJSON.mobileAppTxtAr),
        industryLogo: phoneLogo,
        companyType: this.getSpecifiedLangString(this.state.language,pageTxtData.startUpTxt,configJSON.startUpTxtAr),
        companyTypeLogo: startupLogo,
        price: 12,
        active: "",
      },
      heroCard4: {
        industryTitle: this.getSpecifiedLangString(this.state.language,pageTxtData.eCommerceTxt,configJSON.eCommerceTxtAr),
        industryLogo: eCommerceLogo,
        companyType: this.getSpecifiedLangString(this.state.language,pageTxtData.startUpTxt,configJSON.startUpTxtAr),
        companyTypeLogo: startupLogo,
        price: 2,
        active: "",
      },
      heroCard5: {
        industryTitle: this.getSpecifiedLangString(this.state.language,pageTxtData.energyTxt,configJSON.energyTxtAr),
        industryLogo: energyLogo,
        companyType: this.getSpecifiedLangString(this.state.language,pageTxtData.businessTxt,configJSON.businessTxtAr),
        companyTypeLogo: businessLogo,
        price: 3,
        active: "",
      },
      "hero-card-bg-top": {
        industryTitle: "",
        industryLogo: "",
        companyType: "",
        companyTypeLogo: "",
        price: "",
        active: "",
      },
      "hero-card-bg-bottom": {
        industryTitle: "",
        industryLogo: "",
        companyType: "",
        companyTypeLogo: "",
        price: "",
        active: "",
      },
    };

    return (
      <div
        className={`${classes.heroCard} ${isCard ? classes[heroCardNum] : heroCardNum
          } ${cards[heroCardNum].active}`}
      >
        <div className="industry-header">
          <div className={`industry-header-left ${cards[heroCardNum].active}`}>
         
            {isCard ? (
              <div className={`industry-logo ${cards[heroCardNum].active}`}>
                <img
                  className={`${classes.logoElement}`}
                  src={cards[heroCardNum].industryLogo}
                  alt="industry logo"
                />
              </div>
            ) : (
              "&nbsp;"
            )}
            <div className="industry-title">
              {isCard && cards[heroCardNum].industryTitle}
            </div>
          </div>
          <div className={`industry-header-right ${cards[heroCardNum].active}`}>
            {isCard ? (
              <div className={`company-type-logo ${cards[heroCardNum].active}`}>
                <img
                  src={cards[heroCardNum].companyTypeLogo}
                  alt="company type logo"
                  className={`${classes.companyTypeElement}`}
                />
              </div>
            ) : (
              "&nbsp;"
            )}
            <div className="company-type-wrapper">
              <div className="company-type">
                {isCard && cards[heroCardNum].companyType}
              </div>
            </div>
          </div>
        </div>
        <div className="industry-content">
          <div
            className={`skeleton ${classes.skeleton} ${classes.skeletonWFull} ${classes.skeletonH1} ${classes.skeletonColor1} ${cards[heroCardNum].active}`}
          />
          <div
            className={`skeleton ${classes.skeleton} ${classes.skeletonWFull} ${classes.skeletonH1} ${classes.skeletonColor2}`}
          />
          <div
            className={`skeleton ${classes.skeleton} ${classes.skeletonW2_3} ${classes.skeletonH1} ${classes.skeletonColor2}`}
          />
        </div>
        <div className="industry-footer">
          <div className="industry-footer-skeleton-group">
            <div
              className={`skeleton ${classes.skeleton} ${classes.skeletonW80} ${classes.skeletonH2} ${classes.skeletonColor1} ${cards[heroCardNum].active}`}
            />
            <div
              className={`skeleton ${classes.skeleton} ${classes.skeletonWFull} ${classes.skeletonH1} ${classes.skeletonColor2}`}
            />
          </div>
          <div className="industry-footer-skeleton-group">
            <div
              className={`skeleton ${classes.skeleton} ${classes.skeletonW80} ${classes.skeletonH2} ${classes.skeletonColor1} ${cards[heroCardNum].active}`}
            />
            <div
              className={`skeleton ${classes.skeleton} ${classes.skeletonWFull} ${classes.skeletonH1} ${classes.skeletonColor2}`}
            />
          </div>
          <div className={`price-container ${cards[heroCardNum].active}`}>
            <div className="price-text">{isCard && this.getSpecifiedLangString(this.state.language,pageTxtData.askingPrTxt,configJSON.askingPrTxtAr)}</div>
            <div className="price">
              {isCard && `${pageTxtData.sarTxt} ${cards[heroCardNum].price}${pageTxtData.mnTxt}`}
            </div>
          </div>
        </div>
      </div>
    );
  }

  whatPeopleSayAboutUsBlock(cardNum: number, feedback: {account: {first_name: string, last_name: string, image: {image: string}}, user_feedback: string}) {
    const { classes } = this.props;
    const { hoveredWhatPeopleSayCard, pageTxtData } = this.state;
    const showAlternativeText =
      window.innerWidth > 960 && !!hoveredWhatPeopleSayCard;
    return (
      <div
        data-test={`whatPeopleSayBlock${cardNum}`}
        onMouseEnter={() => this.handleHoverOnWhatPeopleSayCard(cardNum+1)}
        onMouseLeave={this.handleLeaveOnWhatPeopleSayCard}
        className={classes.whatPeopleSayBlock}
      >
        <img
          src={feedback.account.image?.image || whatPeopleSay}
          alt="what people say photo"
          className={classes.whatPeopleSayAvatar}
        />
        {hoveredWhatPeopleSayCard === cardNum+1 && showAlternativeText ? (
          <div className={`${classes.whatPeopleSayTextWrapper}`}>
            <div className={`${classes.whatPeopleSayName} hovered`}>
              {feedback.account.first_name} {feedback.account.last_name}
            </div>
            <div className={classes.oneYearWithUs}>
              {this.getSpecifiedLangString(this.state.language,pageTxtData.oneYearWithUs,configJSON.oneYearWithUsAr)}
            </div>
            <div className={`${classes.whatPeopleSayText} hovered`}>
              {feedback.user_feedback}
            </div>
          </div>
        ) : (
          <div className={classes.whatPeopleSayTextWrapper}>
            <div className={classes.whatPeopleSayText}>
              "{feedback.user_feedback}"
            </div>
            <div className={classes.whatPeopleSayName}>
              -{feedback.account.first_name} {feedback.account.last_name}
            </div>
          </div>
        )}
      </div>
    );
  }
  renderIcon = () => <img className="arrow-down-icon" src={arrowDownIcon} />

  CardDetails = [
    {
      img: sellerLogo
    },
    {
      img: platformLogo
    },
    {
      img: buyerLogo,
      title: this.state.pageTxtData.buyerTxt,
      content: "Discover your ideal opportunity and make an offer within minutes."
    }
  ];
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { language, pageTxtData } = this.state;
    const { classes } = this.props;
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      fade: true,
      autoplaySpeed: 6000,
      pauseOnHover: true
    };

    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={modifiedTheme}>
        <div className={classes.mainDiv}>
          <main>
            <div className={classes.firstContainer}>
              <nav className={classes.navbar}>
                <img className='banner' src={this.state.language === 'en' ? namiLogo : arabicLogo} />
                <div className="desktop">
                  <ul className="linkList">
                    <li className="linkItem">
                      <Link className="link" to="/EmailAccountRegBlock">
                        {this.getSpecifiedLangString(this.state.language, pageTxtData.buyerTxt, "مشتري")}
                      </Link>
                    </li>
                    <li className="linkItem">
                      <Link className="link" to="/EmailAccountRegBlock">
                        {this.getSpecifiedLangString(this.state.language, pageTxtData.sellerTxt, "بائع")}
                      </Link>
                    </li>
                  </ul>
                  <Box className='buttonContainer'>
                  <FormControl variant="outlined" className="language-menu">
                    <InputLabel id="select-language-label" />

                    <Select
                      disabled={false} 
                      labelId="select-language-label"
                      data-testid="languageSelector"
                      id="select-language"
                      value={language}
                      onChange={this.handleLangChange}
                      IconComponent={this.renderIcon.bind(this)}
                    >
                      <MenuItem value="en">
                        <img src={UKFlag} />
                        <span className="languageText">{'EN'}</span>
                      </MenuItem>
                      <MenuItem value="ar">
                        <img src={ArFlag} />
                        <span className="languageText">{'ع'}</span>
                      </MenuItem>
                    </Select>
                  </FormControl>

                  <Link className="signInBtn" to="/EmailAccountLoginBlock">
                    {this.getSpecifiedLangString(this.state.language, pageTxtData.signInTxt, "تسجيل الدخول")}
                  </Link>
                  </Box>
                </div>

                <button
                  className="mobile"
                  onClick={this.navigateToRegistration}
                  data-testId="waitListNav"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27"
                    height="19"
                    viewBox="0 0 27 19"
                    fill="none"
                  >
                    <path
                      d="M9 19H27V15.8333H9V19ZM0 0V3.16667H27V0H0ZM9 11.0833H27V7.91667H9V11.0833Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </nav>
              <div className={classes.ctaSection}>
                <div className={classes.heroSectionContainer}>
                  <div className={classes.contentField}>
                    <Typography data-testid='heading' className={classes.title}>
                      {this.getSpecifiedLangString(this.state.language,pageTxtData.sectionOneHeading,configJSON.sectionOneHeadingAr)}
                    </Typography>
                    <Typography className={classes.subTitle}>
                      "{this.getSpecifiedLangString(this.state.language,pageTxtData.sectionOneContent,configJSON.sectionOneContentAr)}"
                    </Typography>
                    <div className={classes.buttonsWrapper}>
                      <Button
                        className={classes.waitListBtn}
                        onClick={this.navigateToRegistration}
                        data-testId="waitListNavSectionOne"
                      >
                        <Box>
                        <p>{this.getSpecifiedLangString(this.state.language,pageTxtData.becomeABuyer,configJSON.becomeABuyerAr)}</p>
                        </Box>
                        <ArrowForwardIcon className={classes.arrowImg} />
                      </Button>
                      <Button
                        className={classes.waitListBtn}
                        onClick={this.navigateToRegistration}
                      >
                        <Box>
                          <p>{this.getSpecifiedLangString(this.state.language,pageTxtData.becomeASeller,configJSON.becomeASellerAr)}</p>
                        </Box>
                        <ArrowForwardIcon className={classes.arrowImg} />
                      </Button>
                    </div>
                  </div>
                  <div className={classes.heroSection}>
                    <div className={classes.animationContainer}>
                      <div className={classes.leftCardWrapper}>
                        {this.heroCard()}
                        {this.heroCard("heroCard2")}
                        {this.heroCard("heroCard3")}
                        {this.heroCard("heroCard4")}
                        {this.heroCard("heroCard5")}
                        <div className="left-link">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="156"
                            height="81"
                            viewBox="0 0 156 81"
                            fill="none"
                          >
                            <path
                              className="left-link-element"
                              d="M154 79H22C10.9543 79 2 70.0457 2 59V2"
                              stroke="#00F8A2"
                              stroke-width="4"
                              stroke-linecap="round"
                              stroke-dasharray="10 10"
                            />
                          </svg>
                        </div>
                        {this.heroCard("hero-card-bg-top", false)}
                        {this.heroCard("hero-card-bg-bottom", false)}
                      </div>
                      <div className={classes.rightCardWrapper}>
                        <div
                          className={`${classes.personCard} ${classes.personCardTop}`}
                        >
                          <div className="person-header">
                            <div className="person-avatar" />
                            <div className="header-skeleton">
                              <div
                                className={`skeleton ${classes.skeleton} ${classes.skeletonWFull} ${classes.skeletonH2} ${classes.skeletonColor1}`}
                              />
                              <div
                                className={`skeleton ${classes.skeleton} ${classes.skeletonWFull} ${classes.skeletonH2} ${classes.skeletonColor2}`}
                              />
                            </div>
                            <div className="text">
                              <div>{this.getSpecifiedLangString(this.state.language,pageTxtData.liveChatTxt,configJSON.liveChatTxtAr)}</div>
                            </div>
                          </div>
                          <div className="person-content">
                            <div
                              className={`skeleton ${classes.skeleton} ${classes.skeletonWFull} ${classes.skeletonH3} ${classes.skeletonColor2}`}
                            />
                            <div
                              className={`skeleton ${classes.skeleton} ${classes.skeletonW80} ${classes.skeletonH3} ${classes.skeletonColor2}`}
                            />
                          </div>
                          <div className="person-footer">
                            <div className="link-dot">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <g className="link-dot-element">
                                  <circle
                                    cx="8"
                                    cy="8"
                                    r="8"
                                    fill-opacity="0.5"
                                  />
                                  <circle cx="8" cy="8" r="5" />
                                </g>
                              </svg>
                            </div>
                            <div className="skeleton-row">
                              <div
                                className={`skeleton ${classes.skeletonDot} skeleton-dot ${classes.skeletonDotH4} ${classes.skeletonColor3}`}
                              />
                              <div
                                className={`skeleton ${classes.skeleton} ${classes.skeletonW2_3} ${classes.skeletonH4} ${classes.skeletonColor3}`}
                              />
                            </div>
                            <div className="skeleton-row">
                              <div
                                className={`skeleton ${classes.skeleton} ${classes.skeletonW2_3} ${classes.skeletonH4} ${classes.skeletonColor1}`}
                              />
                              <div
                                className={`skeleton ${classes.skeletonDot} skeleton-dot ${classes.skeletonDotH4} ${classes.skeletonColor3}`}
                              />
                            </div>
                            <div className="skeleton-row">
                              <div
                                className={`skeleton ${classes.skeletonDot} skeleton-dot ${classes.skeletonDotH4} ${classes.skeletonColor3}`}
                              />
                              <div
                                className={`skeleton ${classes.skeleton} ${classes.skeletonW2_3} ${classes.skeletonH4} ${classes.skeletonColor3}`}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className={`${classes.personCard} ${classes.personCardBottom}`}
                        >
                          <div className="person-header">
                            <div className="person-avatar" />
                            <div className="person-info">
                              <div className="type">{this.getSpecifiedLangString(this.state.language, pageTxtData.buyerTxt, configJSON.buyerTxtAr)}</div>
                              <div className="name">{pageTxtData.exampleNameTxt}</div>
                              <div className="verified">
                                <span className="text">{this.getSpecifiedLangString(this.state.language,pageTxtData.verifiedTxt,configJSON.verifiedTxtAr)}</span>
                                <div className="verified-symbol">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10"
                                    height="10"
                                    viewBox="0 0 10 10"
                                    fill="none"
                                  >
                                    <g clip-path="url(#clip0_2768_27573)">
                                      <path
                                        className="verified-symbol-element"
                                        d="M9 5L8.11273 3.93714L8.23636 2.53143L6.92364 2.21905L6.23636 1L5 1.55619L3.76364 1L3.07636 2.21524L1.76364 2.52381L1.88727 3.93333L1 5L1.88727 6.06286L1.76364 7.47238L3.07636 7.78476L3.76364 9L5 8.44L6.23636 8.99619L6.92364 7.78095L8.23636 7.46857L8.11273 6.06286L9 5ZM4.30545 6.7981L2.92364 5.34667L3.46182 4.78286L4.30545 5.67048L6.43273 3.43429L6.97091 3.9981L4.30545 6.7981Z"
                                        fill="#5412FC"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_2768_27573">
                                        <rect
                                          width="10"
                                          height="10"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="person-content">
                            <div className="more-person-info">
                              <div className="info-column profile">
                                <div className="info-above">{this.getSpecifiedLangString(this.state.language,pageTxtData.buyerProfileTxt,configJSON.buyerProfileTxtAr)}</div>
                                <div className="info-below">{this.getSpecifiedLangString(this.state.language,pageTxtData.valuationRgTxt,configJSON.valuationRgTxtAr)}</div>
                              </div>
                              <div className="info-column range">
                                <div className="info-above">
                                  {this.getSpecifiedLangString(this.state.language,configJSON.SaasTxt,configJSON.SaasTxtAr)}
                                </div>
                                <div className="info-below">{pageTxtData.exampleRg}</div>
                              </div>
                              <div className="info-column interest">
                                <div className="info-above">{this.getSpecifiedLangString(this.state.language,pageTxtData.buyerInt,configJSON.buyerIntAr)}</div>
                                <div className="info-below">{this.getSpecifiedLangString(this.state.language,configJSON.SaasTxt,configJSON.SaasTxtAr)}</div>
                              </div>
                            </div>
                            <div className="skeleton-row toggle">
                              <div
                                className={`skeleton ${classes.skeleton} ${classes.skeletonW25} ${classes.skeletonH4} ${classes.skeletonColor2}`}
                              />
                              <div
                                className={`skeleton ${classes.skeleton} ${classes.skeletonW35} ${classes.skeletonH4} ${classes.skeletonColor2}`}
                              />
                              <div
                                className={`skeleton ${classes.skeleton} ${classes.skeletonW25} ${classes.skeletonH4} ${classes.skeletonColor2}`}
                              />
                            </div>
                            <div className="skeleton-row">
                              <div
                                className={`skeleton ${classes.skeleton} ${classes.skeletonWFull} ${classes.skeletonH4} ${classes.skeletonColor2}`}
                              />
                            </div>
                            <div className="skeleton-row">
                              <div
                                className={`skeleton special ${classes.skeleton} ${classes.skeletonWFull} ${classes.skeletonH4} ${classes.skeletonColor1}`}
                              />
                            </div>
                            <div className="skeleton-row">
                              <div
                                className={`skeleton ${classes.skeleton} ${classes.skeletonW40} ${classes.skeletonH4} ${classes.skeletonColor2}`}
                              />
                              <div
                                className={`skeleton ${classes.skeleton} ${classes.skeletonW10} ${classes.skeletonH4} ${classes.skeletonColor2}`}
                              />
                            </div>
                          </div>
                          <div className="person-footer">
                            <div
                              className={`skeleton ${classes.skeleton} ${classes.skeletonWFull} ${classes.skeletonH5} ${classes.skeletonColor3}`}
                            />
                          </div>
                        </div>
                        <div className="right-link">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="4"
                            height="64"
                            viewBox="0 0 4 64"
                            fill="none"
                          >
                            <path
                              className="right-link-element"
                              d="M2 62V2"
                              stroke="#00F8A2"
                              stroke-width="4"
                              stroke-linecap="round"
                              stroke-dasharray="10 10"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.anonymousSection}
                >
                  <Typography className={classes.subTitleMobile}>
                    "{pageTxtData.sectionOneContent}"
                  </Typography>
                  <div className={classes.buttonsWrapper}>
                    <Button
                      className={classes.waitListBtnMobile}
                      onClick={this.navigateToRegistration}
                      data-testId="waitListNavSectionOne"
                    >
                          <p>{this.getSpecifiedLangString(this.state.language,pageTxtData.becomeABuyer,configJSON.becomeABuyerAr)}</p>
                      <ArrowForwardIcon
                        className={`${classes.arrowImg} mobile-1`}
                      />
                    </Button>
                    <Button
                      className={classes.waitListBtnMobile}
                      onClick={this.navigateToRegistration}
                    >
                          <p>{this.getSpecifiedLangString(this.state.language,pageTxtData.becomeASeller,configJSON.becomeASellerAr)}</p>
                      <ArrowForwardIcon
                        className={`${classes.arrowImg} mobile-1`}
                      />
                    </Button>
                  </div>
                  <Typography className={classes.section2Subtitle}>
                    {this.getSpecifiedLangString(this.state.language, pageTxtData.anonymousTitle,configJSON.anonymousTitleAr)}
                  </Typography>
                </Grid>
              </div>
            </div>
            <div className={classes.allCardsContainer}>
              <Grid container className={classes.mainCardContainer}>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.greenToplineBox}
                >
                  <div
                    className={`${classes.lineContainer} ${classes.lineContainerTop}`}
                  >
                    <div
                      className={`${classes.staticLine} ${classes.staticLineNoBottom}`}
                    />
                    <div
                      className={`${classes.movingLine} ${classes.movingLineTop}`}
                    />
                    <div
                      className={`${classes.circleLight} ${classes.circleLightTop} ${classes.circleLightTop1}`}
                    />
                    <div
                      className={`${classes.circleLight} ${classes.circleLightTop} ${classes.circleLightTop2}`}
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  className={`${classes.cardGrid} card-grid-first`}
                >
                  <Box className={`${classes.cardContainer} card-first`}>
                    <img
                      src={this.CardDetails[0].img}
                      className={`${classes.cardImg} img-first`}
                    />
                    <Box
                      className={`${classes.cardContentWrapper} card-content-wrapper-first`}
                    >
                      <Typography
                        className={`${classes.cardTitle} card-title-first`}
                      >
                        {this.getSpecifiedLangString(this.state.language,pageTxtData.sellerTxt,configJSON.sellerTxtAr)}
                      </Typography>
                      <Typography
                        className={`${classes.cardText} card-text-first`}
                      >
                        {this.getSpecifiedLangString(this.state.language,pageTxtData.cardContent1,configJSON.cardContent1Ar)}
                      </Typography>
                    </Box>
                  </Box>
                  <div
                    className={`${classes.mobileLineContainer} ${classes.mobileLineContainerTop}`}
                  >
                    <div
                      className={`${classes.staticLine} ${classes.staticLineRightTop}`}
                    />
                    <div
                      className={`${classes.movingLine} ${classes.mobileMovingLineTop}`}
                    />
                    <div
                      className={`${classes.circleLight} ${classes.circleLightTop} ${classes.mobileCircleLightTop}`}
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  className={`${classes.cardGrid} card-grid-second`}
                >
                  <Box className={`${classes.cardContainer} card-second`}>
                    <img
                      src={this.CardDetails[1].img}
                      className={`${classes.cardImg} img-second`}
                    />
                    <Box
                      className={`${classes.cardContentWrapper} card-content-wrapper-second`}
                    >
                      <Typography
                        className={`${classes.cardTitle} card-title-second`}
                      >
                        {this.getSpecifiedLangString(this.state.language,pageTxtData.platformTxt,configJSON.platformTxtAr)}
                      </Typography>
                      <Typography
                        className={`${classes.cardText} card-text-second`}
                      >
                        {this.getSpecifiedLangString(this.state.language,pageTxtData.cardContent2,configJSON.cardContent2Ar)}
                      </Typography>
                    </Box>
                  </Box>
                  <div
                    className={`${classes.mobileLineContainer} ${classes.mobileLineContainerBottom}`}
                  >
                    <div
                      className={`${classes.staticLine} ${classes.staticLineLeftBottom}`}
                    />
                    <div
                      className={`${classes.movingLine} ${classes.mobileMovingLineBottom}`}
                    />
                    <div
                      className={`${classes.circleLight} ${classes.circleLightBottom} ${classes.mobileCircleLightBottom}`}
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  className={`${classes.cardGrid} card-grid-third`}
                >
                  <Box className={`${classes.cardContainer} card-third`}>
                    <img
                      src={this.CardDetails[2].img}
                      className={`${classes.cardImg} img-third`}
                    />
                    <Box
                      className={`${classes.cardContentWrapper} card-content-wrapper-third`}
                    >
                      <Typography
                        className={`${classes.cardTitle} card-title-third`}
                      >
                        {this.getSpecifiedLangString(this.state.language, pageTxtData.buyerTxt, configJSON.buyerTxtAr)}
                      </Typography>
                      <Typography
                        className={`${classes.cardText} card-text-third`}
                      >
                        {this.getSpecifiedLangString(this.state.language,pageTxtData.cardContent3,configJSON.cardContent3Ar)}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.purplelineBox}
                >
                  <div
                    className={`${classes.lineContainer} ${classes.lineContainerBottom}`}
                  >
                    <div
                      className={`${classes.staticLine} ${classes.staticLineNoTop}`}
                    />
                    <div
                      className={`${classes.movingLine} ${classes.movingLineBottom}`}
                    />
                    <div
                      className={`${classes.circleLight} ${classes.circleLightBottom} ${classes.circleLightBottom1}`}
                    />
                    <div
                      className={`${classes.circleLight} ${classes.circleLightBottom} ${classes.circleLightBottom2}`}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className={classes.workOuterSection}>
              <div className={classes.innerMiddleContainer}>
                <Typography className={classes.workHeading}>
                  {this.getSpecifiedLangString(this.state.language,pageTxtData.howNamiWorks,configJSON.howNamiWorksAr)}
                </Typography>
                <div className={classes.unlockWrapper}>
                  <img src={unlocklogo} className={classes.unlockImg} />
                  <div className={classes.workTextContent}>
                    <Typography className={classes.workTitle}>
                    {this.getSpecifiedLangString(this.state.language,pageTxtData.unlockTitle,configJSON.unlockTitleAr)}
                    </Typography>
                    <Box className={`${classes.bulletContainer} container-1`}>
                      <Typography className={classes.workMainSubTitle}>
                        {this.getSpecifiedLangString(this.state.language,pageTxtData.unlockSubTitle,configJSON.unlockSubTitleAr)}
                      </Typography>
                      <div className={classes.eachBullet}>
                        <img
                          src={arrowListIcon}
                          className={classes.arrowBulletPoint}
                          style={{rotate: conditionSort(this.state.language == 'ar', '180deg', '0deg')}}
                        />

                        <Typography className={classes.bulletContent}>
                          <span className={classes.boldStyle}>
                            {this.getSpecifiedLangString(this.state.language,pageTxtData.broadReach,configJSON.broadReachAr)}
                          </span>
                          <span className={classes.workContent}>
                            {this.getSpecifiedLangString(this.state.language,pageTxtData.broadReachContent,configJSON.broadReachContentAr)}
                          </span>
                        </Typography>
                      </div>
                      <div className={classes.eachBullet}>
                        <img
                          src={arrowListIcon}
                          className={classes.arrowBulletPoint}
                          style={{rotate: conditionSort(this.state.language == 'ar', '180deg', '0deg')}}
                        />

                        <Typography className={classes.bulletContent}>
                          <span className={classes.boldStyle}>
                            {this.getSpecifiedLangString(this.state.language,pageTxtData.simpleListing,configJSON.simpleListingAr)}
                          </span>
                          <span className={classes.workContent}>
                            {this.getSpecifiedLangString(this.state.language,pageTxtData.simpleListingContent,configJSON.simpleListingContentAr)}
                          </span>
                        </Typography>
                      </div>
                      <div className={classes.eachBullet}>
                        <img
                          src={arrowListIcon}
                          className={classes.arrowBulletPoint}
                          style={{rotate: conditionSort(this.state.language == 'ar', '180deg', '0deg')}}
                        />

                        <Typography className={classes.bulletContent}>
                          <span className={classes.boldStyle}>
                            {this.getSpecifiedLangString(this.state.language,pageTxtData.dataProtection,configJSON.dataProtectionAr)}
                          </span>
                          <span className={classes.workContent}>
                            {this.getSpecifiedLangString(this.state.language,pageTxtData.dataProtectionContent,configJSON.dataProtectionContentAr)}
                          </span>
                        </Typography>
                      </div>
                    </Box>
                  </div>
                </div>
                <div className={classes.targetWrapper}>
                  <div className={classes.workTextContent}>
                    <Typography className={classes.workTitle}>
                      {this.getSpecifiedLangString(this.state.language,pageTxtData.targetTitle,configJSON.targetTitleAr)}
                    </Typography>
                    <Box className={classes.bulletContainer}>
                      <Typography className={classes.workMainSubTitle}>
                        {this.getSpecifiedLangString(this.state.language,pageTxtData.targetSubTitle,configJSON.targetSubTitleAr)}
                      </Typography>
                      <div className={classes.eachBullet}>
                        <img
                          src={arrowListIcon}
                          className={classes.arrowBulletPoint}
                          style={{rotate: conditionSort(this.state.language == 'ar', '180deg', '0deg')}}
                        />

                        <Typography className={classes.bulletContent}>
                          <span className={classes.boldStyle}>
                            {this.getSpecifiedLangString(this.state.language,pageTxtData.diverseChoices,configJSON.diverseChoicesAr)}
                          </span>
                          <span className={classes.workContent}>
                            {this.getSpecifiedLangString(this.state.language,pageTxtData.diversechoicesContent,configJSON.diversechoicesContentAr)}
                          </span>
                        </Typography>
                      </div>
                      <div className={classes.eachBullet}>
                        <img
                          src={arrowListIcon}
                          className={classes.arrowBulletPoint}
                          style={{rotate: conditionSort(this.state.language == 'ar', '180deg', '0deg')}}
                        />

                        <Typography className={classes.bulletContent}>
                          <span className={classes.boldStyle}>
                            {this.getSpecifiedLangString(this.state.language,pageTxtData.smoothJourney,configJSON.smoothJourneyAr)}
                          </span>
                          <span className={classes.workContent}>
                            {this.getSpecifiedLangString(this.state.language,pageTxtData.smoothJourneyContent,configJSON.smoothJourneyContentAr)}
                          </span>
                        </Typography>
                      </div>
                      <div className={classes.eachBullet}>
                        <img
                          src={arrowListIcon}
                          className={classes.arrowBulletPoint}
                          style={{rotate: conditionSort(this.state.language == 'ar', '180deg', '0deg')}}
                        />

                        <Typography className={classes.bulletContent}>
                          <span className={classes.boldStyle}>
                            {this.getSpecifiedLangString(this.state.language,pageTxtData.tailoredMatches,configJSON.tailoredMatchesAr)}
                          </span>
                          <span className={classes.workContent}>
                            {this.getSpecifiedLangString(this.state.language,pageTxtData.tailordedContent,configJSON.tailordedContentAr)}
                          </span>
                        </Typography>
                      </div>
                    </Box>
                  </div>
                  <img src={targetLogo} className={classes.targetImg} />
                </div>
                <Typography className={classes.preScreenedTitle}>
                  {this.getSpecifiedLangString(this.state.language,pageTxtData.preScreenedtitle,configJSON.preScreenedtitleAr)}
                </Typography>
                <Typography className={classes.preScreenedSubtitle}>
                  <p>{this.getSpecifiedLangString(this.state.language,pageTxtData.preScreenedSubtitle,configJSON.preScreenedSubtitleAr)}</p>
                </Typography>
                <Grid container>
                  <Slider {...settings} className={classes.sliderContainer}>
                    <div dir={conditionSort(isDirRtl, 'rtl', 'ltr')} className={classes.prescreenedCardContainer}>
                      <div>
                        <div className={classes.cardTopSection}>
                          <Box>
                            <Box className={classes.healthcareBox}>
                              <img src={healthCareLogo} alt="" />
                            </Box>
                            <p>{this.getSpecifiedLangString(this.state.language,pageTxtData.healthCareTxt,configJSON.healthCareTxtAr)}</p>
                          </Box>
                          <button>
                            <div className="image-wrapper">
                              <img src={businessLogo} />
                            </div>
                            <p>{this.getSpecifiedLangString(this.state.language,pageTxtData.businessTxt,configJSON.businessTxtAr)}</p>
                          </button>
                        </div>
                        <div>
                          <Grid
                            container
                            className={classes.businessInfoSection}
                          >
                            <Grid
                              item
                              lg={4}
                              md={4}
                              sm={12}
                              xs={12}
                              className={classes.cardInfoSection}
                            >
                              <Typography>{this.getSpecifiedLangString(this.state.language,pageTxtData.saasTitle,configJSON.saasTitleAr)}</Typography>
                            </Grid>
                            <Grid
                              item
                              lg={8}
                              md={8}
                              sm={12}
                              xs={12}
                              className={classes.businessDetails}
                            >
                              <Grid container>
                                <Grid
                                  item
                                  lg={4}
                                  md={4}
                                  sm={6}
                                  xs={6}
                                  className={classes.saasDataContainer}
                                >
                                  <Typography className={classes.valueContent}>
                                    <img src={revenueLogo} />
                                    <Box>
                                    {this.getSpecifiedLangString(this.state.language,pageTxtData.annualRevenue,configJSON.annualRevenueAr)}
                                      <span>{pageTxtData.examplePrice}</span>
                                    </Box>
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  lg={4}
                                  md={4}
                                  sm={6}
                                  xs={6}
                                  className={classes.saasDataContainer}
                                >
                                  <Typography className={classes.valueContent}>
                                    <img src={profitLogo} />
                                    <Box>
                                    {this.getSpecifiedLangString(this.state.language,pageTxtData.annualProfit,configJSON.annualProfitAr)}
                                      <span>{pageTxtData.examplePrice2}</span>
                                    </Box>
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  lg={4}
                                  md={4}
                                  sm={6}
                                  xs={6}
                                  className={classes.saasDataContainer}
                                >
                                  <Typography className={classes.valueContent}>
                                    <img src={mapLogo} />
                                    <Box>
                                      {this.getSpecifiedLangString(this.state.language,pageTxtData.basedIn,configJSON.basedInAr)}
                                      <span>{this.getSpecifiedLangString(this.state.language,pageTxtData.jeddahTxt,configJSON.jeddahTxtAr)}</span>
                                    </Box>
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  lg={4}
                                  md={4}
                                  sm={6}
                                  xs={6}
                                  className={classes.saasDataContainer}
                                >
                                  <Typography className={classes.valueContent}>
                                    <img src={employeesLogo} />
                                    <Box>
                                      {this.getSpecifiedLangString(this.state.language,pageTxtData.numberEmployee,configJSON.numberEmployeeAr)}
                                      <span>50</span>
                                    </Box>
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  lg={4}
                                  md={4}
                                  sm={6}
                                  xs={6}
                                  className={classes.saasDataContainer}
                                >
                                  <Typography className={classes.valueContent}>
                                    <img src={calendarLogo} />
                                    <Box>
                                    {this.getSpecifiedLangString(this.state.language,pageTxtData.foundeIn,configJSON.foundeInAr)}
                                      <span>2018</span>
                                    </Box>
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  lg={4}
                                  md={4}
                                  sm={6}
                                  xs={6}
                                  className={classes.saasDataContainer}
                                >
                                  <Typography className={classes.valueContent}>
                                    <img src={valuationLogo} />
                                    <Box>
                                      {this.getSpecifiedLangString(this.state.language,pageTxtData.valuation,configJSON.valuationAr)}
                                      <span>
                                        {pageTxtData.exampleprice3} <p>({pageTxtData.examplePE})</p>
                                      </span>
                                    </Box>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            {this.getHrContainerUi()}
                          </Grid>
                        </div>
                      </div>
                    </div>
                    <div dir={conditionSort(isDirRtl, 'rtl', 'ltr')} className={classes.prescreenedCardContainer}>
                      <div>
                        <div className={classes.cardTopSection}>
                          <Box>
                            <Box className={classes.mobileIconBox}>
                              <img src={mobileIcon} alt="" />
                            </Box>
                            <p style={{ color: "#10BFB8" }}>
                              {this.getSpecifiedLangString(this.state.language,pageTxtData.mobileAppTxt,configJSON.mobileAppTxtAr)}
                            </p>
                          </Box>
                          <button className="startup-btn">
                            <div className="image-wrapper startup">
                              <img src={startupLogo} />
                            </div>
                            <p>{this.getSpecifiedLangString(this.state.language,pageTxtData.startUpTxt,configJSON.startUpTxtAr)}</p>
                          </button>
                        </div>
                        <div>
                          <Grid
                            container
                            className={classes.businessInfoSection}
                          >
                            <Grid
                              item
                              lg={4}
                              md={4}
                              sm={12}
                              xs={12}
                              className={classes.cardInfoSection}
                            >
                              <Typography>
                                {this.getSpecifiedLangString(this.state.language,pageTxtData.mobileContent,configJSON.mobileContentAr)}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              lg={8}
                              md={8}
                              sm={12}
                              xs={12}
                              className={classes.businessDetails}
                            >
                              <Grid container>
                                <Grid
                                  item
                                  lg={4}
                                  md={4}
                                  sm={6}
                                  xs={6}
                                  className={classes.saasDataContainer}
                                >
                                  <Typography className={classes.valueContent}>
                                    <img src={revenueLogo} />
                                    <Box>
                                      {this.getSpecifiedLangString(this.state.language,pageTxtData.annualRevenue,configJSON.annualRevenueAr)}
                                      <span>{pageTxtData.examplePrice4}</span>
                                    </Box>
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  lg={4}
                                  md={4}
                                  sm={6}
                                  xs={6}
                                  className={classes.saasDataContainer}
                                >
                                  <Typography className={classes.valueContent}>
                                    <img src={profitLogo} />
                                    <Box>
                                      {this.getSpecifiedLangString(this.state.language,pageTxtData.annualProfit,configJSON.annualProfitAr)}
                                      <span>{pageTxtData.examplePrice5}</span>
                                    </Box>
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  lg={4}
                                  md={4}
                                  sm={6}
                                  xs={6}
                                  className={classes.saasDataContainer}
                                >
                                  <Typography className={classes.valueContent}>
                                    <img src={mapLogo} />
                                    <Box>
                                    {this.getSpecifiedLangString(this.state.language,pageTxtData.basedIn,configJSON.basedInAr)}
                                      <span>{this.getSpecifiedLangString(this.state.language,pageTxtData.riyadhTxt,configJSON.riyadhTxtAr)}</span>
                                    </Box>
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  lg={4}
                                  md={4}
                                  sm={6}
                                  xs={6}
                                  className={classes.saasDataContainer}
                                >
                                  <Typography className={classes.valueContent}>
                                    <img src={employeesLogo} />
                                    <Box>
                                    {this.getSpecifiedLangString(this.state.language,pageTxtData.numberEmployee,configJSON.numberEmployeeAr)}
                                      <span>27</span>
                                    </Box>
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  lg={4}
                                  md={4}
                                  sm={6}
                                  xs={6}
                                  className={classes.saasDataContainer}
                                >
                                  <Typography className={classes.valueContent}>
                                    <img src={calendarLogo} />
                                    <Box>
                                      {this.getSpecifiedLangString(this.state.language,pageTxtData.foundeIn,configJSON.foundeInAr)}
                                      <span>2005</span>
                                    </Box>
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  lg={4}
                                  md={4}
                                  sm={6}
                                  xs={6}
                                  className={classes.saasDataContainer}
                                >
                                  <Typography className={classes.valueContent}>
                                    <img src={valuationLogo} />
                                    <Box>
                                    {this.getSpecifiedLangString(this.state.language,pageTxtData.valuation,configJSON.valuationAr)}
                                      <span>
                                        {pageTxtData.examplePrice6} <p>({pageTxtData.exampleEVRe})</p>
                                      </span>
                                    </Box>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            {this.getHrContainerUi()}
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </Grid>
              </div>
              <div className={classes.whatPeopleSayAboutUsSection}>
                <div className={classes.whatPeopleSayTitleWrapper}>
                  <div className={classes.whatPeopleSayTitle}>
                    {this.getSpecifiedLangString(this.state.language,pageTxtData.whatPeopleSayTitle,configJSON.whatPeopleSayTitleAr)}
                  </div>
                </div>
                <div className={classes.whatPeopleSayContentWrapper}>
                  {this.state.feedbackList.map((feedback: {attributes: {account: {first_name: string, last_name: string, image: {image: string}}, user_feedback: string}}, fIndex: number) => {
                    return(
                      <div key={fIndex} style={{width: "50%"}}>
                      {this.whatPeopleSayAboutUsBlock(fIndex+1, feedback.attributes)}
                      </div>
                    )
                  })}

                </div>
                {
                  this.state.showViewMoreBtn && <Box className={classes.similarSectionRow}>
                  <Button className={`${classes.btnOutline}`}
                    onClick={this.navigateToFeedback}
                    data-testId="waitListNavCommunity">{this.getSpecifiedLangString(this.state.language,pageTxtData.viewMore,configJSON.viewMoreAr)}</Button>
                </Box>
                }
                

              </div>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container className={classes.communityContainer}>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="left-wrapper"
                  >
                    <Typography className={classes.communityTitle}>
                      {this.getSpecifiedLangString(this.state.language,pageTxtData.communityTitle,configJSON.communityTitleAr)}
                    </Typography>
                    <Button
                      className={`${classes.waitListBtn} bottom`}
                      onClick={this.navigateToRegistration}
                      data-testId="waitListNavCommunity"
                    >
                      <Box>
                        <p>{this.getSpecifiedLangString(this.state.language,pageTxtData.signUpNow,configJSON.signUpNowAr)}</p>
                      </Box>
                      <ArrowForwardIcon
                        className={classes.arrowImg}
                        style={{ backgroundColor: "#10BFB8", color: "#FFF" }}
                      />
                    </Button>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="right-wrapper"
                  >
                    <div className={classes.imgWrapper}>
                      <img
                        src={communityLogo}
                        className={classes.communityImg}
                      />
                      <img src={foldedArrow} className={classes.foldedArrow} />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </main>
          <FooterBlock />
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const staticDashColor = "#8c8c8c";
const movingDashColor1 = "#00f8a2";
const movingDashColor2 = "#743efd";
const staticLineThickness = "4px"; 
const mobileStaticLineThickness = "2px";
const movingLineThickness = "8px"; 
const mobileMovingLineThickness = "4px";
const staticDashLength = "18px"; 
const staticDashSpacing = staticDashLength;
const mobileStaticDashLength = "9px";
const mobileStaticDashSpacing = mobileStaticDashLength;
const movingDashLength = "100px"; 
const mobileMovingDashLength = "50px";
const routeRatio = 18;
const movingLineDuration = "60s";
const mobileMovingLineDuration = "30s";

const oneWayRoute = parseInt(movingDashLength) * routeRatio + "px";
const mobileOneWayRoute = parseInt(mobileMovingDashLength) * routeRatio + "px";

const adjustTranslateYCenter = "-50%";
const cardShift1 = "100%";
const cardShift2 = "210%";
const scaleRatio = 0.77;
const shiftedCardOpacity = 0.7;
const heroDuration = "12s";
const defaultColor1 = "rgba(140, 140, 140, 1)";
const defaultColor2 = "rgba(140, 140, 140, 0.5)";
const activeColor1 = "rgba(16, 191, 184, 1)";
const activeColor2 = "rgba(16, 191, 184, 0.5)";
const activeColor3 = "rgba(84, 18, 252, 1)";
const activeColor4 = "rgba(0, 0, 0, 1)";
const activeColor5 = "rgba(0, 248, 162, 1)";

export const customStyle = (theme: Theme) =>
  createStyles({
    similarSectionRow: {
      textAlign: "center", 
      margin: "2em"
    },
    sliderContainer: {
      width: "100% !important",
      direction: "ltr",
      "& .slick-list": {
        marginBottom: "6px !important", 
      },
      "& .slick-dots": {
        display: "flex",
        transform: conditionSort(isDirRtl, "rotate(180deg)", "rotate(0deg)"),
        justifyContent: "center",
        margin: 0,
        padding: "0rem 0",
        listStyleType: "none",
        bottom: "-59px",
        [theme.breakpoints.down("sm")]: {
          bottom: "-28px",
        },
        "& li": {
          margin: "0 0.25rem",
          [theme.breakpoints.down("sm")]: {
            margin: "0 3px",
            width: "8px",
            height: "8px",
          },
        },
        "& button": {
          display: "block",
          width: "1rem",
          height: "1rem",
          padding: 0,
          border: "none",
          borderRadius: "100%",
          backgroundColor: "#E6E6E6",
          textIndent: "-9999px",
          [theme.breakpoints.down("sm")]: {
            width: "8px",
            height: "8px",
          },
        },
        "& li.slick-active button": {
          backgroundColor: "#5412FC",
        },
      },
    },
    firstContainer: {
      marginTop: "66px",
      paddingLeft: "65px",
      paddingRight: "65px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "42px",
        paddingLeft: "18px",
        paddingRight: "18px",
      },
    },
    innerMiddleContainer: {
      paddingLeft: "104px",
      paddingRight: "104px",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "23px",
        paddingRight: "23px",
      },
    },
    whatPeopleSayAboutUsSection: {
      marginTop: 117, 
      marginBottom: 59,
      fontFamily: "Montserrat, 'sans-serif'",
      [theme.breakpoints.down("sm")]: {
        marginTop: 61, 
        marginBottom: 60,
      },
    },
    whatPeopleSayTitleWrapper: {
      textAlign: "center",
      marginBottom: "1.02em",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "1em",
      },
    },
    whatPeopleSayTitle: {
      display: "inline-block",
      width: 422,
      color: "#5412FC",
      fontSize: "clamp(2.375rem, 1.875rem + 0.8333vw, 2.5rem)",
      fontWeight: 700,
      lineHeight: "50px",
      letterSpacing: "-1px",
      textTransform: "capitalize",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        fontSize: "24px",
        fontWeight: 700,
      },
    },
    whatPeopleSayContentWrapper: {
      display: "flex",
      gap: 22,
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        gap: 60,
        alignItems: "center",
      },
    },
    whatPeopleSayBlock: {
      maxWidth: 593,
      paddingTop: 24,
      paddingBottom: 22,
      paddingLeft: 24,
      paddingRight: 62,
      display: "flex",
      alignItems: "center",
      borderRadius: 200,
      background: "linear-gradient(277deg, #5412FC 9.57%, #10BFB8 95.8%)",
      transition: "all 2s ease",
      [theme.breakpoints.down("sm")]: {
        maxWidth: 300,
        paddingTop: 12,
        paddingBottom: 11,
        paddingLeft: 12,
        paddingRight: 15,
      },
      "&:hover": {
        background: "#5412FC",
      },
    },
    whatPeopleSayAvatar: {
      width: 168,
      height: 170,
      borderRadius: "50%",
      border: "5px solid rgba(84, 18, 252, 0.50)",
      marginRight: 24,
      [theme.breakpoints.down("sm")]: {
        width: 85,
        height: 86,
        marginRight: 10,
      },
    },
    whatPeopleSayTextWrapper: {
      color: "#FFF",
    },
    whatPeopleSayText: {
      fontSize: 20,
      fontWeight: 700,
      lineHeight: "20px",
      marginBottom: 11,
      "&.hovered": {
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 0,
        [theme.breakpoints.down("sm")]: {
          fontSize: 12,
        },
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: "20px",
        marginBottom: 7,
      },
    },
    whatPeopleSayName: {
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: "-2px",
      textTransform: "capitalize",
      "&.hovered": {
        fontSize: 24,
        [theme.breakpoints.down("sm")]: {
          fontSize: 18,
          textAlign: "left",
        },
      },
      [theme.breakpoints.down("sm")]: {
        textAlign: "right",
        paddingRight: 20,
        letterSpacing: "normal",
        fontSize: 12,
        fontWeight: 400,
        lineHeight: "normal",
      },
    },
    oneYearWithUs: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1,
      marginBottom: 12,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },
    workOuterSection: {
      marginTop: "86px",
      backgroundImage: "url(" + arrowBg + ")",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      [theme.breakpoints.down("sm")]: {
        marginTop: "16px",
      },
    },
    mainDiv: {
      backgroundImage: "url(" + mainLandingBg + ")",
      backgroundSize: "contain",
      backgroundPosition: "top center",
      backgroundRepeat: "no-repeat",
      [theme.breakpoints.down("sm")]: {
        backgroundImage: "url(" + mainLandingBgMobile + ")",
      },
      "& *": {
        boxSizing: "border-box",
      },
      "& main": {
        maxWidth: "1440px",
        margin: "0 auto",
      },
    },
    contentField: {
      width: "62.3%",
      paddingLeft: "min(10%,60px)",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        alignItems: "center",
        width: "100%",
        paddingLeft: "0",
      },
    },
    buttonsWrapper: {
      display: "flex",
      gap: "60px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: 16,
        gap: 27,
        justifyContent: "center",
      },
    },
    btnOutline:{
      "borderRadius": "12px",
      "border": "2px solid #10BFB8",
      "background": "#FFF",
      "width": "240px",
      "height": "60px",
      textAlign: "center",
      textTransform: "none",
      "& span":{
          "color": "#10BFB8",
          "fontFamily": "Montserrat",
          "fontSize": "20px",
          "fontStyle": "normal",
          "fontWeight": 700,
          "lineHeight": "24px"
      }
  },
    waitListBtn: {
      borderRadius: "100px",
      border: "3px solid #FFF",
      color: "#FFF",
      fontFamily: "Montserrat,sans-serif",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "normal",
      textTransform: "none" as "none",
      display: "flex",
      justifyContent: "center !important",
      paddingTop: "6px",
      paddingBottom: "6px",
      paddingLeft: conditionSort(isDirRtl, '6px', '31px'),
      paddingRight: conditionSort(isDirRtl, '31px', '6px'),
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      "&.bottom": {
        color: "#10BFB8",
        borderColor: "#10BFB8",
        [theme.breakpoints.down("sm")]: {
          display: "flex",
          borderWidth: "2px",
          paddingTop: "3px",
          paddingBottom: "3px",
          paddingLeft: "15px",
          paddingRight: "3px",
          fontSize: "10px",
        },
        "&:hover": {
          backgroundColor: "#10BFB8",
          color: "#FFF",
        },
      },
      "&:hover": {
        color: " #3960DE",
        background: "#FFF",
      },
      "& div": {
        margin: "auto !important",
        padding: "0",
      },
      "& p": {
        margin: "0",
      },
    },
    waitListBtnMobile: {
      display: "none",
      
      borderRadius: "100px",
      border: "2px solid #5412FC",
      color: "#5412FC",
      fontFamily: "Montserrat,sans-serif",
      fontSize: "10px !important",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "normal",
      textTransform: "none",
      paddingTop: "5px",
      paddingBottom: "5px",
      paddingRight: "5px",
      paddingLeft: "17px",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
      },
      "& p": {
        margin: "0",
      },
      "&:hover": {
        color: " #FFF",
        background: "#5412FC",
        "& mobile-1": {
          color: "#5412fc",
          background: "#FFFFFF",
        },
      },
    },
    arrowImg: {
      background: "#FFFFFF",
      borderRadius: "50%",
      color: "#4343E9",
      width: "42px",
      height: "42px",
      marginLeft: conditionSort(isDirRtl, '0', '12px'),
      marginRight: conditionSort(isDirRtl, '12px', '0px'),
      "&.mobile-1": {
        background: "#5412fc",
        color: "#FFFFFF",
      },
      [theme.breakpoints.down("sm")]: {
        width: "23px",
        height: "23px",
        marginLeft: "4px",
      },
    },
    title: {
      marginBottom: "49px",
      color: "#FFF",
      fontFamily: "Montserrat,sans-serif !important",
      fontSize: "clamp(1.8rem, 0.5rem + 2.5vw, 2.375rem)",
      fontWeight: 900,
      lineHeight: "50px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "22px",
        fontSize: "16px",
        lineHeight: "23px",
        padding: "0 30px",
        textAlign: "center",
      },
    },
    subTitle: {
      marginBottom: "82px",
      color: "#FFF",
      fontFamily: "Montserrat,sans-serif !important",
      fontSize: "clamp(1.125rem, 0.125rem + 1.6667vw, 1.375rem) !important",
      fontStyle: "normal",
      fontWeight: (500 + "!important") as never,
      lineHeight: "40px !important",
      textTransform: "capitalize" as "capitalize",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    anonymousSection: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "0vw",
      },
    },
    subTitleMobile: {
      display: "none",
      color: "#5412FC",
      fontFamily: "Montserrat,sans-serif",
      fontSize: "10px !important",
      fontWeight: 700,
      lineHeight: "18px",
      textTransform: "capitalize",
      marginTop: "66px",
      marginBottom: "26px",
      padding: "0 50px",
      textAlign: "center" as "center",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    section2titleOne: {
      color: "#FFF",
      textAlign: "center" as "center",
      fontFamily: "Montserrat,sans-serif",
      fontSize: "28px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "60px",
      paddingBottom: "86px !important",
    },
    section2Content: {
      color: "#FFF",
      textAlign: "center" as "center",
      fontFamily: "Montserrat,sans-serif",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "26px",
      padding: "0px 20px 92px 20px !important",
    },
    section2Subtitle: {
      color: "#FFF",
      textAlign: "center",
      fontFamily: "Montserrat,sans-serif !important",
      fontSize: "clamp(1.8rem, 0.5rem + 2.5vw, 2.375rem)",
      fontStyle: "normal",
      fontWeight: 900,
      lineHeight: 1.58,
      textTransform: "capitalize",
      padding: "0",
      marginTop: "30px !important",
      marginBottom: "230px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "0px !important",
        marginBottom: "16px",
        color: "#5412FC",
        fontSize: "15px",
        lineHeight: "60px",
      },
    },
    cardOuterContainer: {
      "&:hover": {
        borderRadius: "0vw 1.4vw 0vw 12.5vw",
        borderColor: "transparent",
        borderStyle: "solid",
        borderWidth: "1px",
        borderImage:
          "linear-gradient(to right, rgb(0, 248, 162), rgb(84, 18, 252)) 1 / 1 / 0 stretch",
      },
    },

    cardGrid: {
      padding: "0 20px",
      zIndex: 1,
      [theme.breakpoints.down("sm")]: {
        padding: "0 52px",
      },
      "&.card-grid-first": {
        [theme.breakpoints.down("sm")]: {
          position: "relative",
          marginBottom: "30px",
        },
      },
      "&.card-grid-second": {
        [theme.breakpoints.down("sm")]: {
          position: "relative",
          marginBottom: "30px",
        },
      },
      "&.card-grid-third": {},
    },
    cardContainer: {
      flexShrink: 0, 
      border: "4px solid #00F8A2", 
      background: "#FFF",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      display: "flex", 
      flexDirection: "column", 
      zIndex: 1,
      [theme.breakpoints.down("sm")]: {
        width: "66%",
        padding: "10px",
        borderWidth: "2px",
      },
      "&.card-first": {
        borderRadius: "0px 20px 0px 180px",
        alignItems: "flex-start",
        justifyContent: "space-evenly",
        marginTop: "165px",
        padding: "24px",
        [theme.breakpoints.down("sm")]: {
          marginTop: "0",
          borderRadius: "0px 8px 0px 100px",
          paddingTop: "10px",
          paddingLeft: "10px",
          paddingRight: "12px",
          paddingBottom: "20px",
        },
      },
      "&.card-second": {
        borderRadius: "20px",
        direction: conditionSort(isDirRtl, 'rtl', 'ltr'),
        alignItems: "center",
        textAlign: "center",
        marginTop: "40px",
        paddingTop: "27px",
        paddingRight: "26px",
        paddingBottom: "24px",
        paddingLeft: "26px",
        [theme.breakpoints.down("sm")]: {
          marginTop: "0",
          marginLeft: "auto",
          borderRadius: "8px",
          paddingTop: "8px",
          paddingLeft: "19px",
          paddingRight: "19px",
          paddingBottom: "13px",
        },
      },
      "&.card-third": {
        borderRadius: "20px 0px 180px 0px",
        direction: conditionSort(isDirRtl, 'rtl', 'ltr'),
        marginTop: "44%",
        padding: "24px",
        [theme.breakpoints.down("sm")]: {
          marginTop: "0",
          padding: "14px",
          borderRadius: "100px 0px 8px 0px",
          paddingTop: "10px",
          paddingLeft: "20px",
          paddingRight: "10px",
          paddingBottom: "24px",
        },
      },
    },
    cardImg: {
      [theme.breakpoints.down("sm")]: {
        width: "100px",
      },
      "&.img-first": {
        flexShrink: 0,
      },
      "&.img-second": {
        flexShrink: 0,
        marginBottom: "20px",
        [theme.breakpoints.down("sm")]: {
          marginBottom: 0,
        },
      },
      "&.img-third": {
        display: "block",
        marginLeft: "auto",
      },
    },
    cardContentWrapper: {
      color: "#5412FC",
      fontFamily: "Poppins, sans-serif !important",
      display: "flex",
      flexDirection: "column",
      "&.card-content-wrapper-first": {
        textAlign: "right",
        direction: conditionSort(isDirRtl, 'rtl', 'ltr'),
        gap: "22px",
        alignItems: "end",
        paddingRight: "20px",
        paddingBottom: "20px",
        paddingLeft: "87px", 
        [theme.breakpoints.down("sm")]: {
          paddingTop: 0,
          paddingBottom: 0,
          paddingRight: 0,
          paddingLeft: "59px", 
          gap: "4px",
          marginTop: "-14px",
        },
      },
      "&.card-content-wrapper-second": {
        textAlign: "center",
        width: "100%",
        gap: "24px",
        [theme.breakpoints.down("sm")]: {
          gap: "7px",
          padding: 0,
        },
      },
      "&.card-content-wrapper-third": {
        gap: "22px",
        paddingLeft: "20px",
        paddingBottom: "20px",
        paddingRight: "81px", 
        [theme.breakpoints.down("sm")]: {
          gap: "8px",
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: "46px", 
          marginTop: "-14px",
        },
      },
    },
    cardTitle: {
      fontFamily: "inherit",
      fontSize: "32px",
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        fontSize: "15px",
      },
      "&.card-title-first": {
        lineHeight: "36px",
        width: "100%",
        [theme.breakpoints.down("sm")]: {
        },
      },
      "&.card-title-second": {
        lineHeight: "28px",
        [theme.breakpoints.down("sm")]: {
        },
      },
      "&.card-title-third": {
        lineHeight: "28px",
        [theme.breakpoints.down("sm")]: {
        },
      },
    },
    cardText: {
      fontFamily: "inherit",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "26px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "7px !important",
        lineHeight: "normal",
      },
      "&.card-text-first": {
        [theme.breakpoints.down("sm")]: {
        },
      },
      "&.card-text-second": {
        [theme.breakpoints.down("sm")]: {
        },
      },
      "&.card-text-third": {
        [theme.breakpoints.down("sm")]: {
        },
      },
    },

    purplelineBox: {
      display: "flex",
      justifyContent: "end",
      zIndex: 0,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    purpleline: {
      width: "45% !important",
    },
    greenToplineBox: {
      zIndex: 0,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    lineContainer: {
      position: "relative",
      height: "250px",
      width: "33.33%",
    },
    lineContainerTop: {
      marginBottom: "-15%",
      marginLeft: "16.66%",
    },
    lineContainerBottom: {
      marginTop: "-15%",
      marginRight: "16.66%",
    },
    staticLine: {
      position: "absolute",
      top: `calc(${staticLineThickness}/2)`,
      left: `calc(${staticLineThickness}/2)`,
      zIndex: -10,
      width: `calc(100% - ${staticLineThickness})`,
      height: `calc(100% - ${staticLineThickness})`,
      backgroundSize: `
        ${staticLineThickness} ${staticDashLength},
        ${staticLineThickness} calc(${staticDashLength} * 100),
        ${staticDashLength} ${staticLineThickness},
        calc(${staticDashLength} * 100) ${staticLineThickness},
        ${staticLineThickness} ${staticDashLength},
        ${staticLineThickness} calc(${staticDashLength} * 100),
        ${staticDashLength} ${staticLineThickness},
        calc(${staticDashLength} * 100) ${staticLineThickness}`,
      backgroundRepeat: `
        no-repeat repeat, no-repeat no-repeat, 
        repeat no-repeat, no-repeat no-repeat, 
        no-repeat repeat, no-repeat no-repeat, 
        repeat no-repeat, no-repeat no-repeat
      `,
      backgroundPosition: `
        0 calc(${staticDashLength}/2), 0 0,
        calc(${staticDashLength}/2) 0, 0 0,
        100% calc(${staticDashLength}/2), 100% 0,
        calc(${staticDashLength}/2) 100%, 0 100%
      `,
      [theme.breakpoints.down("sm")]: {
        top: `calc(${mobileStaticLineThickness}/2)`,
        left: `calc(${mobileStaticLineThickness}/2)`,
        width: `calc(100% - ${mobileStaticLineThickness})`,
        height: `calc(100% - ${mobileStaticLineThickness})`,
        backgroundSize: `
          ${mobileStaticLineThickness} ${mobileStaticDashLength},
          ${mobileStaticLineThickness} calc(${mobileStaticDashLength} * 100),
          ${mobileStaticDashLength} ${mobileStaticLineThickness},
          calc(${mobileStaticDashLength} * 100) ${mobileStaticLineThickness},
          ${mobileStaticLineThickness} ${mobileStaticDashLength},
          ${mobileStaticLineThickness} calc(${mobileStaticDashLength} * 100),
          ${mobileStaticDashLength} ${mobileStaticLineThickness},
          calc(${mobileStaticDashLength} * 100) ${mobileStaticLineThickness}`,
        backgroundPosition: `
          0 calc(${mobileStaticDashLength}/2), 0 0,
          calc(${mobileStaticDashLength}/2) 0, 0 0,
          100% calc(${mobileStaticDashLength}/2), 100% 0,
          calc(${mobileStaticDashLength}/2) 100%, 0 100%
        `,
      },
    },
    staticLineNoBottom: {
      backgroundImage: `
        radial-gradient(circle closest-side, ${staticDashColor} 98%, transparent),
        repeating-linear-gradient(
          0deg,
          ${staticDashColor},
          ${staticDashColor} ${staticDashLength},
          transparent ${staticDashLength},
          transparent calc(${staticDashLength} + ${staticDashSpacing})
        ),
        radial-gradient(circle closest-side, ${staticDashColor} 98%, transparent),
        repeating-linear-gradient(
          90deg,
          ${staticDashColor},
          ${staticDashColor} ${staticDashLength},
          transparent ${staticDashLength},
          transparent calc(${staticDashLength} + ${staticDashSpacing})
        ),
        radial-gradient(circle closest-side, ${staticDashColor} 98%, transparent),
        repeating-linear-gradient(
          180deg,
          ${staticDashColor},
          ${staticDashColor} ${staticDashLength},
          transparent ${staticDashLength},
          transparent calc(${staticDashLength} + ${staticDashSpacing})
        ),
        radial-gradient(circle closest-side, transparent 98%, transparent),
        repeating-linear-gradient(
            270deg,
            transparent,
            transparent ${staticDashLength},
            transparent ${staticDashLength},
            transparent calc(${staticDashLength} + ${staticDashSpacing})
        )
      `,
    },
    staticLineNoTop: {
      backgroundImage: `
        radial-gradient(circle closest-side, ${staticDashColor} 98%, transparent),
        repeating-linear-gradient(
          0deg,
          ${staticDashColor},
          ${staticDashColor} ${staticDashLength},
          transparent ${staticDashLength},
          transparent calc(${staticDashLength} + ${staticDashSpacing})
        ),
        radial-gradient(circle closest-side, transparent 98%, transparent),
        repeating-linear-gradient(
          90deg,
          transparent,
          transparent ${staticDashLength},
          transparent ${staticDashLength},
          transparent calc(${staticDashLength} + ${staticDashSpacing})
        ),
        radial-gradient(circle closest-side, ${staticDashColor} 98%, transparent),
        repeating-linear-gradient(
          180deg,
          ${staticDashColor},
          ${staticDashColor} ${staticDashLength},
          transparent ${staticDashLength},
          transparent calc(${staticDashLength} + ${staticDashSpacing})
        ),
        radial-gradient(circle closest-side, ${staticDashColor} 98%, transparent),
        repeating-linear-gradient(
          270deg,
          ${staticDashColor},
          ${staticDashColor} ${staticDashLength},
          transparent ${staticDashLength},
          transparent calc(${staticDashLength} + ${staticDashSpacing})
        )
      `,
    },
    staticLineRightTop: {
      backgroundImage: `
        radial-gradient(circle closest-side, transparent 98%, transparent),
        repeating-linear-gradient(
          0deg,
          transparent,
          transparent ${mobileStaticDashLength},
          transparent ${mobileStaticDashLength},
          transparent calc(${mobileStaticDashLength} + ${mobileStaticDashSpacing})
        ),
        radial-gradient(circle closest-side, ${staticDashColor} 98%, transparent),
        repeating-linear-gradient(
          90deg,
          ${staticDashColor},
          ${staticDashColor} ${mobileStaticDashLength},
          transparent ${mobileStaticDashLength},
          transparent calc(${mobileStaticDashLength} + ${mobileStaticDashSpacing})
        ),
        radial-gradient(circle closest-side, ${staticDashColor} 98%, transparent),
        repeating-linear-gradient(
          180deg,
          ${staticDashColor},
          ${staticDashColor} ${mobileStaticDashLength},
          transparent ${mobileStaticDashLength},
          transparent calc(${mobileStaticDashLength} + ${mobileStaticDashSpacing})
        ),
        radial-gradient(circle closest-side, transparent 98%, transparent),
        repeating-linear-gradient(
          270deg,
          transparent,
          transparent ${mobileStaticDashLength},
          transparent ${mobileStaticDashLength},
          transparent calc(${mobileStaticDashLength} + ${mobileStaticDashSpacing})
        )
      `,
    },
    staticLineLeftBottom: {
      backgroundImage: `
        radial-gradient(circle closest-side, ${staticDashColor} 98%, transparent),
        repeating-linear-gradient(
          0deg,
          ${staticDashColor},
          ${staticDashColor} ${mobileStaticDashLength},
          transparent ${mobileStaticDashLength},
          transparent calc(${mobileStaticDashLength} + ${mobileStaticDashSpacing})
        ),
        radial-gradient(circle closest-side, ${staticDashColor} 98%, transparent),
        repeating-linear-gradient(
          90deg,
          ${staticDashColor},
          ${staticDashColor} ${mobileStaticDashLength},
          transparent ${mobileStaticDashLength},
          transparent calc(${mobileStaticDashLength} + ${mobileStaticDashSpacing})
        ),
        radial-gradient(circle closest-side, transparent 98%, transparent),
        repeating-linear-gradient(
          180deg,
          transparent,
          transparent ${mobileStaticDashLength},
          transparent ${mobileStaticDashLength},
          transparent calc(${mobileStaticDashLength} + ${mobileStaticDashSpacing})
        ),
        radial-gradient(circle closest-side, transparent 98%, transparent),
        repeating-linear-gradient(
          270deg,
          transparent,
          transparent ${mobileStaticDashLength},
          transparent ${mobileStaticDashLength},
          transparent calc(${mobileStaticDashLength} + ${mobileStaticDashSpacing})
        )
      `,
    },
    movingLine: {
      height: "100%",
      backgroundSize: `
        ${movingLineThickness} ${movingDashLength},
        ${movingLineThickness} calc(${movingDashLength} * 100),
        ${movingDashLength} ${movingLineThickness},
        calc(${movingDashLength} * 100) ${movingLineThickness},
        ${movingLineThickness} ${movingDashLength},
        ${movingLineThickness} calc(${movingDashLength} * 100),
        ${movingDashLength} ${movingLineThickness},
        calc(${movingDashLength} * 100) ${movingLineThickness}
      `,
      backgroundRepeat: `
        no-repeat repeat, no-repeat no-repeat, 
        repeat no-repeat, no-repeat no-repeat, 
        no-repeat repeat, no-repeat no-repeat, 
        repeat no-repeat, no-repeat no-repeat
      `,
      backgroundPosition: `
        0 calc(${movingDashLength}/2), 0 0,
        calc(${movingDashLength}/2) 0, 0 0,
        100% calc(${movingDashLength}/2), 100% 0,
        calc(${movingDashLength}/2) 100%, 0 100%
      `,
      [theme.breakpoints.down("sm")]: {
        backgroundSize: `
          ${mobileMovingLineThickness} ${mobileMovingDashLength},
          ${mobileMovingLineThickness} calc(${mobileMovingDashLength} * 100),
          ${mobileMovingDashLength} ${mobileMovingLineThickness},
          calc(${mobileMovingDashLength} * 100) ${mobileMovingLineThickness},
          ${mobileMovingLineThickness} ${mobileMovingDashLength},
          ${mobileMovingLineThickness} calc(${mobileMovingDashLength} * 100),
          ${mobileMovingDashLength} ${mobileMovingLineThickness},
          calc(${mobileMovingDashLength} * 100) ${mobileMovingLineThickness}
        `,
        backgroundPosition: `
          0 calc(${mobileMovingDashLength}/2), 0 0,
          calc(${mobileMovingDashLength}/2) 0, 0 0,
          100% calc(${mobileMovingDashLength}/2), 100% 0,
          calc(${mobileMovingDashLength}/2) 100%, 0 100%
        `,
      },
    },
    movingLineTop: {
      animation: `$topMoving ${movingLineDuration} infinite linear`,
      backgroundImage: `
        radial-gradient(
          circle closest-side,
          ${movingDashColor1} 98%,
          transparent
        ),
        repeating-linear-gradient(
          0deg,
          ${movingDashColor1},
          ${movingDashColor1} ${movingDashLength},
          transparent ${movingDashLength},
          transparent calc(2 * ${movingDashLength})
        ),
        radial-gradient(
            circle closest-side,
            ${movingDashColor1} 98%,
            transparent
          ),
        repeating-linear-gradient(
          90deg,
          ${movingDashColor1},
          ${movingDashColor1} ${movingDashLength},
          transparent ${movingDashLength},
          transparent calc(2 * ${movingDashLength})
        ),
        radial-gradient(
            circle closest-side,
            ${movingDashColor1} 98%,
            transparent
          ),
        repeating-linear-gradient(
          180deg,
          ${movingDashColor1},
          ${movingDashColor1} ${movingDashLength},
          transparent ${movingDashLength},
          transparent calc(2 * ${movingDashLength})
        ),
        radial-gradient(circle closest-side, transparent 98%, transparent),
        repeating-linear-gradient(
            270deg,
            transparent,
            transparent ${movingDashLength},
            transparent ${movingDashLength},
            transparent calc(2 * ${movingDashLength})
        )
      `,
    },
    movingLineBottom: {
      animation: `$downMoving ${movingLineDuration} infinite linear`,
      backgroundImage: `
        radial-gradient(
          circle closest-side,
          ${movingDashColor2} 98%,
          transparent
        ),
        repeating-linear-gradient(
          0deg,
          ${movingDashColor2},
          ${movingDashColor2} ${movingDashLength},
          transparent ${movingDashLength},
          transparent calc(2 * ${movingDashLength})
        ),
        radial-gradient(circle closest-side, transparent 98%, transparent),
        repeating-linear-gradient(
          90deg,
          transparent,
          transparent ${movingDashLength},
          transparent ${movingDashLength},
          transparent calc(2 * ${movingDashLength})
        ),
        radial-gradient(
            circle closest-side,
            ${movingDashColor2} 98%,
            transparent
          ),
        repeating-linear-gradient(
          180deg,
          ${movingDashColor2},
          ${movingDashColor2} ${movingDashLength},
          transparent ${movingDashLength},
          transparent calc(2 * ${movingDashLength})
        ),
        radial-gradient(
          circle closest-side,
          ${movingDashColor2} 98%,
          transparent
        ),
        repeating-linear-gradient(
            270deg,
            ${movingDashColor2},
            ${movingDashColor2} ${movingDashLength},
            transparent ${movingDashLength},
            transparent calc(2 * ${movingDashLength})
        )
      `,
    },
    mobileMovingLineTop: {
      animation: `$mobileTopMoving ${mobileMovingLineDuration} infinite linear`,
      backgroundImage: `
        radial-gradient(
          circle closest-side,
          transparent 98%,
          transparent
        ),
        repeating-linear-gradient(
          0deg,
          transparent,
          transparent ${mobileMovingDashLength},
          transparent ${mobileMovingDashLength},
          transparent calc(2 * ${mobileMovingDashLength})
        ),
        radial-gradient(
            circle closest-side,
            ${movingDashColor1} 98%,
            transparent
          ),
        repeating-linear-gradient(
          90deg,
          ${movingDashColor1},
          ${movingDashColor1} ${mobileMovingDashLength},
          transparent ${mobileMovingDashLength},
          transparent calc(2 * ${mobileMovingDashLength})
        ),
        radial-gradient(
            circle closest-side,
            ${movingDashColor1} 98%,
            transparent
          ),
        repeating-linear-gradient(
          180deg,
          ${movingDashColor1},
          ${movingDashColor1} ${mobileMovingDashLength},
          transparent ${mobileMovingDashLength},
          transparent calc(2 * ${mobileMovingDashLength})
        ),
        radial-gradient(circle closest-side, transparent 98%, transparent),
        repeating-linear-gradient(
            270deg,
            transparent,
            transparent ${mobileMovingDashLength},
            transparent ${mobileMovingDashLength},
            transparent calc(2 * ${mobileMovingDashLength})
        )
      `,
    },
    mobileMovingLineBottom: {
      animation: `$mobileDownMoving ${mobileMovingLineDuration} infinite linear`,
      backgroundImage: `
        radial-gradient(
          circle closest-side,
          ${movingDashColor2} 98%,
          transparent
        ),
        repeating-linear-gradient(
          0deg,
          ${movingDashColor2},
          ${movingDashColor2} ${mobileMovingDashLength},
          transparent ${mobileMovingDashLength},
          transparent calc(2 * ${mobileMovingDashLength})
        ),
        radial-gradient(circle closest-side, ${movingDashColor2} 98%, transparent),
        repeating-linear-gradient(
          90deg,
          ${movingDashColor2},
          ${movingDashColor2} ${mobileMovingDashLength},
          transparent ${mobileMovingDashLength},
          transparent calc(2 * ${mobileMovingDashLength})
        ),
        radial-gradient(
            circle closest-side,
            transparent 98%,
            transparent
          ),
        repeating-linear-gradient(
          180deg,
          transparent,
          transparent ${mobileMovingDashLength},
          transparent ${mobileMovingDashLength},
          transparent calc(2 * ${mobileMovingDashLength})
        ),
        radial-gradient(
          circle closest-side,
          transparent 98%,
          transparent
        ),
        repeating-linear-gradient(
            270deg,
            transparent,
            transparent ${mobileMovingDashLength},
            transparent ${mobileMovingDashLength},
            transparent calc(2 * ${mobileMovingDashLength})
        )
      `,
    },
    "@keyframes topMoving": {
      "0%": {
        backgroundPosition: `
          0 calc(${movingDashLength}/2), 0 0,
          calc(${movingDashLength}/2 - ${oneWayRoute}) 0, calc(-1*${oneWayRoute}) 0,
          100% calc(${movingDashLength}/2 - ${oneWayRoute}), 100% calc(-1*${oneWayRoute}),
          calc(${movingDashLength}/2) 100%, 0 100%
        `,
      },
      "50%": {
        backgroundPosition: `
          0 calc(${movingDashLength}/2 - ${oneWayRoute}), 0 calc(-1*${oneWayRoute}), 
          calc(${movingDashLength}/2) 0, 0 0, 
          100% calc(${movingDashLength}/2), 100% 0,
          calc(${movingDashLength}/2 - ${oneWayRoute}) 100%, calc(-1*${oneWayRoute}) 100%
        `,
      },
      "100%": {
        backgroundPosition: `
          0 calc(${movingDashLength}/2), 0 0,
          calc(${movingDashLength}/2 - ${oneWayRoute}) 0, calc(-1*${oneWayRoute}) 0,
          100% calc(${movingDashLength}/2 - ${oneWayRoute}), 100% calc(-1*${oneWayRoute}),
          calc(${movingDashLength}/2) 100%, 0 100%
        `,
      },
    },
    "@keyframes mobileTopMoving": {
      "0%": {
        backgroundPosition: `
          0 calc(${mobileMovingDashLength}/2), 0 0,
          calc(${mobileMovingDashLength}/2 - ${mobileOneWayRoute}) 0, calc(-1*${mobileOneWayRoute}) 0,
          100% calc(${mobileMovingDashLength}/2 - ${mobileOneWayRoute}), 100% calc(-1*${mobileOneWayRoute}),
          calc(${mobileMovingDashLength}/2) 100%, 0 100%
        `,
      },
      "50%": {
        backgroundPosition: `
          0 calc(${mobileMovingDashLength}/2 - ${mobileOneWayRoute}), 0 calc(-1*${mobileOneWayRoute}), 
          calc(${mobileMovingDashLength}/2) 0, 0 0, 
          100% calc(${mobileMovingDashLength}/2), 100% 0,
          calc(${mobileMovingDashLength}/2 - ${mobileOneWayRoute}) 100%, calc(-1*${mobileOneWayRoute}) 100%
        `,
      },
      "100%": {
        backgroundPosition: `
          0 calc(${mobileMovingDashLength}/2), 0 0,
          calc(${mobileMovingDashLength}/2 - ${mobileOneWayRoute}) 0, calc(-1*${mobileOneWayRoute}) 0,
          100% calc(${mobileMovingDashLength}/2 - ${mobileOneWayRoute}), 100% calc(-1*${mobileOneWayRoute}),
          calc(${mobileMovingDashLength}/2) 100%, 0 100%
        `,
      },
    },
    "@keyframes downMoving": {
      "0%": {
        backgroundPosition: `
          0 calc(${movingDashLength}/2 - ${oneWayRoute}), 0 calc(-1*${oneWayRoute}),
          calc(${movingDashLength}/2) 0, 0 0,
          100% calc(${movingDashLength}/2), 100% 0,
          calc(${movingDashLength}/2 - ${oneWayRoute}) 100%, calc(-1*${oneWayRoute}) 100%
        `,
      },
      "50%": {
        backgroundPosition: `
          0 calc(${movingDashLength}/2), 0 0,
          calc(${movingDashLength}/2 - ${oneWayRoute}) 0, calc(-1*${oneWayRoute}) 0, 
          100% calc(${movingDashLength}/2 - ${oneWayRoute}), 100% calc(-1*${oneWayRoute}),
          calc(${movingDashLength}/2) 100%, 0 100%
        `,
      },
      "100%": {
        backgroundPosition: `
          0 calc(${movingDashLength}/2 - ${oneWayRoute}), 0 calc(-1*${oneWayRoute}),
          calc(${movingDashLength}/2) 0, 0 0,
          100% calc(${movingDashLength}/2), 100% 0,
          calc(${movingDashLength}/2 - ${oneWayRoute}) 100%, calc(-1*${oneWayRoute}) 100%
        `,
      },
    },
    "@keyframes mobileDownMoving": {
      "0%": {
        backgroundPosition: `
          0 calc(${mobileMovingDashLength}/2 - ${mobileOneWayRoute}), 0 calc(-1*${mobileOneWayRoute}),
          calc(${mobileMovingDashLength}/2) 0, 0 0,
          100% calc(${mobileMovingDashLength}/2), 100% 0,
          calc(${mobileMovingDashLength}/2 - ${mobileOneWayRoute}) 100%, calc(-1*${mobileOneWayRoute}) 100%
        `,
      },
      "50%": {
        backgroundPosition: `
          0 calc(${mobileMovingDashLength}/2), 0 0,
          calc(${mobileMovingDashLength}/2 - ${mobileOneWayRoute}) 0, calc(-1*${mobileOneWayRoute}) 0, 
          100% calc(${mobileMovingDashLength}/2 - ${mobileOneWayRoute}), 100% calc(-1*${mobileOneWayRoute}),
          calc(${mobileMovingDashLength}/2) 100%, 0 100%
        `,
      },
      "100%": {
        backgroundPosition: `
          0 calc(${mobileMovingDashLength}/2 - ${mobileOneWayRoute}), 0 calc(-1*${mobileOneWayRoute}),
          calc(${mobileMovingDashLength}/2) 0, 0 0,
          100% calc(${mobileMovingDashLength}/2), 100% 0,
          calc(${mobileMovingDashLength}/2 - ${mobileOneWayRoute}) 100%, calc(-1*${mobileOneWayRoute}) 100%
        `,
      },
    },
    mobileLineContainer: {
      display: "none",
      position: "relative",
      height: "238px",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    mobileLineContainerTop: {
      width: "40%",
      position: "absolute",
      zIndex: -1,
      top: `calc(50% - (${mobileStaticLineThickness} / 2))`, 
      left: "35%",
    },
    mobileLineContainerBottom: {
      width: "40%",
      position: "absolute",
      zIndex: -1,
      top: `calc(50% - (${mobileStaticLineThickness} / 2))`,
      left: "21%",
    },
    circleLight: {
      position: "absolute",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      [theme.breakpoints.down("sm")]: {
        width: "8px",
        height: "8px",
      },
      "&::before": {
        content: "''",
        display: "block",
        position: "absolute",
        zIndex: "-100",
        width: "32px",
        height: "32px",
        borderRadius: "50%",
        top: "-50%",
        left: "-50%",
        animation: "$pounding 3s infinite linear",
        [theme.breakpoints.down("sm")]: {
          width: "16px",
          height: "16px",
        },
      },
    },
    circleLightTop: {
      backgroundColor: movingDashColor1,
      "&::before": {
        backgroundColor: movingDashColor1,
      },
    },
    circleLightBottom: {
      backgroundColor: movingDashColor2,
      "&::before": {
        backgroundColor: movingDashColor2,
      },
    },
    circleLightTop1: {
      top: "-4px",
      left: "-4px",
    },
    circleLightTop2: {
      top: "-4px",
      left: "calc(100% - 12px)",
    },
    circleLightBottom1: {
      bottom: "-4px",
      right: "-4px",
    },
    circleLightBottom2: {
      bottom: "-4px",
      right: "calc(100% - 12px)",
    },
    mobileCircleLightTop: {
      top: "-2px",
      left: "calc(100% - 6px)",
    },
    mobileCircleLightBottom: {
      top: "-2px",
      left: "-2px",
    },
    "@keyframes pounding": {
      "0%": {
        opacity: 0,
      },
      "50%": {
        opacity: 1,
      },
      "100%": {
        opacity: 0,
      },
    },
    mainCardContainer: {
      marginTop: "-5vw !important",
      [theme.breakpoints.down("sm")]: {
        marginTop: "0 !important",
      },
    },

    workHeading: {
      color: "#5412FC",
      fontFamily: "Montserrat,sans-serif !important",
      fontSize: "clamp(3.125rem, 1.875rem + 2.0833vw, 3.4375rem)",
      fontStyle: "normal",
      fontWeight: (700 + "!important") as never,
      textTransform: "capitalize",
      textAlign: "center" as "center",
      marginBottom: "1em",
      letterSpacing: "-2px !important",
      lineHeight: "1.18",
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px !important",
        lineHeight: "65px",
        marginBottom: "24px",
      },
    },
    unlockWrapper: {
      display: "flex",
      alignItems: "center",
      marginBottom: "6em",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "26px",
      },
    },
    unlockImg: {
      width: "40%",
      [theme.breakpoints.down("sm")]: {
        width: "43%", 
      },
    },
    targetWrapper: {
      display: "flex",
      alignItems: "center",
      marginBottom: "3.5em",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "2.5em",
      },
    },
    targetImg: {
      width: "30%",
      [theme.breakpoints.down("sm")]: {
        width: "37.9%",
      },
    },
    workTitle: {
      color: "#5412FC",
      fontFamily: "Montserrat,sans-serif !important",
      fontSize: "clamp(2.75rem, 1.75rem + 1.6667vw, 3rem)",
      fontWeight: 700,
      textAlign: "start" as "start",
      lineHeight: 0.96,
      marginBottom: "1.02em",
      [theme.breakpoints.down("sm")]: {
        whiteSpace: "normal",
        fontSize: "13px !important",
        lineHeight: "15px",
        marginBottom: "12px",
      },
    },
    bulletContent: {
      fontFamily: "Montserrat,sans-serif !important",
      fontSize: "clamp(1.625rem, 1.125rem + 0.8333vw, 1.75rem)",
      fontWeight: 600,
      color: "rgba(0, 0, 0, 0.61)",
      lineHeight: "32px",
      marginBottom: "0",
      [theme.breakpoints.down("sm")]: {
        fontSize: "8px !important",
        lineHeight: "10px",
        fontWeight: 500, 
      },
    },
    workMainSubTitle: {
      color: "rgba(0, 0, 0, 0.61)",
      fontFamily: "Montserrat,sans-serif !important",
      fontSize: "clamp(1.5rem, 1rem + 0.8333vw, 1.625rem)",
      fontWeight: 600,
      lineHeight: 1.3,
      marginBottom: "1.02em",
      [theme.breakpoints.down("sm")]: {
        fontSize: "8px !important",
        fontWeight: 500,
        lineHeight: "10px",
        marginBottom: "17px",
      },
    },
    arrowBulletPoint: {
      marginTop: "6px",
      height: "20px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "3px",
        height: "5px",
      },
    },
    boldStyle: {
      color: "#5412FC",
      fontWeight: 700,
      display: "block",
      "&::after": {
        display: "inline-block",
        content: "'\xa0'",
      },
      [theme.breakpoints.down("sm")]: {
        display: "inline-block",
        fontWeight: 700,
      },
    },
    workContent: {},
    bulletContainer: {
      "&.container-1": {
        paddingLeft: "35px",
        [theme.breakpoints.down("sm")]: {
          paddingLeft: "0",
        },
      },
    },
    eachBullet: {
      display: "flex",
      gap: "39px",
      "&:not(:last-child)": {
        marginBottom: "23px",
        [theme.breakpoints.down("sm")]: {
          marginBottom: "13px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        gap: "3px",
        paddingLeft: "21px",
      },
    },
    preScreenedTitle: {
      color: "#5412FC",
      fontFamily: "Montserrat,sans-serif !important",
      fontSize: "clamp(3.25rem, 2.5rem + 1.25vw, 3.4375rem)",
      fontWeight: 600,
      textTransform: "capitalize",
      textAlign: "center",
      marginBottom: ".8em",
      lineHeight: "65px",
      letterSpacing: "-2px",
      whiteSpace: "pre-line",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "13px",
        fontSize: "24px !important",
        lineHeight: "30px",
      },
    },
    preScreenedSubtitle: {
      color: "rgba(0, 0, 0, 0.61)",
      textAlign: "center",
      fontFamily: "Montserrat,sans-serif !important",
      fontSize: "24px",
      fontWeight: 500,
      lineHeight: 1.25,
      marginBottom: "2em",
      "& p": {
        padding: "0 245px",
        margin: "0 auto",
        [theme.breakpoints.down("sm")]: {
          padding: "0 21px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        lineHeight: "20px !important",
        fontSize: "12px !important",
        marginBottom: "1.5em",
      },
    },
    prescreenedCardContainer: {
      display: "flex",
      padding: "36px 48px",
      flexDirection: "column",
      alignItems: "center",
      gap: "16px",
      flexShrink: 0,
      borderRadius: "30px",
      border: "4px solid #10BFB8",
      background: "#FFF",
      boxShadow: "0px 6px 0px 0px #5412FC",
      color: "#5412FC",
      fontFamily: "Montserrat,sans-serif",
      fontSize: "0.9vw",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "1.2vw",
      [theme.breakpoints.down("sm")]: {
        padding: "24px 26px",
      },
      "& p": {
        fontFamily: "Montserrat,sans-serif !important",
      },
    },
    saasDataContainer: {
      display: "flex",
      textAlign: "start" as "start",
      alignItems: "baseline",
      flexDirection: "column",
    },
    businessDetails: {
      display: "flex",
    },
    valueContent: {
      display: "flex",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "18px",
      marginBottom: "38px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px !important",
        marginBottom: "24px", 
      },
      "& span": {
        fontSize: "14px",
        display: "block",
        fontWeight: 700,
        [theme.breakpoints.down("sm")]: {
          fontSize: "10px",
        },
        "& p": {
          margin: "0 !important",
          whiteSpace: "nowrap",
          color: "#8C8C8C",
          fontSize: "10px",
          fontWeight: 700,
          display: "inline",
          [theme.breakpoints.down("sm")]: {
            fontSize: "7px",
          },
        },
      },
      "& img": {
        height: "24px",
        width: "24px",
        marginRight: "12px",
      },
    },
    mobileDiv: {
      height: "40px",
      width: "40px",
      backgroundColor: "#5412FC",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "100px",
    },
    mobileIconStyle: {
      backgroundColor: "#5412FC",
      borderRadius: "50%",
      padding: "0.3vw",
      width: "4vw !important",
      [theme.breakpoints.down("sm")]: {
        width: "6vw !important",
      },
    },

    healthImage: {
      backgroundColor: "#5412FC",
      borderRadius: "50%",
      padding: "0.3vw",
      width: "4vw !important",
      [theme.breakpoints.down("sm")]: {
        width: "6vw !important",
      },
    },
    healthcareBox: {
      width: "66px",
      height: "66px",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      "& img": {
        height: "66px",
        width: "66px",
        [theme.breakpoints.down("sm")]: {
          width: "41px",
          height: "41px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        width: "41px",
        height: "41px",
      },
    },
    mobileIconBox: {
      width: "66px",
      height: "66px",
      borderRadius: "50%",
      background: "#10BFB8 !important",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        width: "41px",
        height: "41px",
      },
      "& img": {
        height: "40px",
        width: "40px",
        [theme.breakpoints.down("sm")]: {
          width: "30px",
          height: "30px",
        },
      },
    },
    cardTopSection: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "38px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "19px",
      },
      "& div": {
        display: "flex",
        alignItems: "center",
        gap: "22px",
        [theme.breakpoints.down("sm")]: {
          gap: "14px",
        },
        "& p": {
          fontWeight: 700,
          fontSize: "28px",
          lineHeight: "24px",
          [theme.breakpoints.down("sm")]: {
            fontSize: "20px",
          },
        },
      },
      "& button": {
        borderRadius: "100px",
        border: "2px solid #5412FC",
        background: "#FFF",
        flexShrink: 0,
        color: "#5412FC",
        fontFamily: "Montserrat,sans-serif",
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "20px",
        display: "flex",
        gap: "10px",
        textTransform: "none" as "none",
        justifyContent: "space-between",
        alignItems: "center" as "center",
        padding: "8px 10px",
        [theme.breakpoints.down("sm")]: {
          padding: "3px",
          gap: "3px",
          fontSize: "10px",
          lineHeight: "18px",
        },
        "&.startup-btn": {
          borderColor: "#10BFB8",
          color: "#10BFB8",
        },
        "& p": {
          margin: "0",
        },
        "& .image-wrapper": {
          backgroundColor: "#5412FC",
          borderRadius: "50%",
          width: "32px",
          height: "32px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          [theme.breakpoints.down("sm")]: {
            width: "15px",
            height: "15px",
          },
          "&.startup": {
            backgroundColor: "#10BFB8",
          },
          "& img": {
            width: "20px",
            [theme.breakpoints.down("sm")]: {
              width: "9px",
              height: "9px",
            },
          },
        },
      },
    },
    businessInfoSection: {},
    cardInfoSection: {
      paddingTop: "6px",
      paddingLeft: "33px",
      paddingRight: "50px",
      [theme.breakpoints.down("sm")]: {
        padding: "0",
        marginBottom: "28px",
      },
      "& p": {
        color: "#5412FC",
        fontFamily: "Montserrat,sans-serif",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "18px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "12px !important",
        },
      },
    },
    horizontal: {
      backgroundColor: "#5412FC",
      borderColor: "#5412FC",
      height: "1px",
      margin: "0",
    },
    communityTitle: {
      color: "#5412FC",
      fontFamily: "Montserrat,sans-serif !important",
      fontSize: "clamp(2.5rem, 2rem + 0.8333vw, 2.625rem)",
      fontWeight: 700,
      lineHeight: "46px",
      marginBottom: "1.5em",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        fontSize: "18px",
        lineHeight: "20px",
        marginBottom: "26px",
      },
    },
    communityContainer: {
      display: "flex",
      direction: "ltr",
      flexDirection: conditionSort(isDirRtl, 'row-reverse', 'row'),
      alignItems: "center",
      flexWrap: "wrap-reverse",
      marginTop: "2em",
      marginBottom: "2em",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "1.5em",
      },
      "& .left-wrapper": {
        paddingLeft: "130px",
        paddingRight: "140px",
        direction: conditionSort(isDirRtl, 'rtl', 'ltr'),
        [theme.breakpoints.down("sm")]: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingLeft: "59px",
          paddingRight: "59px",
        },
      },
      "& .right-wrapper": {
        [theme.breakpoints.down("sm")]: {
          display: "flex",
          justifyContent: "center",
          marginBottom: "32px",
        },
      },
    },
    imgWrapper: {
      display: "inline-block",
      position: "relative",
      width: "73.33%", 
      [theme.breakpoints.down("sm")]: {
        width: "55.1%", 
      },
    },
    foldedArrow: {
      height: "65.15%",
      position: "absolute",
      bottom: "8%",
      left: "89%",
      [theme.breakpoints.down("sm")]: {},
    },
    communityImg: {
      maxWidth: "100%",
    },
    hrContainer: {
      marginBottom: "25px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "6px",
      },
    },
    hrBelowContainer: {
      display: "flex",
      justifyContent: "space-between",
      color: "#5412FC",
      textAlign: "right" as "right",
      fontFamily: "Montserrat,sans-serif",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "14px",
    },
    iconsLeft: {
      display: "flex",
      gap: "48px",
      alignItems: "center" as "center",
      [theme.breakpoints.down("sm")]: {
        gap: "13px",
      },
      "& img": {
        width: "32px",
        height: "32px",
        [theme.breakpoints.down("sm")]: {
          width: "17px",
          height: "17px",
        },
      },
    },
    originalListing: {
      display: "flex",
      gap: "4px",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        gap: "8px",
      },
      "& img": {
        [theme.breakpoints.down("sm")]: {
          width: "24px",
          height: "24px",
        },
      },
      "& p": {
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "14px",
        [theme.breakpoints.down("sm")]: {
          fontWeight: 400,
          fontSize: "10px !important",
        },
      },
    },
    navbar: {
      borderRadius: "20px",
      background: "rgba(217, 217, 217, 0.50)",
      display: "flex",
      gap: "clamp(1rem,50%,4rem)",
      alignItems: "center",
      marginBottom: "65px",
      paddingTop: "12px",
      paddingBottom: "12px",
      paddingLeft: "35px",
      paddingRight: "36px",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "space-between",
        marginBottom: "32px",
        borderRadius: "12px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "22px",
        paddingRight: "18px",
      },
      "& .banner": {
        width: '106px'
      },
 
      "& .desktop": {
        flex: 1,
        display: "flex",
        alignItems: "center",

        [theme.breakpoints.down("sm")]: {
          display: "none",
        },

        "& #select-language": {
          display: "flex",
          alignItems: "center",
          gap: 20,
        },

        "& .languageText": {
          display: "inline-block",
          color: "#FFF !important",
          fontSize: 20,
          fontWeight: 700,
          lineHeight: "24px",
        },

        "& .buttonContainer": {
          width: "min(78%,450px)",
          display: "flex",
          justifyContent: "space-between",
          gap: "min(7%,3rem)",
          alignItems: "center",
          flexShrink: 0,
        },
        "& .linkList": {
          marginRight: "auto",
          listStyle: "none",
          display: "flex",
          gap: "min(10%, 119px)",
          width: "min(50%,250px)",
          justifyContent: "space-between",
          marginLeft: "auto",
          padding: 0,
          "& .linkItem": {
            "& .link": {
              textDecoration: "none",
              color: "#FFF",
              fontFamily: "Montserrat",
              fontSize: "20px",
              fontWeight: 700,
              lineHeight: "24px",
            },
          },
        },


        "& .MuiFormControl-root": {
          marginLeft: conditionSort(isDirRtl, "", "auto"),
          marginRight: conditionSort(isDirRtl, "auto", ""),
          [theme.breakpoints.down("sm")]: {
            display: "none",
          },
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: "12px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "2px solid #FFF",
          "&.PrivateNotchedOutline-root-4": {
            border: "2px solid #FFF",
          },
        },
        "& .MuiOutlinedInput-input": {
          padding: "8px 60px 8px 24px",
        },
        "& .arrow-down-icon": {
          width: "20px",
          height: "20px",
          position: "absolute",
          right: "24px",
          pointerEvents: "none",
        },

        "& .signInBtn": {
          textDecoration: "none",
          display: "block",
          padding: "12px 25px",
          borderRadius: "12px",
          border: "1px solid #10BFB8",
          background: "#10BFB8",
          color: "#FFF",
          fontFamily: "Montserrat,sans-serif",
          fontSize: "20px",
          fontWeight: 700,
          lineHeight: 1.2,
          textTransform: "none" as "none",
          cursor: "pointer",
          "&:hover": {
            textDecoration: "none",
            backgroundColor: "#10BFB8",
          },
        },
      },
      "& .mobile": {
        height: "19px",
        padding: "0",
        border: "0",
        background: "transparent",
        display: "none",
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
          display: "block",
        },
      },
      "& img": {
        maxWidth: "100%",
        height: "auto",
        [theme.breakpoints.down("sm")]: {
          height: "24px",
        },
      },
    },
    topBorder: {
      height: "2px",
      backgroundImage:
        "linear-gradient(to right, rgba(0, 248, 162, 1), rgba(84, 18, 252, 1))",
      marginTop: "10%",
    },
    allCardsContainer: {
      direction: "ltr",
      padding: "0 45px", 
      [theme.breakpoints.down("sm")]: {
        padding: "0", 
      },
    },
    ctaSection: {},
    heroSectionContainer: {
      display: "flex",
      gap: "1rem",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    heroSection: {
      width: "37.7%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "233px",
      },
    },
    animationContainer: {
      boxSizing: "border-box",
      overflow: "hidden",
      height: "103%",
      width: "100%",
      fontFamily: "Montserrat",
      color: defaultColor1,
      position: "relative",
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        width: "59%", 
        height: "100%",
      },
      "& *": {
        boxSizing: "border-box",
      },
    },
    leftCardWrapper: {
      position: "relative",
      width: "55%",
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },
      "& .left-link": {
        position: "absolute",
        zIndex: 1000,
        width: "100%",
        height: "90px",
        top: "65%", 
        left: conditionSort(isDirRtl, "0", "50%"),
        right: conditionSort(isDirRtl, "5%", "0"),
        transform: conditionSort(isDirRtl, "scaleX(-1)", ""),
        "& svg": {
          width: "200px",
          height: "105px",
          [theme.breakpoints.down("lg")]: {
            width: "165px",
            height: "85px",
          },
          [theme.breakpoints.down("md")]: {
            width: "156px",
            height: "81px",
          },
          [theme.breakpoints.down("sm")]: {
            width: "62px",
            height: "39px",
          },
        },
        "& .left-link-element": {
          animation: `$toggleStrokeActiveColor5 ${heroDuration} infinite ease-in-out`,
        },
      },
    },
    rightCardWrapper: {
      width: "42%",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& .right-link": {
        position: "absolute",
        zIndex: 0,
        width: "100%",
        height: "90px", 
        top: "40%", 
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down("md")]: {
        },
        [theme.breakpoints.down("sm")]: {
        },
        [theme.breakpoints.down("xs")]: {
          top: "36.5%",
        },
        "& svg": {
          height: "60px",
          [theme.breakpoints.down("sm")]: {
            height: "28px",
          },
        },
        "& .right-link-element": {
          animation: `$toggleStrokeActiveColor5 ${heroDuration} infinite ease-in-out`,
        },
      },
      "& *": {
        boxSizing: "border-box",
      },
    },
    personCard: {
      width: "100%",
      borderRadius: "20px",
      border: `4px solid ${defaultColor1}`,
      background: "#fff",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      animation: `$leftCardGlow ${heroDuration} infinite ease-in-out`,
      [theme.breakpoints.down("sm")]: {
        borderRadius: "8px",
        borderWidth: "2px",
      },
    },
    personCardTop: {
      width: "94%",
      marginBottom: "20%",
      padding: "20px 15px",
      [theme.breakpoints.down("sm")]: {
        padding: "6px 5px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "6px 5px",
      },

      "& .person-header": {
        display: "flex",
        gap: "11px",
        alignItems: "center",
        marginBottom: "18px",
        [theme.breakpoints.down("sm")]: {
          gap: "5px",
          marginBottom: "8px",
        },
        [theme.breakpoints.down("xs")]: {
          gap: "5px",
          marginBottom: "8px",
        },

        "& .person-avatar": {
          display: "block",
          borderRadius: "50%",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "32px",
          height: "32px",
          animation: `$profile1 ${heroDuration} infinite ease-in-out`,
          [theme.breakpoints.down("sm")]: {
            width: "13px",
            height: "13px",
          },
        },

        "& .header-skeleton": {
          width: "30%",

          "& .skeleton:nth-child(1)": {
            animation: `$skeletonColor1Active ${heroDuration} infinite ease-in-out`,
          },
        },

        "& .text": {
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: defaultColor1,
          fontSize: "10px",
          fontWeight: 700,
          lineHeight: "12px",
          animation: `$togglePurpleColor ${heroDuration} infinite ease-in-out`,
          [theme.breakpoints.down("sm")]: {
            fontSize: "4px",
          },
          [theme.breakpoints.down("xs")]: {
            fontSize: "4px",
          },
        },
      },

      "& .person-content": {
        marginBottom: "10px",
        [theme.breakpoints.down("sm")]: {
          marginBottom: "4px",
        },

        "& .skeleton": {
          "&:not(:last-child)": {
            marginBottom: "7px",
            [theme.breakpoints.down("sm")]: {
              marginBottom: "3px",
            },
            [theme.breakpoints.down("xs")]: {
              marginBottom: "3px",
            },
          },
        },
      },

      "& .person-footer": {
        padding: "13px 11px",
        borderRadius: "10px",
        border: `1px solid ${defaultColor2}`,
        background: "rgba(140, 140, 140, 0)",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
          padding: "5px 4px",
          borderRadius: "4px",
          borderWidth: "0.5px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "5px 4px",
          borderRadius: "4px",
          borderWidth: "0.5px",
        },
        "& .link-dot": {
          position: "absolute",
          width: "16px",
          height: "16px",
          top: "100%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          [theme.breakpoints.down("sm")]: {
            width: "8px",
            height: "8px",
          },
          "& svg": {
            [theme.breakpoints.down("sm")]: {
              width: "8px",
              height: "8px",
            },
          },
          "& .link-dot-element": {
            animation: `$toggleFillPurpleColor ${heroDuration} infinite ease-in-out`,
          },
        },
        "& .skeleton-row": {
          display: "flex",
          gap: "8px",
          [theme.breakpoints.down("sm")]: {
            gap: "4px",
          },

          "&:not(:last-child)": {
            marginBottom: "8px",
            [theme.breakpoints.down("sm")]: {
              marginBottom: "4px",
            },
            [theme.breakpoints.down("xs")]: {
              marginBottom: "4px",
            },
          },

          "&:nth-child(3)": {
            justifyContent: "flex-end",
          },

          "& .skeleton": {
            marginBottom: "0",

            "&:last-child": {
              animation: `$skeletonColor3Active ${heroDuration} infinite ease-in-out`,
            },

            "&.skeleton-dot": {
              animation: `$togglePurpleBgColor ${heroDuration} infinite ease-in-out`,
            },
          },
        },
      },
    },
    personCardBottom: {
      position: "relative",
      zIndex: 10000,
      padding: "12% 8%",
      [theme.breakpoints.down("sm")]: {
        paddingtop: "9px",
        paddingLeft: "5px",
        paddingRight: "5px",
        paddingBottom: "9px",
      },

      "& .person-header": {
        display: "flex",
        gap: "8px",
        alignItems: "center",
        marginBottom: "20px",
        [theme.breakpoints.down("sm")]: {
          gap: "3px",
          marginBottom: "7px",
        },
        [theme.breakpoints.down("xs")]: {
          gap: "3px",
          marginBottom: "7px",
        },

        "& .person-avatar": {
          display: "block",
          borderRadius: "50%",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "38px",
          height: "38px",
          animation: `$profile2 ${heroDuration} infinite ease-in-out`,
          [theme.breakpoints.down("sm")]: {
            width: "16px",
            height: "16px",
          },
        },

        "& .person-info": {
          "& .type": {
            fontSize: "7px",
            fontWeight: 500,
            animation: `$toggleBlackColor ${heroDuration} infinite ease-in-out`,
            [theme.breakpoints.down("sm")]: {
              fontSize: "3px",
            },
          },
          "& .name": {
            fontSize: "10px",
            fontWeight: 700,
            lineHeight: 1.2,
            animation: `$toggleBlackColor ${heroDuration} infinite ease-in-out`,
            [theme.breakpoints.down("sm")]: {
              fontSize: "5px",
            },
            [theme.breakpoints.down("xs")]: {
              fontSize: "5px",
            },
          },
          "& .verified": {
            display: "flex",
            gap: "4px",
            alignItems: "center",
            fontSize: "10px",
            fontWeight: 500,
            lineHeight: 1.2,
            animation: `$togglePurpleColor ${heroDuration} infinite ease-in-out`,
            [theme.breakpoints.down("sm")]: {
              fontSize: "4px",
            },

            "& .verified-symbol": {
              "& svg": {
                width: "10px",
                height: "10px",
                [theme.breakpoints.down("sm")]: {
                  width: "4px",
                  height: "4px",
                },
              },
              "& .verified-symbol-element": {
                fill: "orange",
                animation: `$toggleFillPurpleColor ${heroDuration} infinite ease-in-out`,
              },
            },
          },
        },
      },

      "& .person-content": {
        position: "relative",
        marginBottom: "21px",
        [theme.breakpoints.down("sm")]: {
          marginBottom: "9px",
        },
        [theme.breakpoints.down("xs")]: {
          marginBottom: "8px",
        },

        "& .more-person-info": {
          marginBottom: "6px",
          display: "flex",
          justifyContent: "space-between",
          animation: `$toggleMoreInfo ${heroDuration} infinite ease-in-out`,
          [theme.breakpoints.down("sm")]: {
            marginBottom: "2px",
          },

          "& .info-column": {
            display: "flex",
            flexDirection: "column",

            "& .info-above": {
              color: "#262626",
              fontSize: "6px",
              fontWeight: 500,
              lineHeight: 1.33,
              [theme.breakpoints.down("sm")]: {
                fontSize: "3px",
                lineHeight: "normal",
              },
            },

            "& .info-below": {
              color: activeColor3,
              fontSize: "6px",
              fontWeight: 700,
              lineHeight: 1.33,
              [theme.breakpoints.down("sm")]: {
                fontSize: "3px",
                lineHeight: "normal",
              },
            },
          },
        },

        "& .skeleton-row": {
          display: "flex",
          justifyContent: "space-between",

          "&.toggle": {
            animation: `$skeletonToggle ${heroDuration} infinite ease-in-out`,
          },

          "&:last-child": {
            justifyContent: "start",
            gap: "14px",
            [theme.breakpoints.down("sm")]: {
              gap: "6px",
            },
          },

          "&:not(:last-child)": {
            marginBottom: "11px",
            [theme.breakpoints.down("sm")]: {
              marginBottom: "5x",
            },
            [theme.breakpoints.down("xs")]: {
              marginBottom: "5px",
            },
          },

          "& .skeleton": {
            margin: "0",

            "&.special": {
              animation: `$skeletonColor1Active ${heroDuration} infinite ease-in-out`,
            },
          },
        },
      },

      "& .person-footer": {
        "& .skeleton": {
          animation: `$skeletonColor3ActiveB ${heroDuration} infinite ease-in-out`,
        },
      },
    },
    heroCard: {
      position: "absolute",
      zIndex: 10,
      top: "50%",
      width: "100%",
      padding: "18px 19px",
      border: `4px solid ${defaultColor1}`,
      borderRadius: "20px",
      backgroundColor: "#fff",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "5px",
        paddingLeft: "5px",
        paddingRight: "5px",
        paddingBottom: "10px",
        borderWidth: "2px",
        borderRadius: "8px",
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: "5px",
        paddingLeft: "5px",
        paddingRight: "5px",
        paddingBottom: "10px",
        borderWidth: "2px",
        borderRadius: "8px",
      },
      "&.hero-card-bg-top, &.hero-card-bg-bottom": {
        borderWidth: "0 !important",
        zIndex: 0,
        "& *": {
          color: "transparent",
          backgroundColor: "transparent",
          borderColor: "transparent !important",
        },
      },
      "&.hero-card-bg-top": {
        opacity: 0.6,
        background: `linear-gradient(0deg, ${defaultColor1} 0%, rgba(140, 140, 140, 0.00) 26.33%)`,
        transform: `scale(${scaleRatio}) translateY(calc(-1*${cardShift2} + (${adjustTranslateYCenter} / ${scaleRatio}) - 24px))`,
        [theme.breakpoints.down("sm")]: {
          transform: `scale(${scaleRatio}) translateY(calc(-1*${cardShift2} + (${adjustTranslateYCenter} / ${scaleRatio}) - 10px))`,
        },
      },
      "&.hero-card-bg-bottom": {
        opacity: 0.6,
        background: `linear-gradient(180deg, ${defaultColor1} 0%, rgba(140, 140, 140, 0.00) 26.33%)`,
        transform: `scale(${scaleRatio}) translateY(calc(${cardShift2} + (${adjustTranslateYCenter} / ${scaleRatio}) + 24px))`,
        [theme.breakpoints.down("sm")]: {
          transform: `scale(${scaleRatio}) translateY(calc(${cardShift2} + (${adjustTranslateYCenter} / ${scaleRatio}) + 10px))`,
        },
      },
      "& .industry-header": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start",
        marginBottom: "7px",
        [theme.breakpoints.down("sm")]: {
          marginBottom: "3px",
        },
        "& .industry-header-left": {
          width: "70%",
          display: "flex",
          gap: "7px",
          alignItems: "center",
          fontSize: "14px",
          fontWeight: 700,
          lineHeight: 1.4,
          "&.active": {
            animation: `$headerLeftActive ${heroDuration} infinite ease-in-out`,
          },
          [theme.breakpoints.down("sm")]: {
            gap: "3px",
            fontSize: "6px",
            lineHeight: "10px",
          },
          "& .industry-logo": {
            width: "27px",
            height: "27px",
            borderRadius: "50%",
            backgroundColor: defaultColor1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&.active": {
              animation: `$togglePurpleBgColor  ${heroDuration} infinite ease-in-out`,
            },
            [theme.breakpoints.down("sm")]: {
              width: "12px",
              height: "12px",
            },
          },
        },
        "& .industry-header-right": {
          width: "30%",
          display: "flex",
          alignItems: "center",
          fontSize: "7px",
          fontWeight: 600,
          lineHeight: "9px",
          borderRadius: "20px",
          border: `1px solid ${defaultColor1}`,
          padding: "3px",
          [theme.breakpoints.down("sm")]: {
            fontSize: "3px",
            lineHeight: "normal",
            padding: "1px",
          },
          "&.active": {
            animation: `$headerRightActive ${heroDuration} infinite ease-in-out`,
          },
          "& .company-type-logo": {
            width: "13px",
            height: "13px",
            borderRadius: "50%",
            backgroundColor: defaultColor1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&.active": {
              animation: `$toggleActiveColor1  ${heroDuration} infinite ease-in-out`,
            },
            [theme.breakpoints.down("sm")]: {
              width: "5px",
              height: "5px",
            },
          },
          "& .company-type-wrapper": {
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        },
      },
      "& .industry-content": {
        marginBottom: "14px",
        [theme.breakpoints.down("sm")]: {
          marginBottom: "7px",
        },
      },
      "& .industry-footer": {
        display: "flex",
        justifyContent: "space-between",
        "& .industry-footer-skeleton-group": {
          width: "30%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
        "& .price-container": {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "7px",
          "&.active": {
            animation: `$togglePurpleColor ${heroDuration} infinite ease-in-out`,
          },
          "& .price-text": {
            fontSize: "10px",
            fontWeight: 700,
            lineHeight: 1,
            [theme.breakpoints.down("sm")]: {
              fontSize: "4px",
              lineHeight: "4px",
            },
          },
          "& .price": {
            fontSize: "11px",
            fontWeight: 700,
            lineHeight: 1,
            [theme.breakpoints.down("sm")]: {
              fontSize: "5px",
              lineHeight: "0px",
            },
          },
        },
      },
    },
    heroCard1: {
      transform: `scale(1) translateY(${adjustTranslateYCenter})`,
      zIndex: 100,
      animation: `$scrollingUp1 ${heroDuration} infinite ease-in-out`,
      "&.active": {
        animation: `
          $scrollingUp1 ${heroDuration} infinite ease-in-out,
          $heroCardActive ${heroDuration} infinite ease-in-out
        `,
      },
    },
    heroCard2: {
      transform: `scale(${scaleRatio}) translateY(calc(${cardShift1} + (${adjustTranslateYCenter} / ${scaleRatio})))`,
      animation: `$scrollingUp2 ${heroDuration} infinite ease-in-out`,
    },
    heroCard3: {
      transform: `scale(${scaleRatio}) translateY(calc(var(--card-shift-2) + (${adjustTranslateYCenter} / ${scaleRatio})))`,
      animation: `$scrollingUp3 ${heroDuration} infinite ease-in-out`,
    },
    heroCard4: {
      transform: `scale(${scaleRatio}) translateY(calc(-1*var(--card-shift-2) + (${adjustTranslateYCenter} / ${scaleRatio})))`,
      animation: `$scrollingUp4 ${heroDuration} infinite ease-in-out`,
    },
    heroCard5: {
      transform: `scale(${scaleRatio}) translateY(calc(-1*${cardShift1} + (${adjustTranslateYCenter} / ${scaleRatio})))`,
      animation: `$scrollingUp5 ${heroDuration} infinite ease-in-out`,
    },
    logoElement: {
      display: "block",
      width: "16px",
      height: "16px",
      [theme.breakpoints.down("sm")]: {
        width: "7px",
        height: "7px",
      },
    },
    companyTypeElement: {
      display: "block",
      width: "10px",
      height: "10px",
      [theme.breakpoints.down("sm")]: {
        width: "3px",
        height: "3px",
      },
    },
    skeleton: {
      background: defaultColor2,
      borderRadius: "1000px",
      "&:not(:last-child)": {
        marginBottom: "6px",
        [theme.breakpoints.down("sm")]: {
          marginBottom: "4px",
        },
      },
      "&.active": {
        animation:
          "$skeletonColor1Active " + heroDuration + " infinite ease-in-out",
      },
    },
    skeletonDot: {
      background: defaultColor2,
      borderRadius: "50%",
    },
    skeletonWFull: {
      width: "100%",
    },
    skeletonW2_3: {
      width: "66.66%",
    },
    skeletonW1_2: {
      width: "50%",
    },
    skeletonW50: {
      width: "50%",
    },
    skeletonW10: {
      width: "10%",
    },
    skeletonW20: {
      width: "20%",
    },
    skeletonW25: {
      width: "25%",
    },
    skeletonW30: {
      width: "30%",
    },
    skeletonW35: {
      width: "35%",
    },
    skeletonW40: {
      width: "40%",
    },
    skeletonW80: {
      width: "80%",
    },
    skeletonH1: {
      height: "15px",
      [theme.breakpoints.down("sm")]: {
        height: "5px",
      },
    },
    skeletonH2: {
      height: "11px",
      [theme.breakpoints.down("sm")]: {
        height: "4px",
      },
    },
    skeletonH3: {
      height: "18px",
      [theme.breakpoints.down("sm")]: {
        height: "7.5px",
      },
    },
    skeletonH4: {
      height: "12px",
      [theme.breakpoints.down("sm")]: {
        height: "5px",
      },
    },
    skeletonDotH4: {
      width: "12px",
      height: "12px",
      [theme.breakpoints.down("sm")]: {
        width: "5px",
        height: "5px",
      },
    },
    skeletonH5: {
      height: "22px",
      [theme.breakpoints.down("sm")]: {
        height: "9px",
      },
    },
    skeletonColor1: {
      backgroundColor: defaultColor2,
    },
    skeletonColor2: {
      backgroundColor: "#d9d9d9", 
    },
    skeletonColor3: {
      backgroundColor: defaultColor1,
    },
    skeletonColorActive: {
      backgroundColor: activeColor2,
    },
    "@keyframes scrollingUp1": {
      "0%, 15%": {
        transform: `scale(1) translateY(${adjustTranslateYCenter})`,
        backgroundColor: "#fff",
        opacity: 1,
        zIndex: 10000,
      },
      "20%, 35%": {
        transform: `scale(${scaleRatio}) translateY(calc(-1*${cardShift1} + (${adjustTranslateYCenter} / ${scaleRatio})))`,
        opacity: shiftedCardOpacity,
        zIndex: 1000,
      },
      "40%, 55%": {
        transform: `scale(${scaleRatio}) translateY(calc(-1*${cardShift2} + (${adjustTranslateYCenter} / ${scaleRatio})))`,
        opacity: shiftedCardOpacity,
        zIndex: 100,
      },
      "60%, 75%": {
        transform: `scale(${scaleRatio}) translateY(calc(${cardShift2} + (${adjustTranslateYCenter} / ${scaleRatio})))`,
        opacity: shiftedCardOpacity,
        zIndex: 100,
      },
      "80%, 95%": {
        transform: `scale(${scaleRatio}) translateY(calc(${cardShift1} + (${adjustTranslateYCenter} / ${scaleRatio})))`,
        opacity: shiftedCardOpacity,
        zIndex: 1000,
      },
      "100%": {
        transform: `scale(1) translateY(${adjustTranslateYCenter})`,
        backgroundColor: "#fff",
        opacity: 1,
        zIndex: 10000,
      },
    },
    "@keyframes scrollingUp2": {
      "0%, 15%": {
        transform: `scale(${scaleRatio}) translateY(calc(${cardShift1} + (${adjustTranslateYCenter} / ${scaleRatio})))`,
        opacity: shiftedCardOpacity,
        zIndex: 1000,
      },
      "20%, 35%": {
        transform: `scale(1) translateY(${adjustTranslateYCenter})`,
        backgroundColor: "#fff",
        opacity: 1,
        zIndex: 10000,
      },
      "40%, 55%": {
        transform: `scale(${scaleRatio}) translateY(calc(-1*${cardShift1} + (${adjustTranslateYCenter} / ${scaleRatio})))`,
        opacity: shiftedCardOpacity,
        zIndex: 100,
      },
      "60%, 75%": {
        transform: `scale(${scaleRatio}) translateY(calc(-1*${cardShift2} + (${adjustTranslateYCenter} / ${scaleRatio})))`,
        opacity: shiftedCardOpacity,
        zIndex: 100,
      },
      "80%, 95%": {
        transform: `scale(${scaleRatio}) translateY(calc(${cardShift2} + (${adjustTranslateYCenter} / ${scaleRatio})))`,
        opacity: shiftedCardOpacity,
        zIndex: 1000,
      },
      "100%": {
        transform: `scale(${scaleRatio}) translateY(calc(${cardShift1} + (${adjustTranslateYCenter} / ${scaleRatio})))`,
        opacity: shiftedCardOpacity,
        zIndex: 1000,
      },
    },
    "@keyframes scrollingUp3": {
      "0%, 15%": {
        transform: `scale(${scaleRatio}) translateY(calc(${cardShift2} + (${adjustTranslateYCenter} / ${scaleRatio})))`,
        opacity: shiftedCardOpacity,
        zIndex: 1000,
      },
      "20%, 35%": {
        transform: `scale(${scaleRatio}) translateY(calc(${cardShift1} + (${adjustTranslateYCenter} / ${scaleRatio})))`,
        opacity: shiftedCardOpacity,
        zIndex: 1000,
      },
      "40%, 55%": {
        transform: `scale(1) translateY(${adjustTranslateYCenter})`,
        backgroundColor: "#fff",
        opacity: 1,
        zIndex: 10000,
      },
      "60%, 75%": {
        transform: `scale(${scaleRatio}) translateY(calc(-1*${cardShift1} + (${adjustTranslateYCenter} / ${scaleRatio})))`,
        opacity: shiftedCardOpacity,
        zIndex: 100,
      },
      "80%, 95%": {
        transform: `scale(${scaleRatio}) translateY(calc(-1*${cardShift2} + (${adjustTranslateYCenter} / ${scaleRatio})))`,
        opacity: shiftedCardOpacity,
        zIndex: 100,
      },
      "100%": {
        transform: `scale(${scaleRatio}) translateY(calc(${cardShift2} + (${adjustTranslateYCenter} / ${scaleRatio})))`,
        opacity: shiftedCardOpacity,
        zIndex: 1000,
      },
    },
    "@keyframes scrollingUp4": {
      "0%, 15%": {
        transform: `scale(${scaleRatio}) translateY(calc(-1*${cardShift2} + (${adjustTranslateYCenter} / ${scaleRatio})))`,
        opacity: shiftedCardOpacity,
        zIndex: 1000,
      },
      "20%, 35%": {
        transform: `scale(${scaleRatio}) translateY(calc(${cardShift2} + (${adjustTranslateYCenter} / ${scaleRatio})))`,
        opacity: shiftedCardOpacity,
        zIndex: 1000,
      },
      "40%, 55%": {
        transform: `scale(${scaleRatio}) translateY(calc(${cardShift1} + (${adjustTranslateYCenter} / ${scaleRatio})))`,
        opacity: shiftedCardOpacity,
        zIndex: 100,
      },
      "60%, 75%": {
        transform: `scale(1) translateY(${adjustTranslateYCenter})`,
        backgroundColor: "#fff",
        opacity: 1,
        zIndex: 10000,
      },
      "80%, 95%": {
        transform: `scale(${scaleRatio}) translateY(calc(-1*${cardShift1} + (${adjustTranslateYCenter} / ${scaleRatio})))`,
        opacity: shiftedCardOpacity,
        zIndex: 100,
      },
      "100%": {
        transform: `scale(${scaleRatio}) translateY(calc(-1*${cardShift2} + (${adjustTranslateYCenter} / ${scaleRatio})))`,
        opacity: shiftedCardOpacity,
        zIndex: 1000,
      },
    },
    "@keyframes scrollingUp5": {
      "0%, 15%": {
        transform: `scale(${scaleRatio}) translateY(calc(-1*${cardShift1} + (${adjustTranslateYCenter} / ${scaleRatio})))`,
        opacity: shiftedCardOpacity,
        zIndex: 1000,
      },
      "20%, 35%": {
        transform: `scale(${scaleRatio}) translateY(calc(-1*${cardShift2} + (${adjustTranslateYCenter} / ${scaleRatio})))`,
        opacity: shiftedCardOpacity,
        zIndex: 1000,
      },
      "40%, 55%": {
        transform: `scale(${scaleRatio}) translateY(calc(${cardShift2} + (${adjustTranslateYCenter} / ${scaleRatio})))`,
        opacity: shiftedCardOpacity,
        zIndex: 100,
      },
      "60%, 75%": {
        transform: `scale(${scaleRatio}) translateY(calc(${cardShift1} + (${adjustTranslateYCenter} / ${scaleRatio})))`,
        opacity: shiftedCardOpacity,
        zIndex: 100,
      },
      "80%, 95%": {
        transform: `scale(1) translateY(${adjustTranslateYCenter})`,
        backgroundColor: "#fff",
        opacity: 1,
        zIndex: 10000,
      },
      "100%": {
        transform: `scale(${scaleRatio}) translateY(calc(-1*${cardShift1} + (${adjustTranslateYCenter} / ${scaleRatio})))`,
        opacity: shiftedCardOpacity,
        zIndex: 1000,
      },
    },
    "@keyframes heroCardActive": {
      "0%, 15%": {
        borderColor: activeColor5,
      },
      "20%, 95%": {
        borderColor: defaultColor1,
      },
      "100%": {
        borderColor: activeColor5,
      },
    },
    "@keyframes headerLeftActive": {
      "0%, 15%": {
        color: activeColor3,
      },
      "20%, 95%": {
        color: defaultColor1,
      },
      "100%": {
        color: activeColor3,
      },
    },
    "@keyframes headerRightActive": {
      "0%, 15%": {
        color: activeColor1,
        borderColor: activeColor1,
      },
      "20%, 95%": {
        color: defaultColor1,
        borderColor: defaultColor1,
      },
      "100%": {
        color: activeColor1,
        borderColor: activeColor1,
      },
    },
    "@keyframes skeletonColor1Active": {
      "0%, 15%": {
        backgroundColor: activeColor2,
      },
      "20%, 55%": {
        backgroundColor: defaultColor2, 
      },
      "60%, 95%": {
        backgroundColor: defaultColor2, 
      },
      "100%": {
        backgroundColor: activeColor2,
      },
    },
    "@keyframes skeletonColor3Active": {
      "0%, 15%": {
        backgroundColor: activeColor2,
      },
      "20%, 55%": {
        backgroundColor: defaultColor1, 
      },
      "60%, 95%": {
        backgroundColor: defaultColor1,
      },
      "100%": {
        backgroundColor: activeColor2,
      },
    },
    "@keyframes skeletonColor3ActiveB": {
      "0%, 15%": {
        backgroundColor: activeColor1,
      },
      "20%, 55%": {
        backgroundColor: defaultColor1, 
      },
      "60%, 95%": {
        backgroundColor: defaultColor1, 
      },
      "100%": {
        backgroundColor: activeColor1,
      },
    },
    "@keyframes leftCardGlow": {
      "0%, 15%": {
        borderColor: activeColor5,
      },
      "20%, 95%": {
        borderColor: defaultColor1,
      },
      "100%": {
        borderColor: activeColor5,
      },
    },
    "@keyframes skeletonToggle": {
      "0%, 15%": {
        opacity: 0,
        position: "absolute",
      },
      "20%, 95%": {
        opacity: 1,
        position: "relative",
      },
      "100%": {
        opacity: 0,
        position: "absolute",
      },
    },
    "@keyframes toggleMoreInfo": {
      "0%, 15%": {
        opacity: 1,
        position: "relative",
      },
      "20%, 95%": {
        opacity: 0,
        position: "absolute",
      },
      "100%": {
        opacity: 1,
        position: "relative",
      },
    },
    "@keyframes togglePurpleColor": {
      "0%, 15%": {
        color: activeColor3,
      },
      "20%, 95%": {
        color: defaultColor1,
      },
      "100%": {
        color: activeColor3,
      },
    },
    "@keyframes toggleFillPurpleColor": {
      "0%, 15%": {
        fill: activeColor3,
      },
      "20%, 95%": {
        fill: defaultColor1,
      },
      "100%": {
        fill: activeColor3,
      },
    },
    "@keyframes toggleBlackColor": {
      "0%, 15%": {
        color: activeColor4,
      },
      "20%, 95%": {
        color: defaultColor1,
      },
      "100%": {
        color: activeColor4,
      },
    },
    "@keyframes togglePurpleBgColor": {
      "0%, 15%": {
        backgroundColor: activeColor3,
      },
      "20%, 95%": {
        backgroundColor: defaultColor1,
      },
      "100%": {
        backgroundColor: activeColor3,
      },
    },
    "@keyframes toggleActiveColor1": {
      "0%, 15%": {
        backgroundColor: activeColor1,
      },
      "20%, 95%": {
        backgroundColor: defaultColor1,
      },
      "100%": {
        backgroundColor: activeColor1,
      },
    },
    "@keyframes toggleStrokeActiveColor5": {
      "0%, 15%": {
        stroke: activeColor5,
      },
      "20%, 95%": {
        stroke: defaultColor1,
      },
      "100%": {
        stroke: activeColor5,
      },
    },
    "@keyframes profile1": {
      "0%, 15%": {
        backgroundImage: `url(${profile1})`,
      },
      "20%, 95%": {
        backgroundImage: `url(${profile1Gray})`,
      },
      "100%": {
        backgroundImage: `url(${profile1})`,
      },
    },
    "@keyframes profile2": {
      "0%, 15%": {
        backgroundImage: `url(${profile2})`,
      },
      "20%, 95%": {
        backgroundImage: `url(${profile2Gray})`,
      },
      "100%": {
        backgroundImage: `url(${profile2})`,
      },
    },
  });

export const MainLandingPageStyle = withStyles(customStyle, {
  withTheme: true,
})(MainLandingPage);
// Customizable Area End
